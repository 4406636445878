import { type ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

import { OrderDetails } from './OrderDetails'

export function Marketplace(): ReactElement {
  return (
    <>
      <Routes>
        <Route index element={<OrderDetails />} path="/:orderCode" />
      </Routes>
    </>
  )
}
