import useSWR from 'swr'
import fetcher from '@/services/axios'
import { type IFollowingData } from '@/types/Social'

export const useGetFollowing = (
  userId: number,
): {
  following: IFollowingData[] | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, mutate, isLoading } = useSWR<IFollowingData[], string>(
    `/social/${userId}/following`,
    fetcher,
  )

  const following = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    following,
    error,
    isLoading,
    refresh,
  }
}
