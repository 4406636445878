import {
  type ITicketStore,
  type ITicketData,
} from '@/types/hooks/api/useTickets'
import { isTruthy } from './validation'

export function ticketMapperAndSorter(tickets: ITicketData[]): ITicketStore[] {
  const tempTickets = tickets?.map((ticket) => {
    const hasFee = ticket?.paymentMethods?.some((payment) => payment.fee > 0)

    let finalPrice = ticket.price

    if (isTruthy(ticket.promo) && ticket.price > 0) {
      if (isTruthy(ticket.discountAmount)) {
        finalPrice = ticket.price - ticket.discountAmount
      }
      if (isTruthy(ticket.discountPercentage)) {
        finalPrice = ticket.price * (1 - ticket.discountPercentage)
      }
    }

    return {
      ...ticket,
      hasFee,
      quantitySelected: 0,
      finalPrice,
    }
  })
  const sortedTempTickets = tempTickets.sort((ticket) => {
    if (isTruthy(ticket.promo)) return -1
    return 1
  })

  return sortedTempTickets
}
