import { type ReactElement, useRef, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import { MiniMap } from '@/components/Global/Minimap'

import { HiOutlineLocationMarker } from 'react-icons/hi'
import { FiCopy } from 'react-icons/fi'
import { BsCheckLg } from 'react-icons/bs'

import { formatEncodeGoogleMapsString } from '@/helpers/formatString'
import { isTruthy } from '@/helpers/validation'

interface EventLocationProps {
  name: string | undefined
  fullAddress: string | undefined
  latitude?: number
  longitude?: number
}

export function EventLocation({
  name,
  fullAddress,
  latitude,
  longitude,
}: EventLocationProps): ReactElement {
  const [locationCopied, setLocationCopied] = useState<boolean>(false)
  const locationLinkRef = useRef<HTMLSpanElement>(null)

  async function copyLinkToClipboard(): Promise<void> {
    if (locationLinkRef.current !== null) {
      setLocationCopied(true)
      const textToCopy = locationLinkRef.current.textContent
      await navigator.clipboard.writeText(textToCopy ?? '')
      setTimeout(setLocationCopied, 2000, false)
    }
  }

  function handleOpenGMaps(): void {
    const encodedUrl = formatEncodeGoogleMapsString(fullAddress ?? '')
    const gMapsSearchUrl = `https://www.google.com/maps/search/?api=1&query=${encodedUrl}`
    window.open(gMapsSearchUrl, '_blank')
  }

  return (
    <div className="flex w-full flex-col rounded-lg bg-dark-black p-4">
      <div className="flex w-full items-center justify-between gap-4">
        <div className="flex max-w-[75%] grow flex-col items-start justify-start">
          <span className={`text-base font-bold leading-7 text-primary-main`}>
            {name}
          </span>
          <span
            className="text-sm font-normal leading-4 text-text-secondary"
            ref={locationLinkRef}
          >
            {fullAddress}
          </span>
        </div>

        <div className="flex">
          <button
            className={twMerge(
              'transition-color flex h-8 w-8 items-center justify-center rounded-full bg-white duration-200 ease-in-out hover:brightness-[70%]',
              locationCopied ? 'bg-primary-dark' : '',
            )}
            onClick={() => {
              void copyLinkToClipboard()
            }}
          >
            {locationCopied ? (
              <BsCheckLg size={18} color="white" />
            ) : (
              <FiCopy size={18} color="#232323" className="" />
            )}
          </button>

          <button
            className="ml-2 flex size-8 items-center justify-center rounded-full bg-white hover:brightness-[80%]"
            onClick={() => {
              handleOpenGMaps()
            }}
          >
            <HiOutlineLocationMarker size={20} color="#232323" className="" />
          </button>
        </div>
      </div>
      {isTruthy(latitude) && isTruthy(longitude) && (
        <div className="relative mt-4 h-[150px] w-full overflow-hidden rounded-lg">
          <MiniMap latitude={latitude ?? 0} longitude={longitude ?? 0} />
        </div>
      )}
    </div>
  )
}
