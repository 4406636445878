import { useEffect, useState } from 'react'
import useSWR from 'swr'

import fetcher from '@/services/axios'

import { type ITicketData } from '@/types/hooks/api/useTickets'

import { isTruthy } from '@/helpers/validation'

export const useGetTickets = (
  eventAlias: string,
  link?: string,
): {
  ticketsData: ITicketData[] | undefined
  error: string | undefined
  isLoading: boolean
} => {
  let url = `/events/${eventAlias}/tickets`
  if (isTruthy(link)) url = `/events/${eventAlias}/tickets?link=${link!}`

  const { data, error } = useSWR<ITicketData[], string>(url, fetcher)
  const [isLoading, setIsLoading] = useState(true)

  const ticketsData: ITicketData[] | undefined = data ?? undefined

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data, error])

  return {
    ticketsData,
    error,
    isLoading,
  }
}
