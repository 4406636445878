import { useEffect, useState, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { EventImage } from '@/components/Event/EventImage'
import { OnFire } from '@/components/Global/OnFire'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'
import { usePutUserStatus } from '@/hooks/api/Event'
import { useRoutingStore } from '@/store/routing'

interface MainEventCardDesktopProps {
  eventAlias?: string
  imageKey: string | undefined
  titleImage?: string | undefined
  title?: string | undefined
  date: string | undefined
  location: string | undefined
  tags?: string[] | undefined
  onFire?: boolean
  lowestTicketPrice?: number
  description?: string
  isUserInsterested?: boolean
}

export function MainEventCardDesktop({
  eventAlias,
  imageKey,
  titleImage,
  title,
  date,
  location,
  onFire = false,
  lowestTicketPrice,
  description,
  isUserInsterested = false,
}: MainEventCardDesktopProps): ReactElement {
  const { userIsLogged } = useUserStore()
  const { setShowLoginModal } = useGlobalStore()
  const { setToPath } = useRoutingStore()

  const { putUserStatus } = usePutUserStatus(eventAlias!)

  const [isUserInterestedLocal, setIsUserInterestedLocal] =
    useState(isUserInsterested)

  function handleInterest(): void {
    if (!userIsLogged) {
      setToPath('none')
      setShowLoginModal(true)
      return
    }

    setIsUserInterestedLocal(!isUserInterestedLocal)

    const newStatus = isUserInterestedLocal ? 'VIEWED' : 'INTERESTED'

    void putUserStatus(newStatus)
  }

  useEffect(() => {
    setIsUserInterestedLocal(isUserInsterested)
  }, [isUserInsterested])

  return (
    <div className="flex w-full items-end gap-8 overflow-hidden">
      <Link to={`/events/${eventAlias!}`} className="relative w-1/2">
        <EventImage
          imageKey={imageKey}
          expanded
          rounded
          showInterest
          isUserInsterested={isUserInterestedLocal}
          onClick={handleInterest}
        />
        {onFire && (
          <div className="absolute right-4 top-4 z-20">
            <OnFire variant="withText" />
          </div>
        )}
      </Link>

      <div className="flex w-1/2 flex-col gap-8">
        <div className="flex flex-col">
          {isTruthy(titleImage) ? (
            <img
              src={`${import.meta.env.VITE_S3 as string}/${titleImage ?? ''}`}
              alt="Event title image"
              className="aspect-auto h-12"
            ></img>
          ) : (
            <span className="text-2xl font-semibold leading-7 text-white">
              {title}
            </span>
          )}
          <span className="text-xl leading-6 text-primary-main">{date}</span>
          {isTruthy(location) && (
            <span className="text-xl leading-6 text-text-secondary">
              {location}
            </span>
          )}
          {isTruthy(lowestTicketPrice) && (
            <span className="text-xl leading-6 text-text-secondary">
              A partir de {numberToReais(lowestTicketPrice!)}
            </span>
          )}
        </div>
        {isTruthy(description) && (
          <span className="line-clamp-[2] whitespace-pre-wrap text-lg leading-5 text-white sm:line-clamp-[5] md:line-clamp-[8] lg:line-clamp-[10]">
            {description}
          </span>
        )}
        <Link
          to={`/events/${eventAlias ?? ''}`}
          className="w-fit text-nowrap rounded-full bg-primary-main px-4 py-3 font-bold text-dark-black sm:px-8 md:px-16"
        >
          Comprar agora
        </Link>
      </div>
    </div>
  )
}
