import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type IEventData } from '@/types/hooks/api/useEvent'

export const useGetEventByAlias = (
  eventAlias: string,
): {
  eventData: IEventData | undefined
  error: string | undefined
  isLoading: boolean
  mutate: () => Promise<IEventData | undefined>
} => {
  const { data, error, mutate } = useSWR<IEventData, string>(
    `/events/${eventAlias}`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const eventData = data ?? undefined

  return { eventData, error, isLoading, mutate }
}
