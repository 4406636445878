import { create } from 'zustand'

interface IUser {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  imageKey: string
  balance: number
  verifiedEmail: boolean
}

interface userProps {
  id: number
  username: string
  firstName: string
  lastName: string
  email: string
  balance: number
  phoneNumber: string
  imageKey: string
  userIsLogged: boolean
  followRequests: number
  verifiedEmail?: boolean
  setFollowRequests: (n: number) => void
  loginUser: (user: IUser) => void
  logoutUser: () => void
  setBalance: (balance: number) => void
  setProfileImageKey: (imageKey: string) => void
  setUserIsLogged: (isLogged: boolean) => void
  setVerifiedEmail: (isValidated: boolean) => void
  setUpdateProfile: (user: {
    username: string
    firstName: string
    lastName: string
    email: string
  }) => void
}

export const useUserStore = create<userProps>()((set) => ({
  id: 0,
  username: '',
  email: '',
  firstName: '',
  lastName: '',
  balance: 0,
  imageKey: '',
  phoneNumber: '',
  userIsLogged: false as boolean,
  followRequests: 0,
  verifiedEmail: undefined,
  setFollowRequests: (n: number) => {
    set(() => ({ followRequests: n }))
  },
  loginUser: (user) => {
    const {
      id,
      username,
      firstName,
      lastName,
      email,
      phoneNumber,
      imageKey,
      balance,
      verifiedEmail,
    } = user

    set(() => ({
      id,
      username,
      firstName,
      lastName,
      email,
      phoneNumber,
      imageKey,
      balance,
      verifiedEmail,
    }))
  },
  logoutUser: () => {
    set(() => ({
      id: 0,
      username: '',
      firstName: '',
      lastName: '',
      email: '',
      balance: 0,
      phoneNumber: '',
      imageKey: '',
      userIsLogged: false,
    }))
  },
  setProfileImageKey: (imageKey) => {
    set(() => ({
      imageKey,
    }))
  },
  setBalance: (balance) => {
    set(() => ({
      balance,
    }))
  },
  setUserIsLogged: (isLogged) => {
    set(() => ({
      userIsLogged: isLogged,
    }))
  },
  setVerifiedEmail: (isValidated) => {
    set(() => ({
      verifiedEmail: isValidated,
    }))
  },
  setUpdateProfile: (user) => {
    set(() => ({
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }))
  },
}))
