import { useState } from 'react'
import { api } from '@/services/axios'

import { type IFollowUnfollowOrganizationReturn } from '@/types/hooks/api/useOrganization'
import { type AxiosError } from 'axios'

export const useDeleteUnfollowOrganization = (
  organizationAlias: string,
): {
  isLoading: boolean
  deleteUnfollowOrganization: () => Promise<IFollowUnfollowOrganizationReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function deleteUnfollowOrganization(): Promise<IFollowUnfollowOrganizationReturn> {
    setIsLoading(true)

    try {
      await api.delete(`/organizations/${organizationAlias}/unfollow`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { deleteUnfollowOrganization, isLoading }
}
