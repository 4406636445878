import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type IEventInterest } from '@/types/Event'

export const useGetUsersStatus = (
  eventAlias: string,
): {
  usersData: IEventInterest | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, mutate } = useSWR<IEventInterest, string>(
    `/events/${eventAlias}/status`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const usersData = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return { usersData, error, isLoading, refresh }
}
