import { compareAsc, parseISO } from 'date-fns'

import { type IEventData } from '@/types/Social'
import { isEventFinished } from '@/helpers/isEventFinished'

export function categorizeEventsByDate(events: IEventData[]): {
  pastEvents: IEventData[]
  futureEvents: IEventData[]
} {
  const orderedEvents = events.sort((a, b) =>
    compareAsc(parseISO(a.startDate), parseISO(b.startDate)),
  )
  const pastEvents: IEventData[] = []
  const futureEvents: IEventData[] = []

  for (const event of orderedEvents) {
    if (isEventFinished(event.endDate)) {
      pastEvents.push(event)
    } else {
      futureEvents.push(event)
    }
  }

  return {
    pastEvents,
    futureEvents,
  }
}
