import { create } from 'zustand'

interface RoutingProps {
  toPath: string
  isPinging: boolean
  setToPath: (s: string) => void
  setIsPinging: (p: boolean) => void
}

export const useRoutingStore = create<RoutingProps>()((set) => ({
  toPath: '',
  isPinging: true as boolean,
  setToPath: (s: string) => {
    set(() => ({ toPath: s }))
  },
  setIsPinging: (p: boolean) => {
    set(() => ({ isPinging: p }))
  },
}))
