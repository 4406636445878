import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { DownloadApp } from '@/components/Global/DownloadApp'

import { useGlobalStore } from '@/store/global'
import { QrCode } from '../QRCode'

interface DownloadAppModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  text?: string
}

export function DownloadAppModal({
  closeModal,
  isParentClosing,
  text,
}: DownloadAppModalProps): ReactElement {
  const { isMobile } = useGlobalStore()

  return (
    <Modal closeModal={closeModal} isParentClosing={isParentClosing}>
      <div className="flex w-full flex-col items-center gap-2 p-4">
        <span className="text-3xl font-bold">Baixe o nosso App</span>
        {text !== undefined && <span className="leading-4">{text}</span>}
        {isMobile ? (
          <div className="flex w-full justify-evenly">
            <DownloadApp
              store="AppStore"
              destiny="COSMOS"
              variant="dark"
              size="small"
            />
            <DownloadApp
              store="PlayStore"
              destiny="COSMOS"
              variant="dark"
              size="small"
            />
          </div>
        ) : (
          <div className="mt-8 size-56 pb-8">
            <QrCode
              value="https://gdya.dance/app"
              margin={false}
              variant="light"
              bgTransparent
            />
          </div>
        )}
      </div>
    </Modal>
  )
}
