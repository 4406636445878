import { useEffect, type ReactElement } from 'react'
import { Route, Routes } from 'react-router-dom'

// Subpages
import { UserWallet } from './UserWallet'
import { Transfer } from './Transfer'
import { Sell } from './Sell'
import { WalletHistory } from './WalletHistory'
import { EventHistory } from './EventHistory'
import { EventPayments } from './EventPayments'
import { WalletEvent } from './WalletEvent'

// Fetch
import { useGetWalletByUserId } from '@/hooks/api/Wallet'

// Stores
import { useWalletStore } from '@/store/wallet'

export function Wallet(): ReactElement {
  const { walletTicketGroups, isLoading, refresh } = useGetWalletByUserId()

  const { setWalletEvents, setIsLoadingWalletFetch, setRefresh } =
    useWalletStore()

  useEffect(() => {
    setWalletEvents(walletTicketGroups ?? [])
    setIsLoadingWalletFetch(isLoading)
    setRefresh(refresh)
  }, [walletTicketGroups, isLoading])

  return (
    <>
      <Routes>
        <Route index element={<UserWallet />} />
        <Route path="/:eventAlias" element={<WalletEvent />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/sell" element={<Sell />} />
        <Route path="/history" element={<WalletHistory />} />
        <Route path="/history/:eventAlias" element={<EventHistory />} />
        <Route path="/payments/:eventAlias" element={<EventPayments />} />
      </Routes>
    </>
  )
}
