import { useEffect, useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { Loading } from '@/components/Global/Loading'
import { NotificationHistory } from '@/components/Notifications/NotificationHistory'
import { UnfollowModal } from '@/components/Notifications/UnfollowModal'

import { useGetNotificationHistory } from '@/hooks/api/Notifications'

import { useNotificationStore } from '@/store/notification'
import { useGlobalStore } from '@/store/global'

export function Notifications(): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)

  const navigate = useNavigate()

  const { setShowUnfollowConfirmationModal, showUnfollowConfirmationModal } =
    useNotificationStore()
  const { isMobile } = useGlobalStore()

  const { notificationHistoryData, isLoading, refresh } =
    useGetNotificationHistory()

  useEffect(() => {
    if (!isMobile) {
      navigate('/')
    }
  }, [isMobile])

  if (isLoading) return <Loading />

  return (
    <div className="flex min-h-full flex-col bg-background-main py-4 pb-16">
      <h1 className="px-4 text-2xl text-white">Notificações</h1>
      <div className="w-full p-4">
        {notificationHistoryData !== undefined && (
          <NotificationHistory
            notificationHistoryData={notificationHistoryData}
          />
        )}
      </div>
      {showUnfollowConfirmationModal && (
        <UnfollowModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowUnfollowConfirmationModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          triggerRefresh={async () => {
            await refresh()
          }}
        />
      )}
    </div>
  )
}
