import { useState, type ReactElement, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { SearchSelectUser } from '@/components/Transfer/SearchSelectUser'
import { SelectTransferTickets } from '@/components/Transfer/SelectTransferTickets'
import { ConfirmTransferModal } from '@/components/Transfer/ConfirmTransferModal'
import { WarningModal } from '@/components/Transfer/WarningModal'

import { useGetSearchUsers } from '@/hooks/api/Search'

import { BsArrowLeftShort } from 'react-icons/bs'

import { useTransferStore } from '@/store/transfer'
import { useNavbarStore } from '@/store/navbar'
import { useSnackbarStore } from '@/store/snackbar'

import { type IGenericUser } from '@/types/global/user'

import { USER_SEARCH_ERROR, NO_USER_FOUND } from '@/errors'

export function Transfer(): ReactElement {
  const [searchParam, setSearchParam] = useState('')
  const [users, setUsers] = useState<IGenericUser[]>([])

  const { transferState, setTransferState } = useTransferStore()
  const { hideNavbar } = useNavbarStore()
  const { showErrorSnack } = useSnackbarStore()

  const navigate = useNavigate()
  const { searchUsers, isLoading } = useGetSearchUsers()

  async function handleSearch(): Promise<void> {
    const response = await searchUsers(searchParam)
    if (response.status !== 200) {
      showErrorSnack(USER_SEARCH_ERROR)
    } else if (response.users?.length === 0) {
      showErrorSnack(NO_USER_FOUND)
    } else if (response.users !== undefined) {
      setUsers(response?.users as IGenericUser[])
    }
  }

  useEffect(() => {
    hideNavbar()
  }, [])

  return (
    <div className="flex h-full min-h-full flex-col overflow-y-scroll bg-background-main p-4 pb-24">
      <div className="flex gap-4">
        <button
          className="rounded-full bg-dark-white p-0.5"
          onClick={() => {
            if (transferState === 'selectTicket') {
              setTransferState('selectUser')
            } else {
              navigate('/wallet')
            }
          }}
        >
          <BsArrowLeftShort size={32} color="#232323" />
        </button>
        <h1 className="text-2xl text-white">Transferência</h1>
      </div>

      <div className="mt-6 flex flex-col">
        {transferState === 'selectUser' && (
          <SearchSelectUser
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            handleSearch={handleSearch}
            users={users}
            isSearching={isLoading}
          />
        )}
        {(transferState === 'selectTicket' ||
          transferState === 'confirmTransfer' ||
          transferState === 'warningTicketIsAnnounced') && (
          <SelectTransferTickets />
        )}
        {transferState === 'confirmTransfer' && <ConfirmTransferModal />}
        {transferState === 'warningTicketIsAnnounced' && <WarningModal />}
      </div>
    </div>
  )
}
