import { type ReactElement } from 'react'

import { TicketCounter } from '@/components/Global/TicketCounter'

import { numberToReais } from '@/helpers/formatNumber'

import { isTruthy } from '@/helpers/validation'

import { type IWalletTickets } from '@/types/hooks/api/useWallet'

interface WalletEventTicketProps {
  ticket: IWalletTickets
}

export function WalletEventTicket({
  ticket,
}: WalletEventTicketProps): ReactElement {
  const ticketName = isTruthy(ticket.batchDescription)
    ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
    : ticket.ticketSpecDescription
  const priceText =
    ticket.price > 0 ? `Total: ${numberToReais(ticket.price, 2)}` : 'Grátis'

  return (
    <div className="flex h-fit w-full items-center gap-4 rounded-md bg-dark-black p-4">
      <div className="size-12">
        <TicketCounter direction={0} value={1} />
      </div>
      <div className="flex flex-col">
        <span className="truncate text-xs text-[#a3a3a3]">{ticketName}</span>
        <span className="truncate text-sm font-bold text-white">
          {priceText}
        </span>
      </div>
    </div>
  )
}
