import { useEffect, useRef, useState, type ReactElement } from 'react'
import { useNavigate, useLocation, Outlet, useParams } from 'react-router-dom'
import { motion } from 'framer-motion'

import { type IEventData } from '@/types/hooks/api/useEvent'

import { EventBase } from '@/compositions/EventBase'

import { isTruthy } from '@/helpers/validation'
import { datesToFormat } from '@/helpers/formatDate'
import { numberToReais } from '@/helpers/formatNumber'

import { useGetUsersStatus } from '@/hooks/api/Event'
import { usePixelTrackPageView } from '@/hooks/pixel'

import { useInterestStore } from '@/store/interest'
import { useUserStore } from '@/store/user'
import { useSnackbarStore } from '@/store/snackbar'
import { useEventStore } from '@/store/event'

interface DesktopEventLayoutProps {
  eventData?: IEventData
  refresh: () => void
}

export function DesktopEventLayout({
  eventData,
  refresh,
}: DesktopEventLayoutProps): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [showInterestModal, setShowInterestModal] = useState(false)

  const { eventAlias } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    setRefreshData,
    updateAll,
    setRefreshCurrentUserData,
    alias: interestAlias,
  } = useInterestStore()
  const { setPixelId } = useEventStore()
  const { userIsLogged } = useUserStore()
  const { showSuccessSnack } = useSnackbarStore()

  const { usersData, refresh: refreshUsersStatus } = useGetUsersStatus(
    eventAlias!,
  )
  const { trackPageView } = usePixelTrackPageView()

  const doesAnyHistoryEntryExist = location.key !== 'default'
  const searchParams = new URLSearchParams(document.location.search)
  const link = searchParams.get('link')

  const hasOverlay =
    location.pathname.includes('tickets') ||
    location.pathname.includes('checkout')

  const hasFreeTickets = eventData?.lowestTicketPrice === 0
  const isPastEvent =
    eventData?.date.endDate !== undefined
      ? new Date(eventData?.date.endDate) < new Date()
      : false

  const imageContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Pixel track
    if (isTruthy(eventData?.integrationCredentials?.pixelId))
      trackPageView(eventData?.integrationCredentials?.pixelId ?? '')
  }, [window.fbq, eventData])

  useEffect(() => {
    setPixelId(eventData?.integrationCredentials?.pixelId ?? '')
  }, [eventData])

  useEffect(() => {
    setRefreshData(() => {
      void refreshUsersStatus()
    })
    setRefreshCurrentUserData(refresh)
  }, [refresh])

  useEffect(() => {
    if (usersData !== undefined) updateAll(usersData, eventAlias)
  }, [usersData])

  // Updates users status once the user logs in
  useEffect(() => {
    void refreshUsersStatus()
  }, [userIsLogged])

  useEffect(() => {
    if (isTruthy(link)) {
      showSuccessSnack({
        title: 'Cupom aplicado!',
        description: `${link ?? ''}`,
      })
    }
  }, [])

  return (
    <div className="flex min-h-full w-full bg-background-main py-8 lg:py-12">
      <div className="flex w-1/2 flex-col gap-8">
        <div className="flex flex-col gap-4 pl-8 pr-4 lg:pl-12 lg:pr-8">
          <EventBase.Brief
            title={eventData?.title}
            date={datesToFormat(
              eventData?.date.startDate ?? '',
              eventData?.date.endDate ?? '',
            )}
            location={
              isTruthy(eventData?.location?.name)
                ? eventData?.location?.name
                : 'Local a definir'
            }
            titleImage={eventData?.titleImageKey}
            highlighted
            background="bg-background-main"
            variant="white"
            size="large"
          />
          {isTruthy(eventData?.description) && (
            <EventBase.Description text={eventData?.description} />
          )}
        </div>

        {isTruthy(eventData?.lineup) &&
          (eventData?.lineup?.length ?? 0) > 0 && (
            <div className="flex flex-col gap-4 pr-4 lg:pr-8">
              <span className="pl-8 text-[28px] font-bold text-white lg:pl-12">
                Line-up
              </span>
              <EventBase.LineUp
                artists={eventData?.lineup}
                paddingLeft="pl-8 lg:pl-12"
              />
            </div>
          )}
        {isTruthy(eventData?.spotify) && (
          <div className="pl-8 pr-4 lg:pl-12 lg:pr-8">
            <EventBase.Playlist spotify={eventData?.spotify} />
          </div>
        )}
        {(isTruthy(eventData?.location) ||
          isTruthy(eventData?.terms) ||
          isTruthy(eventData?.faq)) && (
          <div className="flex flex-col gap-2 pl-8 pr-4 lg:pl-12 lg:pr-8">
            <span className="mb-2 text-[28px] font-bold text-white">Infos</span>
            <EventBase.Info.Time eventData={eventData} />
            {isTruthy(eventData?.location) && (
              <EventBase.Info.Location
                name={eventData?.location.name}
                fullAddress={eventData?.location.address}
                latitude={eventData?.location.latitude}
                longitude={eventData?.location.longitude}
              />
            )}
            {isTruthy(eventData?.terms) && (
              <EventBase.Info.Terms terms={eventData?.terms} />
            )}
            {isTruthy(eventData?.faq) && eventData!.faq.length > 0 && (
              <EventBase.Info.Faq questionsAnswers={eventData?.faq} />
            )}
          </div>
        )}
        {eventAlias === interestAlias && !eventData!.hideSocial && (
          <div className="flex flex-col gap-4 px-4 pl-8 lg:pl-12 lg:pr-8">
            <span className="text-[28px] font-bold text-white">RSVPs</span>
            <EventBase.RSVPs
              openEventInterestModal={() => {
                setShowInterestModal(true)
              }}
            />
          </div>
        )}
        <EventBase.Bit className="m-0 pl-8 lg:pl-12 lg:pr-8">
          {eventData?.eventType === 'CLOSE_FRIENDS' ? (
            <EventBase.CreateEvent
              title="Crie seu evento close friends!"
              subtitle="Clique aqui e crie sua festa em minutos!"
            />
          ) : (
            <EventBase.CreateEvent
              title="Crie seu evento com a gente!"
              subtitle="Clique aqui e crie sua festa em minutos!"
            />
          )}
        </EventBase.Bit>
        {isTruthy(eventData?.partners) && eventData!.partners.length > 0 && (
          <div className="flex flex-col gap-4 pr-4 lg:pr-8">
            <span className="pl-8 text-[28px] font-bold text-white lg:pl-12">
              Parceiros
            </span>
            <EventBase.Partners
              partners={eventData?.partners}
              paddingLeft="pl-8 lg:pl-12"
            />
          </div>
        )}
        {isTruthy(eventData?.organization) && eventData?.hosts.length === 0 && (
          <div className="w-full pl-8 pr-4 lg:pl-12 lg:pr-8">
            <EventBase.Organization
              organizationData={{
                alias: eventData?.organization.alias ?? '',
                name: eventData?.organization.name ?? '',
                imageKey: eventData?.organization.imageKey ?? '',
                description: eventData?.organization.description ?? '',
                followStatus:
                  eventData?.organization.organizationFollowStatus ?? '',
              }}
              refresh={refresh}
            />
          </div>
        )}
        {isTruthy(eventData?.hosts.length) && (
          <div className="w-full pl-8 pr-4 lg:pl-12 lg:pr-8">
            <EventBase.Hosts hosts={eventData?.hosts} />
          </div>
        )}
      </div>
      <div className="sticky top-8 flex h-screen w-1/2 flex-col lg:top-12">
        <div className="pl-4 pr-8 lg:pl-8 lg:pr-12">
          <div
            ref={imageContainerRef}
            style={{
              height: imageContainerRef.current?.clientWidth ?? 'auto',
            }}
          >
            <EventBase.Image
              imageKey={eventData?.imageKey ?? ''}
              onClick={() => {
                if (doesAnyHistoryEntryExist) navigate(-1)
                else navigate(`/`)
              }}
              imgClassName="rounded-lg "
              onFire={eventData?.onFire}
              closeFriends={eventData?.eventType === 'CLOSE_FRIENDS'}
              share
              eventName={eventData?.title ?? ''}
            />
          </div>
        </div>
        {eventData?.lowestTicketPrice !== undefined && (
          <div className="pl-4 pr-8 pt-4 lg:pl-8 lg:pr-12">
            <div className="rounded-md bg-dark-black">
              <EventBase.CallToAction
                value={
                  hasFreeTickets
                    ? 'Ingressos grátis disponíveis'
                    : eventData?.lowestTicketPrice === null
                      ? 'Ingressos indisponíveis'
                      : `A partir de ${numberToReais(eventData?.lowestTicketPrice, 2)}`
                }
                buttonText={
                  hasFreeTickets ? 'Reservar agora' : 'Comprar ingresso'
                }
                buttonEnabled
                buttonOnClick={() => {
                  navigate(`tickets`)
                }}
                eventFinished={isPastEvent}
                hideButton={eventData?.lowestTicketPrice === null}
              />
            </div>
          </div>
        )}
        {!eventData!.hideSocial && (
          <div className="pl-4 pr-8 pt-4 lg:pl-8 lg:pr-12">
            <EventBase.Interest
              openEventInterestModal={() => {
                setShowInterestModal(true)
              }}
              initialUserStatus={eventData?.userStatus}
            />
          </div>
        )}
        {isTruthy(eventData?.reactions) && !eventData!.hideReactions && (
          <div className="pl-4 pr-8 pt-4 lg:pl-8 lg:pr-12">
            <EventBase.Reactions
              eventId={eventData?.id ?? 0}
              eventReactions={eventData?.reactions}
            />
          </div>
        )}
      </div>

      {hasOverlay && (
        <motion.div
          key="overlay-1"
          className="fixed inset-0 z-40 flex h-max min-h-full w-full items-center justify-center backdrop-blur-xl backdrop-brightness-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        />
      )}

      {hasOverlay ? (
        <div className="fixed inset-0 z-40 flex h-max min-h-full w-full items-center justify-center">
          <Outlet />
        </div>
      ) : (
        <Outlet />
      )}

      {showInterestModal && (
        <EventBase.InterestModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowInterestModal, 400, false)
          }}
          interestData={usersData}
        />
      )}
    </div>
  )
}
