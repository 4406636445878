import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type IEventPayment } from '@/types/EventPayment'

export const useGetEventPayment = (
  eventAlias: string,
): {
  eventPaymentsData: IEventPayment | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => void
} => {
  const { data, error, mutate } = useSWR<IEventPayment, string>(
    `/events/${eventAlias}/payments`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const eventPaymentsData = data ?? undefined

  function refresh(): void {
    void mutate()
  }

  return { eventPaymentsData, error, isLoading, refresh }
}
