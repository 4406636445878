import { useState } from 'react'
import { api } from '@/services/axios'

import { type IFollowUnfollowOrganizationReturn } from '@/types/hooks/api/useOrganization'
import { type AxiosError } from 'axios'

export const usePostFollowOrganization = (
  organizationAlias: string,
): {
  isLoading: boolean
  postFollowOrganization: () => Promise<IFollowUnfollowOrganizationReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postFollowOrganization(): Promise<IFollowUnfollowOrganizationReturn> {
    setIsLoading(true)

    try {
      await api.post(`/organizations/${organizationAlias}/follow`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { postFollowOrganization, isLoading }
}
