import { create } from 'zustand'
import { type Snack } from '@/types/snackbar'

interface IShowSnack {
  title: string
  description?: string
  duration?: number | false
  displayMode?: 'ROOT' | 'MODAL' | 'FOOTER'
}

interface snackbarStoreType {
  snack: Snack | null
  displayMode: 'ROOT' | 'MODAL' | 'FOOTER'
  setSnackbar: (snack: Snack) => void
  clear: () => void
  showErrorSnack: ({
    title,
    description,
    duration,
    displayMode,
  }: IShowSnack) => void
  showSuccessSnack: ({
    title,
    description,
    duration,
    displayMode,
  }: IShowSnack) => void
  showInfoSnack: ({
    title,
    description,
    duration,
    displayMode,
  }: IShowSnack) => void
}

export const useSnackbarStore = create<snackbarStoreType>((set) => ({
  snack: null,
  displayMode: 'ROOT',
  setSnackbar: (snack: Snack) => {
    set({ snack })
  },
  clear: () => {
    set({ snack: null })
  },
  showErrorSnack: ({
    title,
    description,
    duration = 4000,
    displayMode = 'ROOT',
  }: IShowSnack) => {
    set({ snack: { title, description, type: 'ERROR' }, displayMode })

    if (duration !== false) {
      setTimeout(() => {
        set({ snack: null })
      }, duration)
    }
  },
  showSuccessSnack: ({
    title,
    description,
    duration = 4000,
    displayMode = 'ROOT',
  }: IShowSnack) => {
    set({ snack: { title, description, type: 'SUCCESS' }, displayMode })

    if (duration !== false) {
      setTimeout(() => {
        set({ snack: null })
      }, duration)
    }
  },
  showInfoSnack: ({
    title,
    description,
    duration = 4000,
    displayMode = 'ROOT',
  }: IShowSnack) => {
    set({ snack: { title, description, type: 'INFO' }, displayMode })

    if (duration !== false) {
      setTimeout(() => {
        set({ snack: null })
      }, duration)
    }
  },
}))
