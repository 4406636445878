import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion'

// import { CheckoutSuccessModal } from '@/components/CheckoutSuccessModal'
import { TicketSummary } from '@/components/Global/TicketSummary'
import { PaymentSelector } from '@/components/PaymentSelector'
import { PixPayment } from '@/components/PixPayment'

import { EventBase } from '@/compositions/EventBase'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import { useTicketsStore } from '@/store/tickets'
import { CheckoutSuccessModal } from '@/components/CheckoutSuccessModal'
import { useUserStore } from '@/store/user'

interface EventCheckoutMobileProps {
  total: number
  pixCopied: boolean
  payTicket: boolean
  isLoadingVerify: boolean
  hasTicketSelected: boolean
  acceptedPaymentMethods: {
    PIX: boolean
    CREDITCARD: boolean
    INTL_CREDITCARD: boolean
    BOLETO: boolean
  }
  allFreeTickets: boolean
  pixCode: string
  setPixCopied: (code: boolean) => void
  handlePayment: () => Promise<boolean>
  handleVerifyPayment: () => Promise<boolean>
  copyToClipboard: () => Promise<void>
  selectedPayment: 'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | ''
  setSelectedPayment: (
    value: 'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | '',
  ) => void
  isLoading: boolean
  paymentSuccessfull: boolean
  isModalClosing: boolean
  setIsModalClosing: (value: boolean) => void
  isPastEvent: boolean
}

export function EventCheckoutMobile({
  total,
  pixCopied,
  payTicket,
  isLoadingVerify,
  hasTicketSelected,
  acceptedPaymentMethods,
  allFreeTickets,
  handlePayment,
  handleVerifyPayment,
  copyToClipboard,
  selectedPayment,
  setSelectedPayment,
  pixCode,
  setPixCopied,
  isLoading,
  paymentSuccessfull,
  isModalClosing,
  setIsModalClosing,
  isPastEvent,
}: EventCheckoutMobileProps): ReactElement {
  const { tickets, getFees } = useTicketsStore()

  const { username } = useUserStore()

  const navigate = useNavigate()

  const footerButtonText = payTicket
    ? pixCopied
      ? 'Código copiado'
      : 'Copiar código'
    : allFreeTickets
      ? 'Reservar'
      : 'Pagar'

  const footerButtonEnabled =
    ((selectedPayment !== '' && !pixCopied) || allFreeTickets) && !isLoading

  const handleFooterButtonClick: () => void = () => {
    if (payTicket) {
      setPixCopied(true)
      void copyToClipboard()
    } else {
      void handlePayment()
    }
  }

  return (
    <div className="pb-60">
      <motion.div
        className="w-full"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
      >
        <div className="flex w-full flex-col gap-2 p-4">
          {!payTicket &&
            tickets.map((ticket) => {
              const title = isTruthy(ticket.batchDescription)
                ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                : `${ticket.ticketSpecDescription}`

              if (ticket.quantitySelected === 0) return null
              return (
                <TicketSummary
                  key={ticket.ticketSpecId}
                  title={title}
                  quantity={ticket.quantitySelected}
                  thirdInfo={
                    ticket.price === 0
                      ? `Grátis`
                      : `Total: ${numberToReais(
                          ticket.finalPrice * ticket.quantitySelected,
                          2,
                        )}`
                  }
                  variant="light"
                  counterVariant="dark"
                />
              )
            })}
        </div>
      </motion.div>

      <EventBase.Footer
        title="Valor total"
        value={hasTicketSelected ? total : 0}
        buttonText={footerButtonText}
        buttonEnabled={footerButtonEnabled}
        buttonOnClick={handleFooterButtonClick}
        isLoading={isLoading}
        eventFinished={isPastEvent}
      >
        {!allFreeTickets ? (
          <AnimatePresence mode="wait">
            {!payTicket && (
              <motion.div
                key="payment-selector"
                initial={{ y: 200 }}
                animate={{ y: 0 }}
                exit={{ y: 200, height: 0 }}
                transition={{ duration: 0.2 }}
                className="-z-20 w-full bg-dark-black px-4 pb-1 pt-4"
              >
                <PaymentSelector
                  selectedPayment={selectedPayment}
                  setSelectedPayment={setSelectedPayment}
                  acceptedPaymentMethods={acceptedPaymentMethods}
                  fees={getFees()}
                />
              </motion.div>
            )}
            {payTicket && (
              <motion.div
                key="pix"
                initial={{ y: 450 }}
                animate={{ y: 0 }}
                exit={{ y: 450 }}
                transition={{ duration: 0.2 }}
                className="-z-20 w-full bg-dark-black px-4 pb-1 pt-4"
              >
                <PixPayment
                  code={pixCode}
                  codeCopied={pixCopied}
                  isLoadingVerify={isLoadingVerify}
                  handleVerifyPayment={handleVerifyPayment}
                  copyToClipboard={copyToClipboard}
                />
              </motion.div>
            )}
          </AnimatePresence>
        ) : (
          <></>
        )}
      </EventBase.Footer>
      {paymentSuccessfull && (
        <CheckoutSuccessModal
          title={total === 0 ? 'Reserva realizada' : 'Compra realizada'}
          onClick={() => {
            setIsModalClosing(true)
            setTimeout(navigate, 300, `/memories/${username}`)
          }}
          isParentClosing={isModalClosing}
        />
      )}
    </div>
  )
}
