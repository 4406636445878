import { useState, useEffect } from 'react'
import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type OrderDetailsData } from '@/types/hooks/api/useOrder'

export const useGetOrderDetailsByOrderCode = (
  code: string,
): {
  orderDetailsData: OrderDetailsData | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const { data, error } = useSWR<OrderDetailsData, string>(
    '/orders/' + code,
    fetcher,
  )

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data])

  const orderDetailsData: OrderDetailsData | undefined =
    data !== undefined
      ? {
          ...data,
        }
      : undefined

  return { orderDetailsData, error, isLoading }
}
