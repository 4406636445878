import { useEffect, useState, type ReactElement } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'

import { TicketSummary } from '@/components/Global/TicketSummary'
import { PaymentSelector } from '@/components/PaymentSelector'
import { PixPayment } from '@/components/PixPayment'

import { EventBase } from '@/compositions/EventBase'

import { datesToFormat } from '@/helpers/formatDate'
import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import { useEventStore } from '@/store/event'
import { useTicketsStore } from '@/store/tickets'

import { IoArrowBack, IoClose, IoQrCodeSharp } from 'react-icons/io5'
import { twMerge } from 'tailwind-merge'
import { BsCheckLg } from 'react-icons/bs'
import { useUserStore } from '@/store/user'

interface EventCheckoutDesktopProps {
  payTicket: boolean
  selectedPayment: 'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | ''
  setSelectedPayment: (
    value: 'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | '',
  ) => void
  acceptedPaymentMethods: {
    PIX: boolean
    CREDITCARD: boolean
    INTL_CREDITCARD: boolean
    BOLETO: boolean
  }
  hasTicketSelected: boolean
  total: number
  pixCopied: boolean
  allFreeTickets: boolean
  isLoading: boolean
  handlePayment: () => Promise<boolean>
  handleVerifyPayment: () => Promise<boolean>
  copyToClipboard: () => Promise<void>
  pixCode: string
  isLoadingVerify: boolean
  paymentSuccessfull: boolean
}

export function EventCheckoutDesktop({
  payTicket,
  selectedPayment,
  setSelectedPayment,
  acceptedPaymentMethods,
  hasTicketSelected,
  total,
  pixCopied,
  allFreeTickets,
  isLoading,
  handlePayment,
  copyToClipboard,
  handleVerifyPayment,
  pixCode,
  isLoadingVerify,
  paymentSuccessfull,
}: EventCheckoutDesktopProps): ReactElement {
  const [showPaymentMethods, setShowPaymentMethods] = useState(true)
  const [showPixPayment, setShowPixPayment] = useState(false)
  const [showBackFace, setShowBackFace] = useState(false)
  const [showPaymentSuccessfull, setShowPaymentSuccessfull] = useState(false)

  // Width calculations
  const singleCard = Math.min(500, window.innerWidth - 40)
  const leftDoubleCard = Math.min(400, ((window.innerWidth - 60) * 4) / 7)
  const rightDoubleCard = Math.min(300, ((window.innerWidth - 60) * 3) / 7)

  const { currentEvent, setTicketQuantity } = useEventStore()
  const { resetTickets, tickets, getFees } = useTicketsStore()
  const { username } = useUserStore()

  const { eventAlias } = useParams()
  const navigate = useNavigate()

  async function handleCheckout(): Promise<void> {
    const response = await handlePayment()

    if (selectedPayment === 'PIX' && response) {
      setShowPaymentMethods(false)
      setTimeout(setShowBackFace, 600, true)
      setTimeout(setShowPixPayment, 600, true)
    }
  }

  useEffect(() => {
    if (paymentSuccessfull) {
      setShowPaymentMethods(false)
      setShowPixPayment(false)
      setShowBackFace(false)
      setTimeout(setShowPaymentSuccessfull, 100, true)
    }
  }, [paymentSuccessfull])

  useEffect(() => {
    // Lock the scroll
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div className="flex size-full items-center justify-center pb-16">
      <div className="absolute bottom-8 left-8 z-30 flex items-center gap-2">
        <span className="font-regular text-lg text-white">Ingressos</span>
        <span className="text-lg font-semibold text-white">{'->'}</span>
        <span
          className={twMerge(
            'text-lg font-semibold text-primary-main',
            showPixPayment && 'font-normal text-white',
          )}
        >
          Pagamento
        </span>
        {showPixPayment && (
          <>
            <span className="text-lg font-semibold text-white">{'->'}</span>
            <span className="text-lg font-semibold text-primary-main">PIX</span>
          </>
        )}
      </div>

      <motion.button
        key="back-button"
        className="absolute left-8 top-8 z-30 flex size-8 items-center justify-center rounded-full bg-white"
        onClick={() => {
          setShowPaymentMethods(false)
          setTimeout(() => {
            navigate(`/events/${eventAlias ?? ''}/tickets`)
          }, 400)
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.4, ease: 'easeInOut' }}
      >
        <IoArrowBack color="#181818" size={20} />
      </motion.button>

      <button
        className="absolute right-8 top-8 z-30 flex size-8 items-center justify-center rounded-full bg-white"
        onClick={() => {
          resetTickets()
          useTicketsStore.persist.clearStorage()
          setTicketQuantity(0)
          navigate(`/events/${eventAlias ?? ''}`)
        }}
      >
        <IoClose color="#181818" size={22} />
      </button>

      <motion.div
        className="relative flex w-full flex-col items-start justify-center"
        initial={{ width: singleCard }}
        animate={{
          width: showPaymentMethods
            ? leftDoubleCard + rightDoubleCard + 20
            : singleCard,
        }}
        transition={{ duration: 0.4, ease: 'easeOut' }}
      >
        <div className="z-40 flex flex-col gap-4" style={{ perspective: 1000 }}>
          {currentEvent !== undefined && (
            <div className="w-full">
              <EventBase.Thumbnail
                date={datesToFormat(
                  currentEvent.date.startDate,
                  currentEvent.date.endDate,
                )}
                title={currentEvent.title}
                location={
                  currentEvent?.location?.name ??
                  currentEvent?.location?.address ??
                  'Local a definir'
                }
                imageKey={currentEvent.imageKey}
                briefVariant="white"
                highlighted
                imageVariant="large"
              />
            </div>
          )}
          <div className="overflow-scroll">
            <motion.div
              key="tickets"
              className="flex size-full h-[500px] rounded-lg bg-background-main px-4"
              initial={{ width: singleCard, rotateY: 0 }}
              animate={{
                width: showPaymentMethods ? leftDoubleCard : singleCard,
                rotateY: showBackFace ? 180 : 0,
              }}
              transition={{ duration: 0.4, ease: 'easeInOut' }}
              style={{ transformStyle: 'preserve-3d' }}
            >
              {showPaymentSuccessfull ? (
                <div className="flex size-full h-[100px] flex-col items-center gap-12 p-8 text-white">
                  <div className="flex flex-col items-center">
                    <BsCheckLg
                      className="size-14 rounded-full bg-primary-main p-3"
                      color="#181818"
                    />
                    <h1 className="mt-2 text-2xl">
                      {total === 0 ? 'Reserva realizada' : 'Compra realizada'}
                    </h1>
                  </div>

                  <div className="flex w-[90%] flex-col items-center">
                    <div className="rounded-full bg-dark-black p-8">
                      <IoQrCodeSharp color="white" size={80} />
                    </div>
                    <span className="mt-4 text-center text-sm leading-4">
                      Para sua segurança, o acesso ao evento é feito com o seu
                      <strong> identificador</strong> - um QR-Code único.
                    </span>
                  </div>

                  <button
                    className="w-full rounded-full bg-primary-main p-3 font-bold text-black hover:cursor-pointer"
                    onClick={() => {
                      navigate(`/memories/${username}`)
                    }}
                  >
                    Continuar
                  </button>
                </div>
              ) : (
                <div className="relative size-full">
                  <div className="absolute left-0 top-0 z-10 h-4 w-full bg-gradient-to-b from-background-main to-transparent" />
                  <div className="absolute bottom-0 left-0 z-10 h-4 w-full bg-gradient-to-t from-background-main to-transparent" />
                  <div className="flex size-full flex-col gap-2 overflow-scroll py-4">
                    {tickets
                      .filter((ticket) => ticket.quantitySelected > 0)
                      .map((ticket) => {
                        const title = isTruthy(ticket.batchDescription)
                          ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                          : `${ticket.ticketSpecDescription}`
                        return (
                          <div
                            key={ticket.ticketSpecId}
                            className="rounded-md bg-dark-black p-4"
                          >
                            <TicketSummary
                              title={title}
                              quantity={ticket.quantitySelected}
                              thirdInfo={
                                ticket.price === 0
                                  ? `Grátis`
                                  : `Total: ${numberToReais(
                                      ticket.finalPrice *
                                        ticket.quantitySelected,
                                      2,
                                    )}`
                              }
                              variant="light"
                              counterVariant="dark"
                            />
                          </div>
                        )
                      })}
                  </div>
                </div>
              )}

              <div
                className="absolute left-0 top-0 flex size-full flex-col gap-4 rounded-md bg-background-main p-4"
                style={{
                  backfaceVisibility: 'hidden',
                  transform: 'rotateY(180deg)',
                }}
              >
                {showPixPayment && (
                  <PixPayment
                    code={pixCode}
                    codeCopied={pixCopied}
                    isLoadingVerify={isLoadingVerify}
                    handleVerifyPayment={handleVerifyPayment}
                    copyToClipboard={copyToClipboard}
                    showQRCode
                  />
                )}
              </div>
            </motion.div>
          </div>
        </div>

        {!showPixPayment && !showPaymentSuccessfull && (
          <motion.div
            key="payment-methods"
            className="absolute bottom-0 right-0 z-30 flex flex-col gap-4"
            style={{
              width: rightDoubleCard,
            }}
          >
            {!allFreeTickets && (
              <PaymentSelector
                selectedPayment={selectedPayment}
                setSelectedPayment={setSelectedPayment}
                acceptedPaymentMethods={acceptedPaymentMethods}
                fees={getFees()}
              />
            )}
            <div className="rounded-md bg-background-main">
              <EventBase.CallToAction
                title={'Valor total'}
                value={hasTicketSelected ? total : 0}
                buttonText={
                  payTicket
                    ? pixCopied
                      ? 'Código copiado'
                      : 'Copiar código'
                    : allFreeTickets
                      ? 'Reservar'
                      : 'Pagar'
                }
                buttonEnabled={
                  ((selectedPayment !== '' && !pixCopied) || allFreeTickets) &&
                  !isLoading
                }
                buttonOnClick={handleCheckout}
                isLoading={isLoading}
              />
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  )
}
