import { AnimatePresence, motion } from 'framer-motion'
import { type ButtonHTMLAttributes, type ReactElement } from 'react'
import { IoMdHeartEmpty, IoMdHeart } from 'react-icons/io'

interface EventHeartProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isActive: boolean
  parentWidth: number
}

export function EventHeart({
  isActive,
  parentWidth,
  ...props
}: EventHeartProps): ReactElement {
  const divSize = parentWidth * 0.12
  const heartSize = Math.min(divSize * 0.7, 18)

  return (
    <button
      className="flex max-h-[32px] min-h-[20px] min-w-[20px] max-w-[32px] items-center justify-center rounded-full bg-white shadow-sm"
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (props.onClick !== undefined) props.onClick(e)
      }}
      style={{
        width: divSize,
        height: divSize,
      }}
    >
      <AnimatePresence mode="wait">
        {isActive ? (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.05 }}
            key="heart-full"
          >
            <IoMdHeart size={heartSize} color="#FF5C5C" />
          </motion.div>
        ) : (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.05 }}
            key="heart-empty"
          >
            <IoMdHeartEmpty size={heartSize} color="#181818" />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  )
}
