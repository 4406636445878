import { create } from 'zustand'

import { type IEventInterest, type IEventInterestData } from '@/types/Event'

interface interestProps {
  userStatus: 'GOING' | 'INTERESTED' | 'VIEWED'
  alias: string | undefined
  going: IEventInterestData
  interested: IEventInterestData
  viewed: IEventInterestData
  noNewUsers: {
    GOING: boolean
    INTERESTED: boolean
    VIEWED: boolean
  }
  updateAll: (data: IEventInterest, alias: string | undefined) => void
  appendAll: (data: IEventInterest) => void
  changeFollowStatus: (
    userId: number,
    followRequest: {
      id?: number
      status: 'FOLLOWING' | 'NOT_FOLLOWING' | 'PENDING'
    },
  ) => void
  refreshData?: () => void
  setRefreshData: (refresh: () => void) => void
  refreshCurrentUserData?: () => void
  setRefreshCurrentUserData: (refresh: () => void) => void
  closeModal?: () => void
  setCloseModal: (closeModal: () => void) => void
  setNoNewUsers: (noNewUsers: {
    GOING: boolean
    INTERESTED: boolean
    VIEWED: boolean
  }) => void
  setUserStatus: (newStatus: 'GOING' | 'INTERESTED' | 'VIEWED') => void
}

const initialInterestData = {
  total: 0,
  users: [],
}

export const useInterestStore = create<interestProps>()((set) => ({
  userStatus: 'VIEWED',
  alias: undefined,
  going: initialInterestData,
  interested: initialInterestData,
  viewed: initialInterestData,
  noNewUsers: {
    GOING: false,
    INTERESTED: false,
    VIEWED: false,
  },
  updateAll: (data: IEventInterest, alias: string | undefined) => {
    set(() => ({
      alias,
      going: data.GOING,
      interested: data.INTERESTED,
      viewed: data.VIEWED,
    }))
  },
  appendAll: (data: IEventInterest) => {
    set((state) => ({
      going: {
        total: state.going.total,
        users: [...state.going.users, ...data.GOING.users],
      },
      interested: {
        total: state.interested.total,
        users: [...state.interested.users, ...data.INTERESTED.users],
      },
      viewed: {
        total: state.viewed.total,
        users: [...state.viewed.users, ...data.VIEWED.users],
      },
    }))
  },
  changeFollowStatus: (
    userId: number,
    followRequest: {
      id?: number
      status: 'FOLLOWING' | 'NOT_FOLLOWING' | 'PENDING'
    },
  ) => {
    set((state) => {
      const going = state.going.users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            followRequest,
          }
        }
        return user
      })

      const interested = state.interested.users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            followRequest,
          }
        }
        return user
      })

      const viewed = state.viewed.users.map((user) => {
        if (user.id === userId) {
          return {
            ...user,
            followRequest,
          }
        }
        return user
      })

      return {
        going: { total: going.length, users: going },
        interested: { total: interested.length, users: interested },
        viewed: { total: viewed.length, users: viewed },
      }
    })
  },
  refreshData: undefined,
  setRefreshData: (refresh) => {
    set(() => ({
      refreshData: refresh,
    }))
  },
  refreshCurrentUserData: undefined,
  setRefreshCurrentUserData: (refresh) => {
    set(() => ({
      refreshCurrentUserData: refresh,
    }))
  },
  closeModal: undefined,
  setCloseModal: (closeModal) => {
    set(() => ({
      closeModal,
    }))
  },
  setNoNewUsers: (noNewUsers) => {
    set(() => ({
      noNewUsers,
    }))
  },
  setUserStatus: (newStatus: 'GOING' | 'INTERESTED' | 'VIEWED') => {
    set(() => ({
      userStatus: newStatus,
    }))
  },
}))
