import { type ReactElement, useState, useEffect, useRef } from 'react'
import { Image, Transformer } from 'react-konva/lib/ReactKonvaCore'

import 'konva/lib/shapes/Image'
import 'konva/lib/shapes/Transformer'
import { type KonvaEventObject } from 'konva/lib/Node'

interface StickerPositionProps {
  x: number
  y: number
  rotation: number
}

interface StickerProps {
  id: number
  stickerImageKey: string
  isSelected: boolean
  onSelect: () => void
  setStickerPosition: (s: StickerPositionProps) => void
  stickerPosition: StickerPositionProps | undefined
}

interface IImgRatio {
  width: number
  height: number
}

export function Sticker({
  id,
  stickerImageKey,
  isSelected,
  onSelect,
  setStickerPosition,
}: StickerProps): ReactElement {
  const [image, setImage] = useState<HTMLImageElement>()
  const [imageIsLoaded, setImageIsLoaded] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [imgRatio, setImgRatio] = useState<IImgRatio>()

  // eslint-disable-next-line
  const imageRef = useRef<any>()
  // eslint-disable-next-line
  const trRef = useRef<any>()

  function handleDragStart(): void {
    setIsDragging(true)
  }

  function handleDragEnd(e: KonvaEventObject<DragEvent>): void {
    setIsDragging(false)
    setStickerPosition({
      x: e.target.x() / stageWidth,
      y: e.target.y(),
      rotation: e.target.rotation(),
    })
  }

  useEffect(() => {
    const img = new window.Image()
    img.src = `${import.meta.env.VITE_S3 as string}/${stickerImageKey}`
    img.onload = () => {
      // Calculating the image diagonal
      const currentWidth = img.naturalWidth
      const currentHeight = img.naturalHeight

      const diagonalReference = 350

      const currentDiagonal = Math.sqrt(currentHeight ** 2 + currentWidth ** 2)

      const diagonalRatio = diagonalReference / currentDiagonal
      const widthRatio = Math.round(currentWidth * diagonalRatio)
      const heightRatio = Math.round(currentHeight * diagonalRatio)

      setImgRatio({
        width: widthRatio,
        height: heightRatio,
      })

      setImageIsLoaded(true)
      setImage(img)
    }
  }, [])

  useEffect(() => {
    if (
      isSelected &&
      trRef.current !== undefined &&
      imageRef.current !== undefined &&
      imageIsLoaded
    ) {
      // eslint-disable-next-line
      trRef.current.nodes([imageRef.current])
      // eslint-disable-next-line
      trRef.current.getLayer().batchDraw()
    }
  }, [isSelected, imageIsLoaded, trRef, imageRef])

  useEffect(() => {
    if (imageIsLoaded) setTimeout(onSelect, 100)
  }, [imageIsLoaded])

  const centerWidth =
    (window.innerWidth > 432 ? 432 / 2 : window.innerWidth / 2) -
    (imgRatio?.width !== undefined ? imgRatio.width / 2 : 0)

  const centerHeight =
    (window.innerHeight > 936 ? 936 / 2 : window.innerHeight / 2) -
    (imgRatio?.height !== undefined ? imgRatio.height / 2 : 0) -
    100

  const stageWidth = window.innerWidth < 432 ? window.innerWidth : 432

  useEffect(() => {
    setStickerPosition({
      x: centerWidth / stageWidth,
      y: centerHeight,
      rotation: 0,
    })
  }, [image])

  return (
    <>
      <Image
        image={image}
        alt="Sticker"
        id={id.toString()}
        x={centerWidth}
        y={centerHeight}
        opacity={1}
        draggable
        ref={imageRef}
        shadowColor="black"
        shadowBlur={isDragging ? 20 : 10}
        shadowOpacity={0.6}
        onClick={onSelect}
        onTouchStart={onSelect}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onTransformEnd={handleDragEnd}
        width={imgRatio?.width}
        height={imgRatio?.height}
      />
      {isSelected && imageIsLoaded && (
        <Transformer
          ref={trRef}
          anchorCornerRadius={20}
          anchorStrokeWidth={2}
          anchorSize={25}
          anchorFill="#232323"
          anchorStroke="#D5FF5C"
          rotateEnabled={true}
          resizeEnabled={false}
          rotateAnchorOffset={25}
          rotationSnaps={[0, 90, 180, 270]}
          borderStroke="#D5FF5C"
          borderDash={[20, 7]}
        />
      )}
    </>
  )
}
