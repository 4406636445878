import { useState, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { type IGenericOrganization } from '@/types/hooks/api/useSearch'

import { Button } from '@/components/Global/Button'

import {
  useDeleteUnfollowOrganization,
  usePostFollowOrganization,
} from '@/hooks/api/Organization'

import { useSnackbarStore } from '@/store/snackbar'
import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'

interface HeaderSearchOrganizationCardProps {
  organization: IGenericOrganization
  closeSearch: () => void
}

export function HeaderSearchOrganizationCard({
  organization,
  closeSearch,
}: HeaderSearchOrganizationCardProps): ReactElement {
  const [localFollowStatus, setLocalFollowStatus] = useState<
    'FOLLOWING' | 'NOT_FOLLOWING' | undefined
  >(organization.followStatus ?? undefined)

  const numberOfEventsText =
    organization.numberOfEvents === 0
      ? 'Nenhum evento'
      : organization.numberOfEvents > 1
        ? `${organization.numberOfEvents} eventos`
        : '1 evento'

  const { showErrorSnack } = useSnackbarStore()
  const { userIsLogged } = useUserStore()
  const { setShowLoginModal } = useGlobalStore()

  const { postFollowOrganization, isLoading: isLoadingFollow } =
    usePostFollowOrganization(organization.alias)
  const { deleteUnfollowOrganization, isLoading: isLoadingUnfollow } =
    useDeleteUnfollowOrganization(organization.alias)

  async function handleFollowOrganization(): Promise<void> {
    if (!userIsLogged) {
      closeSearch()
      setTimeout(setShowLoginModal, 400, true)
      return
    }
    const response = await postFollowOrganization()
    if (response.status === 200) {
      setLocalFollowStatus('FOLLOWING')
    } else {
      showErrorSnack({
        title: 'Erro ao seguir organização',
        description: 'Tente novamente',
        displayMode: 'ROOT',
      })
    }
  }

  async function handleUnfollowOrganization(): Promise<void> {
    const response = await deleteUnfollowOrganization()
    if (response.status === 200) {
      setLocalFollowStatus('NOT_FOLLOWING')
    } else {
      showErrorSnack({
        title: 'Erro ao deixar de seguir organização',
        description: 'Tente novamente',
        displayMode: 'ROOT',
      })
    }
  }

  return (
    <div className="flex w-full items-center gap-4">
      <Link
        to={`/organizations/${organization.alias}`}
        className="flex w-full items-center gap-4"
      >
        <div className="size-12">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${organization.imageKey}`}
            alt="Organization image"
            className="size-full rounded-full"
          />
        </div>

        <div className="flex grow flex-col">
          <span className="text-xs font-semibold">{organization.name}</span>
          <span className="text-xs text-dark-dark-gray">
            {numberOfEventsText}
          </span>
        </div>
      </Link>

      <div className="h-6 w-[140px]">
        {localFollowStatus === 'FOLLOWING' ? (
          <Button
            className="h-6 w-[140px] text-nowrap rounded-full bg-dark-light-gray px-6 py-1 text-xs font-semibold"
            text="Deixar de seguir"
            enabled={!isLoadingUnfollow}
            isLoading={isLoadingUnfollow}
            onClick={handleUnfollowOrganization}
            customSpinnerHeight="w-4"
            customSpinnerWidth="h-4"
          />
        ) : (
          <Button
            className="h-6 w-[120px] rounded-full bg-dark-light-gray px-6 py-1 text-xs font-semibold"
            text="Seguir"
            enabled={!isLoadingFollow}
            isLoading={isLoadingFollow}
            onClick={handleFollowOrganization}
            customSpinnerHeight="w-4"
            customSpinnerWidth="h-4"
          />
        )}
      </div>
    </div>
  )
}
