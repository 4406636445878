import useSWR from 'swr'

import fetcher from '@/services/axios'

import { type INotification } from '@/types/hooks/api/useNotifications'

export const useGetNotificationHistory = (): {
  notificationHistoryData: INotification[] | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, isLoading, mutate } = useSWR<INotification[], string>(
    `/users/notifications/history`,
    fetcher,
  )

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    notificationHistoryData: data,
    error,
    isLoading,
    refresh,
  }
}
