import { api } from '@/services/axios'
import { useState } from 'react'
import { type AxiosError } from 'axios'

import { type IVerifyReturn } from '@/types/hooks/api/useSignup'

export const usePostVerifyCPF = (): {
  verifyCPF: (cpf: string) => Promise<IVerifyReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function verifyCPF(cpf: string): Promise<IVerifyReturn> {
    setIsLoading(true)

    const reqBody = {
      cpf,
    }

    try {
      await api.post('/users/cpf/availability', reqBody)
      setIsLoading(false)
      return {
        status: 200,
        isAvailable: true,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
        isAvailable: false,
      }
    }
  }

  return { verifyCPF, isLoading }
}
