import { api } from '@/services/axios'
import { useState } from 'react'
import { type AxiosError } from 'axios'

interface LogoutReturn {
  status: number
}

export const useLogout = (): {
  logout: () => Promise<LogoutReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function logout(): Promise<LogoutReturn> {
    setIsLoading(true)

    try {
      await api.post(`/auth/logout`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { logout, isLoading }
}
