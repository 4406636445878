import { type ReactElement } from 'react'
import { AnimatePresence } from 'framer-motion'

import { SnackBar } from '@/components/Global/SnackBar'

import { useSnackbarStore } from '@/store/snackbar'

interface MobileProps {
  children: ReactElement
}

export function MobileWrapper({ children }: MobileProps): ReactElement {
  const { snack, displayMode } = useSnackbarStore()

  return (
    <div className="size-full grow">
      {children}
      <AnimatePresence>
        {snack !== null && displayMode === 'ROOT' && (
          <SnackBar snack={snack} isMobile={true}></SnackBar>
        )}
      </AnimatePresence>
    </div>
  )
}
