import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosResponse } from 'axios'

import { type SectionData } from '@/types/hooks/api/useHomeEvents'

export const useGetRefund = (): {
  refund: (refundId: number) => Promise<{ status: number }>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function refund(refundId: number): Promise<{ status: number }> {
    setIsLoading(true)
    // setTimeout(setIsLoading, 2000, false)
    // return
    try {
      await api.get<SectionData[]>(`/payments/${refundId}/refund`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosResponse<string>
      setIsLoading(false)
      return {
        status: error.status ?? 500,
      }
    }
  }

  return { refund, isLoading }
}
