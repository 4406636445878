import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { EventImage } from '@/components/Event/EventImage'
import { EventBrief } from '@/components/Event/EventBrief'
import { OnFire } from '@/components/Global/OnFire'

interface MainEventCardMobileProps {
  eventAlias?: string
  imageKey: string | undefined
  titleImage?: string | undefined
  title?: string | undefined
  date: string | undefined
  location: string | undefined
  tags: string[] | undefined
  onFire?: boolean
}

export function MainEventCardMobile({
  eventAlias,
  imageKey,
  titleImage,
  title,
  date,
  location,
  tags,
  onFire = false,
}: MainEventCardMobileProps): ReactElement {
  return (
    <Link
      to={`/events/${eventAlias ?? ''}`}
      className="mx-4 overflow-hidden rounded-lg border-2 border-secondary-gray bg-dark-black p-2"
    >
      <div className="relative">
        <EventImage imageKey={imageKey} expanded rounded />
        {onFire && (
          <div className="absolute right-4 top-4 z-20">
            <OnFire variant="withText" />
          </div>
        )}
      </div>
      <div className="mt-2">
        <EventBrief
          titleImage={titleImage}
          title={title}
          date={date}
          location={location}
          highlighted
          padding=""
          tags={tags}
          variant="white"
          size="small"
        />
      </div>
    </Link>
  )
}
