import { type ReactElement } from 'react'

interface PixPaymentInstructionsProps {
  scanAvailable?: boolean
}

export function PixPaymentInstructions({
  scanAvailable = false,
}: PixPaymentInstructionsProps): ReactElement {
  const text1 = scanAvailable
    ? 'Abra o aplicativo do seu banco pelo celular ou copie o código'
    : 'Copie o código Pix e acesse o app do banco que usará para realizar o pagamento'
  const text2 = scanAvailable
    ? 'Procure por "Pix copia e cola" ou "Escanear QR Code"'
    : 'Na área do Pix do seu banco, procure ”Pix copia e cola”'
  const text3 = scanAvailable
    ? 'Cole o código ou escaneie o código ao lado e faça o pagamento'
    : 'Cole o código Pix e faça o pagamento'

  return (
    <div className="flex flex-col gap-6">
      <div className="flex h-10 items-center justify-between gap-2">
        <div>
          <span className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray font-black text-white">
            1
          </span>
        </div>
        <span className="w-full text-sm text-white">{text1}</span>
      </div>

      <div className="flex h-10 w-full items-center justify-between gap-2">
        <div>
          <span className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray font-black text-white">
            2
          </span>
        </div>
        <span className="w-full text-sm text-white">{text2}</span>
      </div>

      <div className="flex h-10 w-full items-center justify-between gap-2">
        <div>
          <span className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray font-black text-white">
            3
          </span>
        </div>
        <span className="w-full text-sm text-white">{text3}</span>
      </div>
    </div>
  )
}
