import { type ReactElement } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'

import { datesToFormat } from '@/helpers/formatDate'

import { TicketCounter } from '@/components/Global/TicketCounter'

import { useEventStore } from '@/store/event'

import { EventBase } from '@/compositions/EventBase'

import { type IEventData } from '@/types/hooks/api/useEvent'

import { isTruthy } from '@/helpers/validation'

interface MobileEventLayoutProps {
  eventData?: IEventData
}

export function MobileEventLayout({
  eventData,
}: MobileEventLayoutProps): ReactElement {
  const navigate = useNavigate()
  const location = useLocation()

  const doesAnyHistoryEntryExist = location.key !== 'default'

  const referrer = document.referrer

  const { ticketQuantity } = useEventStore()

  return (
    <div className="min-h-full bg-background-main">
      <div className="flex w-full flex-col gap-2 bg-background-main p-4 pb-0">
        <EventBase.Image
          imageKey={eventData?.imageKey ?? ''}
          onClick={() => {
            // TEMPORARY INIT
            if (referrer === 'https://sal.gandaya.dance/') {
              navigate(-1)
              return
            }
            // TEMPORARY END
            if (doesAnyHistoryEntryExist) navigate(-1)
            else navigate(`/`)
          }}
          imgClassName="rounded-lg "
          onFire={eventData?.onFire}
          closeFriends={eventData?.eventType === 'CLOSE_FRIENDS'}
          share
          back
          eventName={eventData?.title ?? ''}
        />
        <div className="z-10 flex w-full items-center justify-between">
          <EventBase.Brief
            title={eventData?.title}
            date={datesToFormat(
              eventData?.date.startDate ?? '',
              eventData?.date.endDate ?? '',
            )}
            location={
              isTruthy(eventData?.location?.name)
                ? eventData?.location?.name
                : 'Local a definir'
            }
            titleImage={eventData?.titleImageKey}
            highlighted
            background="bg-background-main"
            variant="white"
            size="medium"
            allowMultilineTitle
          />

          {ticketQuantity > 0 && (
            <TicketCounter value={ticketQuantity} direction={1} />
          )}
        </div>
      </div>
      <Outlet />
    </div>
  )
}
