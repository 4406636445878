import { useEffect, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { useNavbarStore } from '@/store/navbar'
import { useGlobalStore } from '@/store/global'

export function PageNotFound(): ReactElement {
  const navigate = useNavigate()

  const { hideNavbar, showNavbar } = useNavbarStore()
  const { isMobile } = useGlobalStore()

  useEffect(() => {
    hideNavbar()
  }, [])

  if (isMobile)
    return (
      <div className="absolute left-0 top-0 z-50 flex size-full flex-col items-center justify-center bg-dark-black p-8">
        <h1 className="mb-8 text-center text-3xl text-white">
          Não encontramos essa página 🙁
        </h1>
        <button
          className="fixed bottom-8 w-4/5 rounded-full bg-primary-main px-8 py-4 font-bold"
          onClick={() => {
            showNavbar()
            navigate('/')
          }}
        >
          Ir para a página inicial
        </button>
      </div>
    )
  return (
    <div className="absolute left-1/2 top-1/2 flex w-[400px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center pb-32">
      <span className="text-center text-2xl font-bold text-white">
        Não encontramos essa página 🙁
      </span>
    </div>
  )
}
