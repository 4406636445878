import { useEffect, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { BsCheckLg } from 'react-icons/bs'
import { IoQrCodeSharp } from 'react-icons/io5'
import { useUserStore } from '@/store/user'

export function EventCheckoutSuccessDesktop(): ReactElement {
  const navigate = useNavigate()

  const { username } = useUserStore()

  useEffect(() => {
    // Lock the scroll
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = ''
    }
  }, [])

  return (
    <div className="relative flex size-[500px] rounded-lg bg-background-main px-4">
      <div className="absolute left-0 top-0 flex size-full flex-col gap-4 rounded-md bg-background-main p-4">
        <AnimatePresence mode="wait">
          <motion.div
            className="flex size-full h-[100px] flex-col items-center gap-12 p-8 text-white"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.4, ease: 'easeInOut' }}
            key="payment-successfull"
          >
            <div className="flex flex-col items-center">
              <BsCheckLg
                className="size-14 rounded-full bg-primary-main p-3"
                color="#181818"
              />
              <h1 className="mt-2 text-2xl">Compra realizada</h1>
            </div>

            <div className="flex w-[90%] flex-col items-center">
              <div className="rounded-full bg-dark-black p-8">
                <IoQrCodeSharp color="white" size={80} />
              </div>
              <span className="mt-4 text-center text-sm leading-4">
                Para sua segurança, o acesso ao evento é feito com o seu
                <strong> identificador</strong> - um QR-Code único.
              </span>
            </div>

            <button
              className="w-full rounded-full bg-primary-main p-3 font-bold text-black hover:cursor-pointer"
              onClick={() => {
                navigate(`/memories/${username}`)
              }}
            >
              Continuar
            </button>
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  )
}
