import { type ReactElement } from 'react'
import { tv } from 'tailwind-variants'

import onFire from '@/assets/onFire.svg'

interface OnFireProps {
  variant: 'simple' | 'simpleWithBorder' | 'withText'
}

export function OnFire({ variant }: OnFireProps): ReactElement {
  const containerVariant = tv({
    base: 'flex items-center gap-2',
    variants: {
      background: {
        simple: 'aspect-square rounded-full p-2',
        simpleWithBorder:
          'backdrop-blur-sm p-2 bg-black/25 border border-primary-main rounded-full justify-center',
        withText:
          'backdrop-blur-sm bg-black/25 border-2 border-primary-main rounded-xl py-1 px-2',
      },
      size: {
        simple: 'h-8 aspect-square',
        simpleWithBorder: 'h-8 aspect-square',
        withText: 'size-fit',
      },
    },
  })

  const imageVariant = tv({
    base: 'aspect-square',
    variants: {
      size: {
        simple: 'h-5',
        simpleWithBorder: 'h-5',
        withText: 'h-5',
      },
    },
  })

  return (
    <div className={containerVariant({ background: variant, size: variant })}>
      <img src={onFire} alt="" className={imageVariant({ size: variant })} />
      {variant === 'withText' && (
        <span className="text-base font-black text-primary-main">ON FIRE</span>
      )}
    </div>
  )
}
