import { useState, type ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { SearchBar } from '@/components/Global/SearchBar'
import { Avatar } from '@/components/Global/Avatar'
import { EventCard } from '@/components/Global/EventCard'

import { useGetGenericSearch } from '@/hooks/api/Search'

import { useNavbarStore } from '@/store/navbar'
import { useSnackbarStore } from '@/store/snackbar'
import { useGlobalStore } from '@/store/global'

import { type IGenericEvent } from '@/types/hooks/api/useSearch'
import { type IGenericUser } from '@/types/global/user'

import { SEARCH_ERROR } from '@/errors'

export function Search(): ReactElement {
  const [searchParam, setSearchParam] = useState('')
  const [users, setUsers] = useState<IGenericUser[]>([])
  const [events, setEvents] = useState<IGenericEvent[]>([])
  const [didSearch, setDidSearch] = useState(false)

  const navigate = useNavigate()

  const { search, isLoading } = useGetGenericSearch()

  const { showNavbar } = useNavbarStore()
  const { showErrorSnack } = useSnackbarStore()
  const { isMobile } = useGlobalStore()

  async function handleSearch(): Promise<void> {
    const response = await search(searchParam)
    if (response.status === 200) {
      setUsers(response?.data?.users ?? [])
      setEvents(response?.data?.events ?? [])
      setDidSearch(true)
    } else {
      showErrorSnack(SEARCH_ERROR)
    }
  }

  useEffect(() => {
    showNavbar()
  }, [])

  useEffect(() => {
    if (!isMobile) navigate('/', { replace: true })
  }, [isMobile])

  if (isMobile)
    return (
      <div className="flex min-h-full flex-col gap-2 bg-background-main py-4">
        <h1 className="px-4 text-2xl text-white">Pesquisar</h1>
        <div className="px-4">
          <SearchBar
            searchParam={searchParam}
            setSearchParam={setSearchParam}
            placeholder="Pessoas e eventos"
            isSearching={isLoading}
            handleSearch={() => {
              void handleSearch()
            }}
          />
        </div>
        {users.length === 0 && events.length === 0 && didSearch && (
          <div className="mt-8 flex w-full flex-col">
            <span className="text-center text-white">
              Nenhum resultado encontrado
            </span>
          </div>
        )}
        {users.length > 0 && (
          <div className="mt-4 flex flex-col gap-2 ">
            <h1 className="px-4 text-lg text-white">Usuários</h1>
            <div className="w-full overflow-x-scroll px-4">
              <div className="flex w-max gap-2">
                {users?.map((user) => {
                  return (
                    <Avatar
                      key={user.id}
                      avatarImageKey={user.imageKey}
                      firstName={user.firstName}
                      lastName={user.lastName}
                      onClick={() => {
                        navigate(`/memories/${user.username}`)
                      }}
                    />
                  )
                })}
              </div>
            </div>
          </div>
        )}
        {events.length > 0 && (
          <div className="mt-4 flex w-full flex-col">
            <h1 className="mb-2 px-4 text-lg text-white">Eventos</h1>
            <div className="mb-12 flex flex-col gap-2 px-4">
              {events
                ?.sort((eventA, eventB) => {
                  const dateA = new Date(eventA.date)
                  const dateB = new Date(eventB.date)
                  return dateA < dateB ? 1 : -1
                })
                .map((event) => {
                  return (
                    <EventCard
                      key={event.alias}
                      imageUrl={event.imageKey}
                      title={event.title}
                      startDate={event.date}
                      endDate={event.endDate}
                      location={event.location ?? 'Local a definir'}
                      variation="small"
                      alias={event.alias}
                    />
                  )
                })}
            </div>
          </div>
        )}
      </div>
    )
  else return <></>
}
