import { EventTime } from '@/components/Event/EventTime'
import { EventLocation } from '@/components/Event/EventLocation'
import { EventTerms } from '@/components/Event/EventTerms'
import { EventFaq } from '@/components/Event/EventFaq'

export const EventInfo = {
  Time: EventTime,
  Location: EventLocation,
  Terms: EventTerms,
  Faq: EventFaq,
}
