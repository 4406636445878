import { type ReactElement } from 'react'

interface EventPlaylisProps {
  spotify: string | undefined
}

export function EventPlaylist({ spotify }: EventPlaylisProps): ReactElement {
  return (
    <div className="mt-1 w-full">
      <iframe
        // eslint-disable-next-line
        style={{borderRadius: '12px'}}
        // src={`${import.meta.env.VITE_SPOTIFY as string}/${spotify ?? ''}`}
        src={(import.meta.env.VITE_SPOTIFY as string) + (spotify ?? '')}
        width="100%"
        height="152"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        loading="lazy"
      ></iframe>
    </div>
  )
}
