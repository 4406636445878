import { type ReactElement } from 'react'
import { motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

interface LinkToggleProps {
  setSelected: (selected: 'analytics' | 'users') => void
  selected: 'analytics' | 'users'
}

export function LinkToggle({
  selected,
  setSelected,
}: LinkToggleProps): ReactElement {
  return (
    <div className="relative flex h-8 w-fit items-center self-center rounded-full border border-[#D9D9D9]">
      <motion.div
        className="absolute h-full w-24 rounded-full border-2 border-background-main bg-dark-dark-gray"
        initial={{ left: 0 }}
        animate={{ left: selected === 'users' ? '50%' : 0 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        key="toggle-bg"
      />
      <button
        onClick={() => {
          setSelected('analytics')
        }}
        className={twMerge(
          'z-10 flex w-24 items-center justify-center text-center text-sm text-white transition-all duration-200 ease-in-out',
          selected === 'analytics' && 'font-bold',
        )}
      >
        Analíticos
      </button>

      <motion.button
        onClick={() => {
          setSelected('users')
        }}
        className={twMerge(
          'z-10 flex w-24 items-center justify-center text-center text-sm text-white transition-all duration-200 ease-in-out',
          selected === 'users' && 'font-bold',
        )}
      >
        Usuários
      </motion.button>
    </div>
  )
}
