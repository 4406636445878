import { type ReactElement } from 'react'

import { type Snack } from '@/types/snackbar'

import { DesktopSnackbar } from './DesktopSnackbar'
import { MobileSnackbar } from './MobileSnackbar'

interface SnackBarProps {
  snack: Snack
  isMobile: boolean
}

export function SnackBar({ snack, isMobile }: SnackBarProps): ReactElement {
  if (isMobile) return <MobileSnackbar snack={snack} />
  return <DesktopSnackbar snack={snack} />
}
