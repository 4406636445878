import { useState, useEffect, type ReactElement } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { AuthLink } from '@/components/Global/AuthLink'

import { GoHomeFill, GoSearch } from 'react-icons/go'
import { IoNotificationsOutline, IoQrCodeSharp } from 'react-icons/io5'
import { BsPersonCircle } from 'react-icons/bs'

import { useNavbarStore } from '@/store/navbar'
import { useUserStore } from '@/store/user'

import { isTruthy } from '@/helpers/validation'

type NavItem =
  | 'home'
  | 'search'
  | 'wallet'
  | 'memories'
  | 'notifications'
  | 'none'

const navItems: NavItem[] = [
  'home',
  'search',
  'wallet',
  'memories',
  'notifications',
]

export function NavBar(): ReactElement {
  const location = useLocation()

  const { username } = useParams()

  const { navbarIsShown } = useNavbarStore()
  const { imageKey, username: usernameStore, followRequests } = useUserStore()

  const [active, setActive] = useState<Record<NavItem, boolean>>({
    home: false,
    search: false,
    wallet: false,
    notifications: false,
    memories: false,
    none: false,
  })

  function switchActive(name: NavItem): void {
    const newActive: Record<NavItem, boolean> = {
      home: false,
      search: false,
      wallet: false,
      notifications: false,
      memories: false,
      none: false,
    }

    newActive[name] = true

    setActive(newActive)
  }

  useEffect(() => {
    const current = location.pathname

    if (current === '/' || current === '') {
      switchActive('home')
      return
    }

    const path = current.slice(1)
    const pathZero = path.split('/')[0]
    const currentNavItem = navItems.find((item) => pathZero === item)
    switchActive(currentNavItem ?? 'none')
  }, [location, navbarIsShown])

  return (
    <>
      <AnimatePresence>
        {navbarIsShown && (
          <motion.div
            key="navbar"
            className="fixed -bottom-0.5 z-20 flex h-12 w-full items-center justify-between bg-dark-black p-2"
            initial={{ y: '100%' }}
            animate={{ y: 0 }}
            exit={{ y: '100%' }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <Link to="/" className="flex w-full items-center justify-center">
              <GoHomeFill
                size={24}
                color={active.home ? '#D5FF5C' : '#fff'}
                className="transition duration-200 hover:cursor-pointer "
              />
            </Link>
            <Link
              to="/search"
              className="flex w-full items-center justify-center"
            >
              <GoSearch
                size={24}
                color={active.search ? '#D5FF5C' : '#fff'}
                className="transition duration-200 hover:cursor-pointer "
              />
            </Link>
            <AuthLink
              to="/wallet"
              className="flex w-full items-center justify-center"
            >
              <IoQrCodeSharp
                size={24}
                color={active.wallet ? '#D5FF5C' : '#fff'}
                className="transition duration-200 hover:cursor-pointer "
              />
            </AuthLink>
            <Link
              to="/notifications"
              className="flex w-full items-center justify-center"
            >
              <div className="relative">
                <IoNotificationsOutline
                  size={24}
                  color={active.notifications ? '#D5FF5C' : '#fff'}
                  className="transition duration-200 hover:cursor-pointer "
                />
                {followRequests > 0 && (
                  <div
                    className={twMerge(
                      'absolute flex aspect-square -translate-y-8 translate-x-3 items-center justify-center rounded-full bg-tonal-red',
                      followRequests > 10 ? 'h-5' : 'h-4',
                    )}
                  >
                    <span className="text-xs font-bold text-white">
                      {followRequests}
                    </span>
                  </div>
                )}
              </div>
            </Link>
            <AuthLink
              to={`/memories/${usernameStore}`}
              className="flex w-full items-center justify-center"
            >
              {isTruthy(imageKey) ? (
                <img
                  src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
                  alt="Profile"
                  className={
                    'h-8 w-8 rounded-full border ' +
                    (active.memories && username === usernameStore
                      ? 'border-primary-main'
                      : 'border-white')
                  }
                />
              ) : (
                <BsPersonCircle
                  size={24}
                  color={
                    active.memories && username === usernameStore
                      ? '#D5FF5C'
                      : '#fff'
                  }
                  className="transition duration-200 hover:cursor-pointer "
                />
              )}
            </AuthLink>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  )
}
