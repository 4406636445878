import { useState } from 'react'
import { type AxiosError } from 'axios'

import { api } from '@/services/axios'

import {
  type IFollowRequest,
  type IFollowRequestReturn,
} from '@/types/hooks/api/useNotifications'

export const useGetFollowRequests = (): {
  getFollowRequests: () => Promise<IFollowRequestReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getFollowRequests(): Promise<IFollowRequestReturn> {
    setIsLoading(true)

    try {
      const response = await api.get<IFollowRequest[]>(
        `/social/follow/requests`,
      )
      setIsLoading(false)
      return {
        status: 200,
        followRequests: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    getFollowRequests,
    isLoading,
  }
}
