import { Modal } from '@/components/Global/Modal'
import { useGetFollowers } from '@/hooks/api/Social'
import { type ReactElement } from 'react'
import { UserSocialCard } from '../UserSocialCard'
import { Spinner } from '@/components/Global/Spinner'

interface ShowFollowersModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  userId: number
  isAuthUser: boolean
}

export function ShowFollowersModal({
  closeModal,
  isParentClosing,
  userId,
  isAuthUser,
}: ShowFollowersModalProps): ReactElement {
  const { followers, isLoading, refresh } = useGetFollowers(userId)

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variation="dark"
    >
      <div className="mt-6 flex w-full flex-col gap-4 px-4 desktop:mt-0 desktop:p-4 desktop:pb-0">
        <h1 className="font-matter text-2xl font-bold text-dark-light-gray">
          Seguidores
        </h1>

        {isLoading || followers === undefined ? (
          <div className="flex w-full items-center justify-center">
            <Spinner
              borderWidth="border-4"
              borderColor="border-[#232323]/50"
              bottomBorderColor="border-b-[#232323]"
            />
          </div>
        ) : (
          <div className="relative">
            <div className="pointer-events-none absolute top-0 z-10 h-4 w-full bg-gradient-to-t from-transparent to-background-main" />
            <div className="flex max-h-[432px] w-full flex-col overflow-y-scroll py-4">
              {followers.map((follower) => (
                <UserSocialCard
                  key={follower.userId}
                  user={follower}
                  isAuthUser={isAuthUser}
                  mode="FOLLOWER"
                  closeModal={closeModal}
                  refreshData={() => {
                    void refresh()
                  }}
                />
              ))}
              {followers.length === 0 && (
                <div className="flex w-full items-center justify-center">
                  <span className="text-center text-sm text-text-secondary">
                    Nenhum seguidor encontrado
                  </span>
                </div>
              )}
            </div>
            <div className="pointer-events-none absolute bottom-0 z-10 h-4 w-full bg-gradient-to-b from-transparent to-background-main" />
          </div>
        )}
      </div>
    </Modal>
  )
}
