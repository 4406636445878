import { useState } from 'react'
import { type AxiosError } from 'axios'
import { api } from '@/services/axios'

import {
  type IUpdateUserData,
  type IUpdateProfileReturn,
} from '@/types/hooks/api/useProfile'

export const usePutEditProfile = (): {
  isLoading: boolean
  putEditProfile: (
    id: number,
    user: IUpdateUserData,
  ) => Promise<IUpdateProfileReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function putEditProfile(
    id: number,
    user: IUpdateUserData,
  ): Promise<IUpdateProfileReturn> {
    setIsLoading(true)

    try {
      const response = await api.put<IUpdateUserData>(`/users/${id}`, {
        ...user,
      })
      setIsLoading(false)
      return {
        status: 200,
        userData: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { putEditProfile, isLoading }
}
