import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface CircularProgressProps {
  size: number
  value?: number
  percentValue: number
  bgProgressColor?: string
  progressColor?: string
  strokeWidth?: number
  valueClassName?: string
}

export function CircularProgress({
  size,
  percentValue,
  bgProgressColor,
  progressColor,
  strokeWidth = 2,
  value,
  valueClassName,
}: CircularProgressProps): ReactElement {
  const radius = (size - strokeWidth) / 2
  const circumference = radius * 2 * Math.PI

  const progress = percentValue * 2 * Math.PI

  return (
    <div className="relative">
      <svg
        width={size}
        height={size}
        style={{
          transform: 'rotate(270deg)',
        }}
      >
        <circle
          stroke={bgProgressColor}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <circle
          stroke={progressColor}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDashoffset={progress * radius}
          strokeDasharray={`${circumference} ${circumference}`}
          strokeWidth={strokeWidth}
          style={{
            transition: 'stroke-dashoffset 1s linear',
          }}
        />
      </svg>
      <span
        className={twMerge(
          'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
          valueClassName,
        )}
      >
        {value}
      </span>
    </div>
  )
}
