import { MainEventCardMobile } from '@/components/Home/MainEventCard/MainEventCardMobile'
import { MainEventCardDesktop } from '@/components/Home/MainEventCard/MainEvenCardDesktop'
import { EventCard } from '@/components/Global/EventCard'
import { Carousel } from '@/components/Home/Carousel'
import { Section } from '@/components/Home/Section'

export const HomeBase = {
  MainMobile: MainEventCardMobile,
  MainDesktop: MainEventCardDesktop,
  Card: EventCard,
  Carousel,
  Section,
}
