import { type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { EventImage } from '@/components/Event/EventImage'
import { EventBrief } from '@/components/Event/EventBrief'

interface EventThumbnailProps {
  imageKey: string
  title?: string
  titleImageKey?: string
  date: string
  location: string
  highlighted?: boolean
  imageVariant?: 'small' | 'medium' | 'large'
  briefVariant?: 'dark' | 'light' | 'white'
  eventAlias?: string
}

export function EventThumbnail({
  imageKey,
  title,
  titleImageKey,
  date,
  location,
  highlighted = false,
  imageVariant = 'small',
  briefVariant,
  eventAlias,
}: EventThumbnailProps): ReactElement {
  const imageSize = {
    small: 'h-20 aspect-square',
    medium: 'h-24 aspect-square',
    large: 'h-28 aspect-square',
  }

  const navigate = useNavigate()

  return (
    <div className="flex gap-4">
      <div className={imageSize[imageVariant]}>
        <EventImage imageKey={imageKey} rounded />
      </div>
      <div className="flex flex-col justify-evenly">
        <EventBrief
          title={title}
          titleImage={titleImageKey}
          date={date}
          location={location}
          highlighted={highlighted}
          variant={briefVariant}
          size="medium"
        />
        {eventAlias !== undefined && (
          <button
            className="flex w-min items-center gap-2 whitespace-nowrap rounded-lg bg-dark-light-gray px-2 py-1 text-xs font-bold"
            onClick={() => {
              navigate(`/events/${eventAlias ?? ''}`)
            }}
          >
            Ver evento
          </button>
        )}
      </div>
    </div>
  )
}
