import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

import {
  type IWalletTickets,
  type IWalletEvent,
} from '@/types/hooks/api/useWallet'
import { type IGenericUser } from '@/types/global/user'

interface Ticket extends IWalletTickets {
  isSelected: boolean
}

interface UseTicketProps {
  transferState:
    | 'selectUser'
    | 'selectTicket'
    | 'confirmTransfer'
    | 'warningTicketIsAnnounced'
  transferModalState:
    | 'preTransfer'
    | 'isTransfering'
    | 'posTransferSuccess'
    | 'posTransferError'
  transferTickets: Ticket[]
  transferTicketsEvent: IWalletEvent
  receiver: IGenericUser
  setTransferState: (
    transferState:
      | 'selectUser'
      | 'selectTicket'
      | 'confirmTransfer'
      | 'warningTicketIsAnnounced',
  ) => void
  setTransferModalState: (
    transferModalState:
      | 'preTransfer'
      | 'isTransfering'
      | 'posTransferSuccess'
      | 'posTransferError',
  ) => void
  setTransferTickets: (transferTickets: Ticket[]) => void
  setTransferTicketsEvent: (transferTicketsEvent: IWalletEvent) => void
  setReceiver: (receiver: IGenericUser) => void
  resetReceiver: () => void
  switchTicketSelectState: (ticketSpecId: number) => void
  resetTransferStore: () => void
}

export const useTransferStore = create<UseTicketProps>()(
  devtools(
    persist(
      (set) => ({
        transferState: 'selectUser',
        transferModalState: 'preTransfer',
        transferTickets: [],
        transferTicketsEvent: {
          id: 0,
          alias: '',
          imageKey: '',
          titleImageKey: '',
          title: '',
          startDate: '',
          endDate: '',
          location: '',
        },
        receiver: {
          id: 0,
          firstName: '',
          lastName: '',
          username: '',
          imageKey: '',
          email: '',
        },
        setTransferState: (
          transferState:
            | 'selectUser'
            | 'selectTicket'
            | 'confirmTransfer'
            | 'warningTicketIsAnnounced',
        ) => {
          set({ transferState })
        },
        setTransferModalState: (
          transferModalState:
            | 'preTransfer'
            | 'isTransfering'
            | 'posTransferSuccess'
            | 'posTransferError',
        ) => {
          set({ transferModalState })
        },
        setTransferTickets: (transferTickets: Ticket[]) => {
          set({
            transferTickets: transferTickets.map((ticket) => {
              return {
                ...ticket,
                isSelected: false,
              }
            }),
          })
        },
        setTransferTicketsEvent: (transferTicketsEvent: IWalletEvent) => {
          set({ transferTicketsEvent })
        },
        setReceiver: (receiver: IGenericUser) => {
          set({ receiver })
        },
        resetReceiver: () => {
          set(() => ({
            receiver: {
              id: 0,
              firstName: '',
              lastName: '',
              username: '',
              imageKey: '',
              email: '',
            },
          }))
        },
        switchTicketSelectState: (id: number) => {
          set((state) => ({
            transferTickets: state.transferTickets.map((ticket) =>
              ticket.id === id
                ? { ...ticket, isSelected: !ticket.isSelected }
                : ticket,
            ),
          }))
        },
        resetTransferStore: () => {
          set(() => ({
            transferState: 'selectUser',
            transferModalState: 'preTransfer',
            transferTickets: [],
            transferTicketsEvent: {
              id: 0,
              alias: '',
              imageKey: '',
              titleImageKey: '',
              title: '',
              startDate: '',
              endDate: '',
              location: '',
            },
            receiver: {
              id: 0,
              firstName: '',
              lastName: '',
              username: '',
              imageKey: '',
              email: '',
            },
          }))
        },
      }),
      {
        name: 'transfer-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
)
