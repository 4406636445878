import { create } from 'zustand'

interface NavbarProps {
  navbarIsShown: boolean
  hideNavbar: () => void
  showNavbar: () => void
}

export const useNavbarStore = create<NavbarProps>()((set) => ({
  navbarIsShown: true,
  hideNavbar: () => {
    set(() => ({ navbarIsShown: false }))
  },
  showNavbar: () => {
    set(() => ({ navbarIsShown: true }))
  },
}))
