import { z } from 'zod'

export function isEventFinished(endDate: string): boolean {
  const validatedEndDate = z.string().datetime().parse(endDate)
  const currentTime = new Date().getTime()
  const endDateTime = new Date(validatedEndDate).getTime()

  const timeDelta = currentTime - endDateTime
  const secondsSinceEndDate = Math.floor(timeDelta / 1000)
  return secondsSinceEndDate > 86400 // 86400 segundos são 24 horas!
}
