import { useState } from 'react'

import { api } from '@/services/axios'

import { type ITicketStore } from '@/types/hooks/api/useTickets'

export const usePostTicketsCheck = (
  eventId: number,
  tickets: ITicketStore[],
): {
  checkAvailability: () => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function checkAvailability(): Promise<boolean> {
    setIsLoading(true)

    const requestBody = {
      eventId,
      products: tickets
        .filter((ticket) => ticket.quantitySelected > 0)
        .map((ticket) => {
          return {
            ticketSpecId: ticket.ticketSpecId,
            quantity: ticket.quantitySelected,
          }
        }),
    }

    try {
      await api.post(`/tickets/check`, requestBody)
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      return false
    }
  }

  return { checkAvailability, isLoading }
}
