import { type ReactElement, forwardRef, type ForwardedRef } from 'react'
import { AnimatePresence } from 'framer-motion'

import { EventCallToAction } from '../EventCallToAction'
import { SnackBar } from '@/components/Global/SnackBar'

import { useSnackbarStore } from '@/store/snackbar'

interface EventFooterProps {
  title?: string
  value?: number | string | undefined
  subtitle?: string
  buttonText: string
  buttonEnabled: boolean
  buttonOnClick: () => void
  isLoading?: boolean
  children?: ReactElement
  error?: ReactElement
  information?: ReactElement
  ref?: ForwardedRef<HTMLDivElement>
  hideButton?: boolean
  eventFinished?: boolean
}

export const EventFooter = forwardRef(
  (
    {
      title,
      value,
      subtitle,
      buttonText,
      buttonEnabled,
      buttonOnClick,
      isLoading = false,
      children,
      error,
      information,
      hideButton = false,
      eventFinished = false,
    }: EventFooterProps,
    ref: ForwardedRef<HTMLDivElement>, // Declare the type for ref
  ): ReactElement => {
    const { snack, displayMode } = useSnackbarStore()

    return (
      <div className="fixed bottom-0 z-40 w-full" ref={ref}>
        <div className={'absolute -z-10 h-0 w-full'}>
          <AnimatePresence>
            {snack !== null && displayMode === 'FOOTER' && (
              <SnackBar snack={snack} isMobile={true} />
            )}
          </AnimatePresence>
        </div>
        {error}
        {information}
        {children}
        <div className="z-[41] bg-dark-black">
          <EventCallToAction
            title={title}
            value={value}
            subtitle={subtitle}
            buttonText={buttonText}
            buttonEnabled={buttonEnabled}
            buttonOnClick={buttonOnClick}
            isLoading={isLoading}
            hideButton={hideButton}
            eventFinished={eventFinished}
          />
        </div>
      </div>
    )
  },
)

EventFooter.displayName = 'Footer'
