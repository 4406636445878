import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import {
  type IProfilePictureResponse,
  type IProfilePictureReturn,
} from '@/types/hooks/api/useProfilePicture'

export const usePostNewProfilePicture = (): {
  isLoading: boolean
  postNewProfilePicture: (
    profilePicture: File,
  ) => Promise<IProfilePictureReturn>
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function postNewProfilePicture(
    profilePicture: File,
  ): Promise<IProfilePictureReturn> {
    setIsLoading(true)

    const formData = new FormData()
    formData.append('image', profilePicture)

    try {
      const response = await api.post<IProfilePictureResponse>(
        `/users/profile-picture`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      setIsLoading(false)
      return {
        status: 200,
        imageKey: response.data.imageKey,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    isLoading,
    postNewProfilePicture,
  }
}
