import { type ReactElement } from 'react'

import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api'

interface MiniMapProps {
  latitude: number
  longitude: number
}

export function MiniMap({ latitude, longitude }: MiniMapProps): ReactElement {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY as string,
  })

  const center = { lat: latitude, lng: longitude }
  const options = {
    mapId: '2d72fdfdf2bd5f84',
    disableDefaultUI: true,
    zoomControl: false,
    clickableIcons: false,
    mapTypeControl: false,
    panControl: false,
    rotateControl: false,
    scaleControl: false,
    streetViewControl: false,
    backgroundColor: '#232323',
  }

  return (
    <>
      {isLoaded && (
        <div className="flex h-[175px] w-full">
          {isLoaded && (
            <GoogleMap
              zoom={17}
              center={center}
              mapContainerStyle={{ width: '100%', height: '100%' }}
              options={options}
            >
              <Marker position={center} icon="../marker.svg" />
            </GoogleMap>
          )}
        </div>
      )}
    </>
  )
}
