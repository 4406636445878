import { api } from '@/services/axios'
import { type AxiosError } from 'axios'
import { useState } from 'react'

import { type IFollowRequestResponse } from '@/types/Social'

interface IReturn {
  status: number
  followRequestId?: number
}

export const usePostCreateFollowRequest = (): {
  isLoading: boolean
  postCreateFollowRequest: (receiverId: number) => Promise<IReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postCreateFollowRequest(receiverId: number): Promise<IReturn> {
    setIsLoading(true)

    try {
      const response = await api.post<IFollowRequestResponse>(
        `/social/follow/${receiverId}`,
      )
      setIsLoading(false)
      return {
        status: 200,
        followRequestId: response.data.id,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { postCreateFollowRequest, isLoading }
}
