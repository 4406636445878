import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'

import { BsCheckLg } from 'react-icons/bs'
import { IoQrCodeSharp } from 'react-icons/io5'

interface PaymentSuccessModalProps {
  onClick: () => void
  isParentClosing?: boolean
  title: string
}

export function CheckoutSuccessModal({
  onClick,
  isParentClosing,
  title,
}: PaymentSuccessModalProps): ReactElement {
  return (
    <Modal isParentClosing={isParentClosing}>
      <div className="flex size-full flex-col items-center gap-32 p-8">
        <div className="flex flex-col items-center">
          <BsCheckLg className="size-14 rounded-full bg-primary-main p-3" />
          <h1 className="mt-2 text-2xl">{title}</h1>
        </div>

        <div className="flex w-[90%] flex-col items-center">
          <div className="rounded-full bg-dark-black p-8">
            <IoQrCodeSharp color="white" size={80} />
          </div>
          <span className="mt-4 text-center text-sm leading-4">
            Para sua segurança, o acesso ao evento é feito com o seu
            <strong> identificador</strong> - um QR-Code único.
          </span>
        </div>

        <button
          className="w-full rounded-full bg-primary-main p-3 font-bold text-black hover:cursor-pointer"
          onClick={onClick}
        >
          Continuar
        </button>
      </div>
    </Modal>
  )
}
