import { type ReactElement } from 'react'

import { HomeMobile } from './HomeMobile'
import { HomeDesktop } from './HomeDesktop'

import { useGlobalStore } from '@/store/global'

export function Home(): ReactElement {
  const { isMobile } = useGlobalStore()

  if (isMobile) return <HomeMobile />
  return <HomeDesktop />
}
