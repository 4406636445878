import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type IRefundRequirements } from '@/types/EventPayment'

export const useGetRefundRequirements = (
  paymentId: number,
): {
  refundRequirements: IRefundRequirements | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const shouldFetch = paymentId !== undefined
  const { data, error } = useSWR<IRefundRequirements, string>(
    shouldFetch && `/payments/${paymentId}/refund/requirements`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const refundRequirements = data ?? undefined

  return { refundRequirements, error, isLoading }
}
