import { type ITicketStore } from '@/types/hooks/api/useTickets'

type ITicketStoreWithoutQuantitySelected = Omit<
  ITicketStore,
  'quantitySelected'
>

export function ticketsFilter(
  tickets: ITicketStore[],
): ITicketStoreWithoutQuantitySelected[] {
  return tickets.map((ticket) => {
    const { quantitySelected, ...ticketWithoutQuantitySelected } = ticket
    return ticketWithoutQuantitySelected
  })
}
