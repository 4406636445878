import { useRef, useState, type ReactElement } from 'react'
import { motion } from 'framer-motion'

import { TicketSummary } from '@/components/Global/TicketSummary'

import { SlArrowDown } from 'react-icons/sl'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import { type IWalletTicketGroup } from '@/types/hooks/api/useWallet'

interface EventHistoryTicketsCardProps {
  currentWalletEvent: IWalletTicketGroup
}

export function EventHistoryTicketsCard({
  currentWalletEvent,
}: EventHistoryTicketsCardProps): ReactElement {
  const [showTickets, setShowTickets] = useState<boolean>(false)

  const divRef = useRef<HTMLDivElement>(null)

  const total = currentWalletEvent.tickets.reduce((acc, ticket) => {
    return acc + ticket.price
  }, 0)

  return (
    <div className="flex flex-col gap-2 rounded-md bg-dark-black p-4">
      <span className="text-xs font-bold text-white">Ingressos</span>
      <div className="relative">
        <motion.div
          key="tickets"
          className="relative overflow-hidden"
          initial={{ maxHeight: `240px` }}
          animate={{
            maxHeight: showTickets
              ? `${divRef.current?.clientHeight ?? 0}px`
              : '240px',
          }}
          transition={{ duration: 0.5, ease: 'easeInOut' }}
        >
          <div className="relative flex w-full flex-col gap-2" ref={divRef}>
            {currentWalletEvent.tickets.map((ticket) => {
              const title = isTruthy(ticket.batchDescription)
                ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                : ticket.ticketSpecDescription
              return (
                <TicketSummary
                  key={ticket.id}
                  title={title}
                  subtitle={`${numberToReais(ticket.price, 2)}`}
                  quantity={1}
                  variant="light"
                  counterVariant="dark"
                />
              )
            })}
          </div>

          {currentWalletEvent.tickets.length > 4 && (
            <div
              className={
                'absolute bottom-0 z-10 h-10 w-full bg-gradient-to-b from-transparent to-dark-black transition-opacity duration-100 ease-linear ' +
                (showTickets ? 'opacity-0' : ' opacity-100')
              }
            />
          )}
        </motion.div>
        {currentWalletEvent.tickets.length > 4 && (
          <button
            onClick={() => {
              setShowTickets(!showTickets)
            }}
            className="absolute -bottom-4 right-1/2 z-20 flex translate-x-1/2 items-center justify-center"
          >
            <SlArrowDown
              color="#fff"
              size={20}
              className={
                'transition-transform duration-500 ease-in-out ' +
                (showTickets ? '-rotate-180' : '')
              }
            />
          </button>
        )}
      </div>
      <span className="mt-4 font-bold leading-3 text-white">
        Total: {numberToReais(total, 2)}
      </span>
    </div>
  )
}
