import { type ReactElement, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { SelectSellTickets } from '@/components/Sell/SelectSellTickets'
import { ShareSellLinkModal } from '@/components/Sell/ShareSellLinkModal'

import { BsArrowLeftShort } from 'react-icons/bs'

import { useSellStore } from '@/store/sell'

export function Sell(): ReactElement {
  const { sellState } = useSellStore()

  const mainDivRef = useRef<HTMLDivElement>(null)

  const navigate = useNavigate()

  function scrollToBottom(): void {
    mainDivRef?.current?.scrollTo({
      top: mainDivRef?.current?.scrollHeight,
      behavior: 'smooth',
    })
  }

  return (
    <div
      ref={mainDivRef}
      className="flex h-full flex-col overflow-y-scroll bg-background-main p-4"
    >
      <div className="flex gap-4">
        <button
          className="rounded-full bg-dark-white p-0.5"
          onClick={() => {
            navigate('/wallet')
          }}
        >
          <BsArrowLeftShort size={32} color="#232323" />
        </button>
        <h1 className="text-2xl text-white">Link para venda</h1>
      </div>

      <div className="mt-6 flex flex-col">
        <SelectSellTickets scrollToBottom={scrollToBottom} />
        {sellState === 'shareLink' && <ShareSellLinkModal />}
      </div>
    </div>
  )
}
