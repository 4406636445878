import fetcher from '@/services/axios'
import useSWR from 'swr'

import { type IUsers } from '@/types/hooks/api/useEventHistorySocial'

export const useGetEventHistorySocial = (
  alias: string,
): {
  users: IUsers | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const { data, error } = useSWR<IUsers, string>(
    `/social/${alias}/friends`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const users: IUsers | undefined = data ?? undefined

  return {
    users,
    error,
    isLoading,
  }
}
