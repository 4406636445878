import { type ReactElement } from 'react'
import { AnimatePresence } from 'framer-motion'

import { useSnackbarStore } from '@/store/snackbar'

import { SnackBar } from '@/components/Global/SnackBar'
import { Header } from '@/components/Global/Header'
import { Footer } from '@/components/Global/Footer'

interface DesktopWrapperProps {
  children: ReactElement
}

export function DesktopWrapper({
  children,
}: DesktopWrapperProps): ReactElement {
  const { snack } = useSnackbarStore()

  return (
    <div className="flex min-h-full w-full flex-col items-center">
      <Header />
      <div className="flex size-full max-w-[1200px] grow">{children}</div>
      <Footer />

      <AnimatePresence>
        {snack !== null && <SnackBar snack={snack} isMobile={false}></SnackBar>}
      </AnimatePresence>
    </div>
  )
}
