import { EventRoot } from './EventRoot'
import { EventImage } from '@/components/Event/EventImage'
import { EventBrief } from '@/components/Event/EventBrief'
import { EventReactions } from '@/components/Event/EventReactions'
import { EventDescription } from '@/components/Event/EventDescription'
import { EventLineUp } from '@/components/Event/EventLineUp'
import { EventPlaylist } from '@/components/Event/EventPlaylist'
import { EventPartners } from '@/components/Event/EventPartners'
import { EventBit } from '@/components/Event/EventBit'
import { EventOrganization } from '@/components/Event/EventOrganization'
import { EventFooter } from '@/components/Event/EventFooter'
import { AddPromo } from '@/components/Event/AddPromo'
import { EventCreateEvent } from '@/components/Event/EventCreateEvent'
import { EventHosts } from '@/components/Event/EventHosts'
import { EventInterest } from '@/components/Event/EventInterest'
import { EventInterestModal } from '@/components/Event/EventInterestModal'
import { EventCallToAction } from '@/components/Event/EventCallToAction'
import { EventThumbnail } from '@/components/Event/EventThumbnail'
import { EventRSVPs } from '@/components/Event/EventRSVPs'

import { EventInfo } from '../EventInfo'

export const EventBase = {
  Root: EventRoot,
  Image: EventImage,
  Brief: EventBrief,
  Reactions: EventReactions,
  Description: EventDescription,
  LineUp: EventLineUp,
  Playlist: EventPlaylist,
  Info: EventInfo,
  Partners: EventPartners,
  Organization: EventOrganization,
  Bit: EventBit,
  Footer: EventFooter,
  RSVPs: EventRSVPs,
  AddPromo,
  CreateEvent: EventCreateEvent,
  Hosts: EventHosts,
  Interest: EventInterest,
  InterestModal: EventInterestModal,
  CallToAction: EventCallToAction,
  Thumbnail: EventThumbnail,
}
