import { useState } from 'react'
import { type AxiosResponse, type AxiosError } from 'axios'

import { api } from '@/services/axios'

import {
  type IGenericSearchDataReturn,
  type IGenericSearchDataResponse,
} from '@/types/hooks/api/useSearch'

export const useGetGenericSearch = (): {
  search: (searchParam: string) => Promise<IGenericSearchDataReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function search(
    searchParam: string,
  ): Promise<IGenericSearchDataReturn> {
    setIsLoading(true)

    try {
      const response: AxiosResponse<IGenericSearchDataResponse> = await api.get(
        `/search?term=${searchParam}&skip=0&take=10`,
      )
      setIsLoading(false)
      return {
        status: 200,
        data: response.data,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { search, isLoading }
}
