import { parseISO, format, differenceInDays, isSameYear } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { capitalizeString } from './formatString'
import { isTruthy } from './validation'

export function dateToFormat(date: string | undefined): string {
  if (date === undefined || date === '') return ''

  const dateObj = parseISO(date)

  const dayOfWeek = capitalizeString(format(dateObj, 'EEE', { locale: ptBR }))
  const dayOfMonth = capitalizeString(format(dateObj, 'dd', { locale: ptBR }))
  const monthFullName = format(dateObj, 'MMMM', { locale: ptBR })

  const formattedDate = `${dayOfWeek.slice(
    0,
    3,
  )}, ${dayOfMonth} de ${monthFullName}`

  return formattedDate
}

export function dateToHour(date: string | undefined): string {
  if (date === undefined || date === null) return ''

  const dateObj = parseISO(date)
  const hour = format(dateObj, 'HH:mm', { locale: ptBR })
  return hour
}

export function dateToMonthYear(date: string | undefined): string {
  if (date === undefined || date === null) return ''

  const dateObj = parseISO(date)
  const month = capitalizeString(format(dateObj, 'MMMM', { locale: ptBR }))
  const year = format(dateObj, 'yyyy', { locale: ptBR })

  return `${month} de ${year}`
}

export function dateToFormatNoWeekDay(date: string | undefined): string {
  if (date === undefined || date === '') return ''

  const dateObj = parseISO(date)

  const dayOfMonth = capitalizeString(format(dateObj, 'dd', { locale: ptBR }))
  const monthFullName = format(dateObj, 'MMMM', { locale: ptBR })

  const year = format(dateObj, 'yyyy', { locale: ptBR })

  const formattedDate = `${dayOfMonth} de ${monthFullName} ${year} `

  return formattedDate
}

export function datesToFormat(date1: string, date2: string): string {
  if (!isTruthy(date1) || !isTruthy(date2)) return ''

  const d1 = parseISO(date1)
  const d2 = parseISO(date2)

  const diffDays = Math.abs(differenceInDays(d1, d2))

  if (diffDays <= 1) {
    const dayOfWeek = capitalizeString(format(d1, 'EEE', { locale: ptBR }))
    const dayOfMonth = capitalizeString(format(d1, 'dd', { locale: ptBR }))
    const monthFullName = format(d1, 'MMMM', { locale: ptBR })

    const formattedDate = `${dayOfWeek.slice(
      0,
      3,
    )}, ${dayOfMonth} de ${monthFullName}`
    return formattedDate
  } else if (isSameYear(d1, d2)) {
    return `${format(d1, 'dd/MM')} -> ${format(d2, 'dd/MM')}`
  } else {
    return `${format(d1, 'dd/MM/yyyy')} -> ${format(d2, 'dd/MM/yyyy')}`
  }
}

function formatDate(date: Date, formatString: string): string {
  return format(date, formatString, { locale: ptBR })
}

export function generateEventTime(
  startDate: string,
  endDate: string,
  maxEntranceDate?: string,
): {
  formattedStartDate: string
  formattedMaxEntranceDate: string | undefined
  formattedEndDate: string
} {
  const d1 = parseISO(startDate)
  const d2 = parseISO(endDate)
  const d3 = isTruthy(maxEntranceDate) ? parseISO(maxEntranceDate!) : undefined

  const diffDays = Math.abs(differenceInDays(d1, d2))

  let formatString: string
  if (diffDays < 2) {
    formatString = "EEE, dd 'de' MMMM, 'às' HH:mm"
  } else if (isSameYear(d1, d2)) {
    formatString = "dd/MM 'às' HH:mm"
  } else {
    formatString = "dd/MM/yyyy 'às' HH:mm"
  }

  const formattedStartDate = formatDate(d1, formatString)
  const formattedEndDate = formatDate(d2, formatString)
  const formattedMaxEntranceDate =
    d3 !== undefined ? formatDate(d3, formatString) : ''

  return {
    formattedStartDate,
    formattedMaxEntranceDate:
      d3 === undefined ? undefined : formattedMaxEntranceDate,
    formattedEndDate,
  }
}

export function formatDateRelativeToToday(date: string): string {
  const [year, month, day] = date.split('-').map(Number)
  const inputDate = new Date(year, month - 1, day)
  const today = new Date()
  const yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  if (inputDate.toDateString() === today.toDateString()) {
    return 'Hoje'
  } else if (inputDate.toDateString() === yesterday.toDateString()) {
    return 'Ontem'
  } else {
    return `${day.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}/${year}`
  }
}

export function toLocalYYYYMMDD(date: Date): string {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
