import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { fetcher } from '@/services/axios'

import { type ILinkAnalytics } from '@/types/hooks/api/useLinks'

export const useGetLinkAnalytics = (
  linkHash?: string,
): {
  linkAnalytics: ILinkAnalytics | undefined
  isLoading: boolean
  refresh: () => void
  error?: string
} => {
  const [isLoading, setIsLoading] = useState(true)
  const shouldFetch = linkHash !== undefined
  const { data, error, mutate } = useSWR<ILinkAnalytics, string>(
    shouldFetch && `/links/${linkHash}/analytics`,

    fetcher,
  )

  function refresh(): void {
    void mutate()
  }

  useEffect(() => {
    setIsLoading(!(data !== undefined || error !== undefined))
  }, [data, error])

  return {
    linkAnalytics: data,
    isLoading,
    refresh,
    error,
  }
}
