import { useState, type ReactElement, useRef } from 'react'

import { useNavigate } from 'react-router-dom'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { TicketSummary } from '@/components/Global/TicketSummary'
import { EventThumbnail } from '@/components/Event/EventThumbnail'

import { useSellStore } from '@/store/sell'
import { useWalletStore } from '@/store/wallet'

import { numberToReais } from '@/helpers/formatNumber'
import { BsCheckLg } from 'react-icons/bs'
import { LuCopy } from 'react-icons/lu'
import { FiLink } from 'react-icons/fi'

import { datesToFormat } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

export function ShareSellLinkModal(): ReactElement {
  const linkRef = useRef<HTMLSpanElement>(null)

  const [linkCopied, setLinkCopied] = useState(false)

  const navigate = useNavigate()
  const { sellTickets, sellPrice, sellLink, sellTicketsEvent } = useSellStore()
  const { refresh } = useWalletStore()

  const { imageKey, title, startDate, location, alias, endDate } =
    sellTicketsEvent

  async function copyLinkToClipboard(): Promise<void> {
    if (linkRef.current !== null) {
      setLinkCopied(true)
      setTimeout(setLinkCopied, 3000, false)
      const textToCopy = linkRef.current.textContent
      await navigator.clipboard.writeText(
        `Para comprar meu ingresso da *${title}* de forma segura, acesse meu link de compra:\n\n${
          textToCopy ?? ''
        }\n\n💃🪩🍹🕺`,
      )
    }
  }

  return (
    <Modal
      closeModal={() => {
        navigate('/wallet')
      }}
      disableTouchToClose
    >
      <div className="flex w-full flex-col gap-6 p-4">
        <div className="mb-2 flex h-2 w-full justify-center">
          <div className="h-full w-1/3 rounded-full bg-[#D9D9D9]" />
        </div>
        <div className=" flex flex-col gap-4">
          <EventThumbnail
            imageKey={imageKey}
            title={title}
            date={datesToFormat(startDate, endDate)}
            location={location}
            briefVariant="dark"
            imageVariant="medium"
            highlighted={false}
            eventAlias={alias}
          />
          <div className="flex max-h-48 flex-col gap-2 overflow-y-scroll">
            {sellTickets
              ?.filter((ticket) => ticket.isSelected)
              .map((ticket, index) => {
                const title = isTruthy(ticket.batchDescription)
                  ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                  : ticket.ticketSpecDescription
                return (
                  <TicketSummary
                    key={index}
                    quantity={1}
                    title={title}
                    subtitle={'Valor: ' + numberToReais(ticket?.price, 2)}
                    variant="dark"
                    counterVariant="dark"
                  />
                )
              })}
          </div>
        </div>
        <div className="flex w-full max-w-full items-center justify-between gap-2 overflow-hidden rounded-lg bg-dark-light-gray px-4 py-3">
          <div className="flex w-4/5 flex-col">
            <div className="flex items-center gap-2">
              <FiLink size={16} color="black" />
              <span className="font-bold text-text-dark ">Link para venda</span>
            </div>
            <span
              ref={linkRef}
              className="my-1 w-full truncate text-xs text-dark-dark-gray"
            >
              {`${
                import.meta.env.VITE_KAYA_URL as string
              }/marketplace/${sellLink}`}
            </span>
          </div>
          <div
            className={
              'transition-color flex h-8 w-8 items-center justify-center rounded-full duration-200 ease-in-out hover:cursor-pointer' +
              (linkCopied ? ' bg-dark-dark-gray' : ' bg-dark-white')
            }
            onClick={() => {
              void copyLinkToClipboard()
            }}
          >
            {linkCopied ? (
              <BsCheckLg size={18} color="white" />
            ) : (
              <LuCopy size={18} color="bg-background-main" />
            )}
          </div>
        </div>
        <div className="flex">
          <div className="flex w-1/2 flex-col gap-1">
            <span className="text-xs leading-3 text-text-secondary">
              Quanto receberá pela venda
            </span>
            <span className="text-2xl font-bold leading-7">
              {numberToReais(sellPrice, 2)}
            </span>
          </div>
          <div className="flex w-1/2 flex-col gap-1">
            <span className="text-xs leading-3 text-text-secondary">
              Valor cobrado do comprador
            </span>
            <span className="text-2xl leading-7">
              {numberToReais(sellPrice * 1.1, 2)}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Button
            onClick={() => {
              void copyLinkToClipboard()
            }}
            enabled={!linkCopied}
            text={linkCopied ? 'Link copiado' : 'Copiar link'}
            className="h-12 text-lg"
          />
          <Button
            onClick={async () => {
              if (refresh !== undefined) await refresh()
              navigate('/wallet')
            }}
            enabled
            text="Voltar para minha carteira"
            className="h-12 bg-dark-light-gray text-lg"
          />
        </div>
      </div>
    </Modal>
  )
}
