import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import {
  type PaymentResponse,
  type PaymentReturn,
} from '@/types/hooks/api/usePayment'

export const useGetPaymentById = (): {
  getPaymentById: (paymentId: number) => Promise<PaymentReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getPaymentById(paymentId: number): Promise<PaymentReturn> {
    setIsLoading(true)

    try {
      const response = await api.get<PaymentResponse>(`/payments/${paymentId}`)
      setIsLoading(false)
      return {
        status: 200,
        paymentData: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { getPaymentById, isLoading }
}
