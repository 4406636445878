import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { LoginSignup } from '@/components/LoginSignup'

interface LoginSignupModalProps {
  isParentClosing: boolean
  closeModal: () => void
}

export function LoginSignupModal({
  isParentClosing,
  closeModal,
}: LoginSignupModalProps): ReactElement {
  return (
    <Modal
      isParentClosing={isParentClosing}
      closeModal={closeModal}
      maxWidth="max-w-[500px]"
    >
      <LoginSignup />
    </Modal>
  )
}
