import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'

import { Form } from '@/components/Global/Form/Form'
import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'
import { EditProfileFormInput } from './EditProfileFormInput'

import { usePostVerifyUsername, usePostVerifyEmail } from '@/hooks/api/Auth'

import { validTLDs } from '@/helpers/tlds'
import { formatPhoneNumber } from '@/helpers/formatString'

import { useUserStore } from '@/store/user'
import { useSnackbarStore } from '@/store/snackbar'
import { useGlobalStore } from '@/store/global'

import { EDIT_PROFILE_ERROR } from '@/errors'

import { usePutEditProfile } from '@/hooks/api/Profile'
import { twMerge } from 'tailwind-merge'

interface EditProfileModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  refreshUserData: () => void
}

export function EditProfileModal({
  closeModal,
  isParentClosing,
  refreshUserData,
}: EditProfileModalProps): ReactElement {
  const [verifyUsernameState, setVerifyUsernameState] = useState<
    null | 'loading' | 'success' | 'error'
  >(null)
  const [prevUsername, setPrevUsername] = useState('')
  const [usernameIsValidated, setUsernameIsValidated] = useState(false)

  const [verifyEmailState, setVerifyEmailState] = useState<
    null | 'loading' | 'success' | 'error'
  >(null)
  const [prevEmail, setPrevEmail] = useState('')
  const [verifiedEmail, setVerifiedEmail] = useState(false)

  const { verifyUsername } = usePostVerifyUsername()
  const { verifyEmail } = usePostVerifyEmail()
  const { putEditProfile, isLoading } = usePutEditProfile()

  const {
    imageKey,
    phoneNumber,
    username: usernameStore,
    firstName,
    lastName,
    email,
    id,
  } = useUserStore()
  const { showErrorSnack, showSuccessSnack } = useSnackbarStore()
  const { setUpdateProfile } = useUserStore()
  const { isMobile } = useGlobalStore()

  const navigate = useNavigate()

  let defaultValues: Record<string, string | number> | null = null
  defaultValues = {
    phoneNumber,
    username: usernameStore,
    firstName,
    lastName,
    email,
  }

  const countryCode = phoneNumber.slice(0, 3)
  const number = phoneNumber.slice(3)
  const isBrazillian = countryCode === '+55'

  const editProfileSchema = z.object({
    username: z
      .string()
      .min(1, { message: 'O username é obrigatório.' })
      .min(4, { message: 'O username precisa ter pelo menos 4 caracateres.' })
      .refine(
        (value) => {
          const emojiRegex = /\p{Extended_Pictographic}/u
          return !emojiRegex.test(value)
        },
        {
          message: 'Não use emojis.',
        },
      )
      .refine(
        (value) => {
          const regex = /^[a-z0-9-_]+$/
          if (!regex.test(value)) {
            return false
          }
          return true
        },
        {
          message: 'Use apenas letras minúsculas, - ou _ no username',
        },
      )
      .refine(
        async (value) => {
          if (value === usernameStore) return true
          if (value !== prevUsername && value !== '') {
            setPrevUsername(value)
            return await handleUsernameVerify(value)
          }
          return usernameIsValidated
        },
        {
          message: 'Username indisponível.',
        },
      ),
    firstName: z
      .string()
      .min(1, { message: 'O nome é obrigatório.' })
      .refine(
        (value) => {
          const emojiRegex = /\p{Extended_Pictographic}/u
          return !emojiRegex.test(value)
        },
        {
          message: 'Não use emojis.',
        },
      )
      .refine(
        (value) => {
          const letterRegex = /^[a-zA-Z\u00C0-\u00FF\s]+$/
          return letterRegex.test(value)
        },
        {
          message: 'Use apenas letras.',
        },
      ),
    lastName: z
      .string()
      .min(1, { message: 'O sobrenome é obrigatório.' })
      .refine(
        (value) => {
          const emojiRegex = /\p{Extended_Pictographic}/u
          return !emojiRegex.test(value)
        },
        {
          message: 'Não use emojis.',
        },
      )
      .refine(
        (value) => {
          const letterRegex = /^[a-zA-Z\u00C0-\u00FF\s]+$/
          return letterRegex.test(value)
        },
        {
          message: 'Use apenas letras.',
        },
      ),
    email: z
      .string()
      .min(1, { message: 'O email é obrigatório.' })
      .email({ message: 'Email inválido.' })
      .refine(
        (email) => {
          const tld = email.split('.').pop()
          return isValidTLD(tld as string)
        },
        {
          message: 'Email inválido.',
        },
      )
      .refine(
        async (value) => {
          if (value === email) return true
          if (prevEmail !== value) {
            setPrevEmail(value)
            return await handleEmailVerify(value)
          }
          return verifiedEmail
        },
        {
          message: 'Email indisponível.',
        },
      ),
  })

  type FormData = z.infer<typeof editProfileSchema>

  function isValidTLD(tld: string | undefined | null): boolean {
    if (tld === undefined || tld === null) return false

    return validTLDs.includes(tld)
  }

  async function handleUsernameVerify(newUsername: string): Promise<boolean> {
    if (usernameStore === newUsername) return true
    setVerifyUsernameState('loading')
    const response = await verifyUsername(newUsername)
    if (response.isAvailable) {
      setVerifyUsernameState('success')
      setUsernameIsValidated(true)
      return true
    }
    setVerifyUsernameState('error')
    setUsernameIsValidated(false)
    return false
  }

  async function handleEmailVerify(newEmail: string): Promise<boolean> {
    if (email === newEmail) return true
    setVerifyEmailState('loading')
    const response = await verifyEmail(newEmail)
    if (response.isAvailable) {
      setVerifyEmailState('success')
      setVerifiedEmail(true)
      return true
    }
    setVerifyEmailState('error')
    setVerifiedEmail(false)
    return false
  }

  async function handleEditProfile(data: FormData): Promise<void> {
    const response = await putEditProfile(id, data)
    if (response.status === 200 && response.userData !== undefined) {
      refreshUserData()
      const { username, firstName, lastName, email } = response.userData
      if (usernameStore !== username) {
        setTimeout(() => {
          navigate(`/memories/${username}`)
        }, 400)
      }
      setUpdateProfile({
        username,
        firstName,
        lastName,
        email,
      })
      closeModal()
      showSuccessSnack({
        title: 'Perfil atualizado',
      })
    } else {
      showErrorSnack(EDIT_PROFILE_ERROR)
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variation="dark"
    >
      <div className="mt-4 flex w-full flex-col items-center gap-0 desktop:mt-0 desktop:gap-4">
        {!isMobile && <h1 className="text-white">editar perfil</h1>}

        {!isMobile && (
          <img
            src={`${import.meta.env.VITE_S3 as string}/${imageKey ?? ''}`}
            alt="Profile image"
            className="size-40 rounded-full"
          />
        )}
        <h1 className="text-xl text-white">
          {isBrazillian
            ? `🇧🇷 ${countryCode} ${formatPhoneNumber(number)}`
            : `🌎 ${phoneNumber}`}
        </h1>

        <div className="mt-4 grid w-full px-4 desktop:px-0">
          <div className="w-full">
            <Form
              formSchema={editProfileSchema}
              className={twMerge(
                'gap-2',
                isMobile ? 'flex flex-col' : 'grid grid-cols-2',
              )}
              handleModalClose={(data: FormData): void => {
                void handleEditProfile(data)
              }}
              defaultValues={defaultValues ?? undefined}
            >
              <EditProfileFormInput
                label="Username"
                type="text"
                id="username"
                placeholder="ex: pedrof"
                name="username"
                autoCorrect="off"
                autoCapitalize="none"
                hasVerifier
                verifierState={verifyUsernameState}
              />
              <EditProfileFormInput
                label="Email"
                type="text"
                id="email"
                placeholder="ex: pedrof@gandaya.io"
                name="email"
                autoCorrect="off"
                autoCapitalize="none"
                hasVerifier
                verifierState={verifyEmailState}
              />
              <EditProfileFormInput
                label="Nome"
                type="text"
                id="firstName"
                placeholder="ex: Pedro"
                name="firstName"
                autoCorrect="off"
                autoCapitalize="words"
                className="col-span-2"
              />
              <EditProfileFormInput
                label="Sobrenome"
                type="text"
                id="lastName"
                placeholder="ex: Fernandes"
                name="lastName"
                autoCorrect="off"
                autoCapitalize="words"
                className="col-span-2"
              />
              <div className="col-span-2 mb-8 flex h-12 w-full min-w-[140px] items-center justify-center desktop:mb-0 desktop:mt-4">
                <Button
                  type="submit"
                  enabled={!isLoading}
                  text="Salvar alterações"
                  isLoading={isLoading}
                  className="w-1/2 text-nowrap bg-white px-4 py-2 text-base font-bold disabled:bg-dark-light-gray"
                />
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  )
}
