import { usePutUserStatus } from '@/hooks/api/Event'
import { useInterestStore } from '@/store/interest'
import { useRoutingStore } from '@/store/routing'
import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'

type UserInterest = 'GOING' | 'INTERESTED' | 'VIEWED'

export function useUpdateUserInterest(
  eventAlias: string,
  closeModal?: () => void,
): {
  handleUpdateUserInterest: (newStatus: UserInterest) => Promise<void>
} {
  const { refreshData, userStatus, setUserStatus } = useInterestStore()
  const { userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()

  const { putUserStatus } = usePutUserStatus(eventAlias)

  const handleUpdateUserInterest = async (
    newStatus: UserInterest,
  ): Promise<void> => {
    if (!userIsLogged) {
      if (closeModal !== undefined) closeModal()
      setToPath('none')
      setTimeout(setShowLoginModal, 400, true)
      return
    }

    const previousStatus = userStatus
    let tempNewStatus = newStatus

    // If the user is already in the status, change it to VIEWED
    if (userStatus === newStatus) {
      tempNewStatus = 'VIEWED'
    }

    setUserStatus(tempNewStatus)
    const response = await putUserStatus(tempNewStatus)

    // Rollback to previous status if some error happen on the update
    if (response.status !== 200) {
      setUserStatus(previousStatus)
    }

    if (refreshData !== undefined) refreshData()
  }

  return { handleUpdateUserInterest }
}
