import { useEffect, type ReactElement } from 'react'
import { useParams, Route, Routes, useNavigate } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

// Subpages
import { EventStory } from './EventStory'
import { EventTickets } from './EventTickets'
import { EventCheckout } from './EventCheckout'
import { EventCheckoutSuccess } from './EventCheckoutSuccess'

// Component
import { Loading } from '@/components/Global/Loading'
import { ProtectedRoute } from '@/components/Global/ProtectedRoute'
import { PixelMeta } from '@/components/MetaPixel'

// Hooks
import { useGetEventByAlias } from '@/hooks/api/Event'

// Store
import { useNavbarStore } from '@/store/navbar'
import { useGlobalStore } from '@/store/global'
import { useEventStore } from '@/store/event'

// Layouts
import { MobileEventLayout } from '@/layouts/MobileEventLayout'
import { DesktopEventLayout } from '@/layouts/DesktopEventLayout'

// Helpers
import { isTruthy } from '@/helpers/validation'
import { isEventFinished } from '@/helpers/isEventFinished'

export function Event(): ReactElement {
  const { eventAlias } = useParams()

  const navigate = useNavigate()

  const { showNavbar } = useNavbarStore()
  const { isMobile } = useGlobalStore()
  const { setCurrentEvent } = useEventStore()

  // Fetch event data
  const { eventData, isLoading, mutate } = useGetEventByAlias(eventAlias ?? '')

  const isPastEvent =
    eventData?.date.endDate !== undefined
      ? isEventFinished(eventData?.date.endDate)
      : false

  useEffect(() => {
    if (isTruthy(eventData)) setCurrentEvent(eventData!)
  }, [eventData])

  if (isLoading)
    return (
      <div className="absolute left-0 top-0 -z-10 flex size-full items-center justify-center bg-background-main">
        <Loading />
      </div>
    )

  if (eventData === undefined && !isLoading)
    return (
      <div className="absolute left-0 top-0 z-50 flex size-full flex-col items-center justify-center bg-dark-black p-8">
        <h1 className="mb-8 text-center text-3xl text-white">
          Não encontramos este evento 🙁
        </h1>
        <button
          className="fixed bottom-8 w-4/5 rounded-full bg-primary-main px-8 py-4 font-bold"
          onClick={() => {
            showNavbar()
            navigate('/')
          }}
        >
          Ir para a página inicial
        </button>
      </div>
    )

  if (isMobile)
    return (
      <HelmetProvider>
        {isTruthy(eventData?.integrationCredentials) && (
          <PixelMeta
            pixelId={eventData?.integrationCredentials?.pixelId ?? ''}
          />
        )}
        <Routes>
          <Route element={<MobileEventLayout eventData={eventData} />}>
            <Route
              index
              element={
                <EventStory
                  eventData={eventData}
                  refresh={() => {
                    void mutate()
                  }}
                />
              }
            />
            <Route
              path="/tickets"
              element={
                <EventTickets
                  isPastEvent={isPastEvent}
                  eventId={eventData?.id}
                />
              }
            />
            <Route element={<ProtectedRoute />}>
              <Route
                path="/checkout"
                element={
                  <EventCheckout
                    isPastEvent={isPastEvent}
                    eventId={eventData?.id}
                  />
                }
              />
              <Route
                path="/checkout/success/:paymentId"
                element={<EventCheckoutSuccess />}
              />
            </Route>
          </Route>
        </Routes>
      </HelmetProvider>
    )

  return (
    <HelmetProvider>
      {isTruthy(eventData?.integrationCredentials) && (
        <PixelMeta pixelId={eventData?.integrationCredentials?.pixelId ?? ''} />
      )}
      <Routes>
        <Route
          element={
            <DesktopEventLayout
              eventData={eventData}
              refresh={() => {
                void mutate()
              }}
            />
          }
        >
          <Route index element={<></>} />
          <Route
            path="/tickets"
            element={
              <EventTickets isPastEvent={isPastEvent} eventId={eventData?.id} />
            }
          />
          <Route element={<ProtectedRoute />}>
            <Route
              path="/checkout"
              element={
                <EventCheckout
                  isPastEvent={isPastEvent}
                  eventId={eventData?.id}
                />
              }
            />
            <Route
              path="/checkout/success/:paymentId"
              element={<EventCheckoutSuccess />}
            />
          </Route>
        </Route>
      </Routes>
    </HelmetProvider>
  )
}
