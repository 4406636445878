import { useRef, useState, type ReactElement } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { EventCard } from '@/components/Global/EventCard'
import { WalletEventDownloadApp } from '@/components/WalletEvent/WalletEventDownloadApp'
import { Loading } from '@/components/Global/Loading'
import { WalletEventTicket } from '@/components/WalletEvent/WalletEventTicket'
import { WalletEventDownloadAppModal } from '@/components/WalletEvent/WalletEventDownloadAppModal'

import { useGlobalStore } from '@/store/global'

import { useGetUserTicketsByEventAlias } from '@/hooks/api/Wallet'

import { isTruthy } from '@/helpers/validation'

import { IoArrowBack } from 'react-icons/io5'
import { IoIosArrowForward } from 'react-icons/io'

export function WalletEvent(): ReactElement {
  const [showDownloadAppModal, setShowDownloadAppModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)

  const { isMobile } = useGlobalStore()

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { walletTicketGroup, isLoading } = useGetUserTicketsByEventAlias(
    eventAlias!,
  )

  const eventCardRef = useRef<HTMLDivElement>(null)

  if (isMobile) navigate('/wallet')

  if (isLoading) {
    return (
      <div className="size-full">
        <Loading />
      </div>
    )
  }

  return (
    <div className="flex size-full flex-col gap-8 p-8">
      <div className="flex w-full items-center gap-4">
        <button
          className="flex size-8 items-center justify-center rounded-full bg-white"
          onClick={() => {
            navigate('/wallet')
          }}
        >
          <IoArrowBack size={16} />
        </button>
        <h1 className="text-lg font-medium text-white">
          Voltar para meus ingressos
        </h1>
      </div>

      <div className="flex w-full gap-8">
        <div className="w-1/3" ref={eventCardRef}>
          {walletTicketGroup !== undefined && (
            <EventCard
              alias={walletTicketGroup?.alias}
              key={walletTicketGroup?.alias}
              imageUrl={walletTicketGroup.imageKey}
              title={walletTicketGroup?.title}
              startDate={walletTicketGroup.startDate}
              endDate={walletTicketGroup.endDate}
              location={
                isTruthy(walletTicketGroup?.location)
                  ? walletTicketGroup?.location
                  : 'Local a definir'
              }
              variation="desktop"
              ticketCount={walletTicketGroup?.tickets.length ?? 0}
            />
          )}
        </div>
        <div className="w-2/3">
          <WalletEventDownloadApp />
        </div>
      </div>

      <div className="flex w-full gap-8">
        <div className="flex w-1/3 flex-col gap-3">
          <button
            className="flex h-[80px] w-full items-center justify-between gap-4 rounded-lg bg-dark-black px-6"
            onClick={() => {
              navigate(`/events/${eventAlias!}`)
            }}
          >
            <span className="truncate font-medium text-white">Ver evento</span>
            <IoIosArrowForward size={20} color="#fff" />
          </button>
          <button
            className="flex h-[80px] w-full items-center justify-between gap-4 rounded-lg bg-dark-black px-6"
            onClick={() => {
              setShowDownloadAppModal(true)
            }}
          >
            <span className="truncate font-medium text-white">
              Transferir ingresso
            </span>
            <IoIosArrowForward size={20} color="#fff" />
          </button>
          <button
            className="flex h-[80px] w-full items-center justify-between gap-4 rounded-lg bg-dark-black px-6"
            onClick={() => {
              setShowDownloadAppModal(true)
            }}
          >
            <span className="truncate font-medium text-white">
              Cancelar ingresso
            </span>
            <IoIosArrowForward size={20} color="#fff" />
          </button>
        </div>

        <div className="grid h-min w-2/3 grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-3">
          {walletTicketGroup?.tickets.map((ticket) => {
            return <WalletEventTicket ticket={ticket} key={ticket.id} />
          })}
        </div>
      </div>

      {showDownloadAppModal && (
        <WalletEventDownloadAppModal
          isParentClosing={isParentClosing}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowDownloadAppModal, 400, false)
          }}
        />
      )}
    </div>
  )
}
