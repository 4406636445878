import { type ReactElement } from 'react'

import { QrCode } from '@/components/Global/QRCode'

import myTicketsBg from '@/assets/images/meusingressos-imagem.png'

export function WalletEventDownloadApp(): ReactElement {
  return (
    <div className="flex size-full flex-col">
      <div className="relative size-full flex-1 overflow-hidden rounded-xl">
        <img
          src={myTicketsBg}
          className="size-full brightness-50"
          alt="Background rounded"
        />
        <div className="absolute inset-0 z-10 flex size-full flex-col justify-between p-4 backdrop-blur-sm">
          <span className="w-4/5 min-w-[250px] text-xl font-semibold text-white lg:text-3xl">
            Baixe o app ou abra o site no celular para visualizar o seu ingresso
          </span>
          <div className="flex h-full items-end gap-4">
            <div className="aspect-square h-20 w-auto">
              <QrCode
                value="https://gdya.dance/app"
                variant="dark"
                bgTransparent
                margin={false}
              />
            </div>
            <span className="text-nowrap text-xs font-medium leading-3 text-white">
              Leia o QR code <br />
              para baixar o app
            </span>
          </div>
        </div>
      </div>
      <div className="h-[64px] w-full" />
    </div>
  )
}
