import { api } from '@/services/axios'
import { useSnackbarStore } from '@/store/snackbar'
import useSWRMutation from 'swr/mutation'

const showErrorSnack = useSnackbarStore.getState().showErrorSnack

async function removeFollower<TData>(url: string): Promise<TData> {
  const response = await api.delete<TData>(`${url}`)
  return response.data
}

export function useRemoveFollower(followerId: number): {
  deleteFollower: () => Promise<string | undefined>
  isMutating: boolean
  error: string | undefined
} {
  const { trigger, isMutating, error } = useSWRMutation<string, string>(
    `/social/followers/${followerId}`,
    removeFollower,
    {
      rollbackOnError: true,
      revalidate: true,
      populateCache: true,
    },
  )

  const deleteFollower = async (): Promise<string | undefined> => {
    try {
      return await trigger()
    } catch (error) {
      showErrorSnack({
        title: 'Erro ao remover seguidor',
        description: 'Tente novamente',
        displayMode: 'ROOT',
      })
    }
  }

  return {
    deleteFollower,
    isMutating,
    error,
  }
}
