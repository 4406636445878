import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type IWalletTicketGroup } from '@/types/hooks/api/useWallet'

export const useGetUserTicketsByEventAlias = (
  alias: string,
): {
  walletTicketGroup: IWalletTicketGroup | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const { data, error } = useSWR<IWalletTicketGroup, string>(
    `/users/tickets/${alias}`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const walletTicketGroup: IWalletTicketGroup | undefined = data ?? undefined

  return {
    walletTicketGroup,
    error,
    isLoading,
  }
}
