import { type INotification } from '@/types/hooks/api/useNotifications'
import { toLocalYYYYMMDD } from '@/helpers/formatDate'

export function aggregateNotificationsByDate(
  notifications: INotification[],
): Array<{
  date: string
  notifications: Array<{
    notification: INotification
    hide: boolean
  }>
}> {
  const aggregationMap: Record<
    string,
    Array<{ notification: INotification; hide: boolean }>
  > = {}
  notifications.forEach((notification) => {
    const localDate = new Date(notification.createdAt)
    const date = toLocalYYYYMMDD(localDate)
    if (!(date in aggregationMap)) {
      aggregationMap[date] = []
    }
    aggregationMap[date].push({ notification, hide: false })
  })

  const aggregatedArray = Object.keys(aggregationMap).map((date) => ({
    date,
    notifications: aggregationMap[date],
  }))
  aggregatedArray.sort((a, b) => (a.date < b.date ? 1 : -1))
  return aggregatedArray
}
