import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { useUserStore } from '@/store/user'

import { Modal } from '@/components/Global/Modal'
import { Selector } from '@/components/Global/Selector'
import { Button } from '@/components/Global/Button'

import { useLogout } from '@/hooks/api/Profile'
import { useSnackbarStore } from '@/store/snackbar'

interface OptionsModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  openSupportModal: () => void
}

export function OptionsModal({
  closeModal,
  isParentClosing,
  openSupportModal,
}: OptionsModalProps): ReactElement {
  const [optionSelected, setOptionSelected] = useState<
    '' | 'support' | 'logout'
  >('')

  const { logout, isLoading } = useLogout()

  const navigate = useNavigate()

  const { logoutUser } = useUserStore()
  const { showErrorSnack } = useSnackbarStore()

  async function handleLogout(): Promise<void> {
    const response = await logout()
    if (response.status === 200) {
      logoutUser()
      closeModal()
      navigate('/')
    } else {
      showErrorSnack({
        title: 'Erro ao fazer logout. Tente novamente.',
      })
    }
  }

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variation="dark"
    >
      <div className="flex w-full flex-col gap-2 p-2 pt-4">
        <Selector
          title="Suporte"
          subtitle="Entre em contato conosco para criar eventos ou tirar dúvidas."
          variant="dark"
          isSelected={optionSelected === 'support'}
          onClick={() => {
            setOptionSelected('support')
          }}
        />
        <Selector
          title="Logout"
          subtitle="Você será desconectado da sua conta e para acessá-la terá que fazer o login novamente."
          variant="dark"
          isSelected={optionSelected === 'logout'}
          onClick={() => {
            setOptionSelected('logout')
          }}
        />
        <div className="my-2 h-12 w-full">
          <Button
            text="Confirmar"
            enabled={optionSelected !== '' && !isLoading}
            onClick={() => {
              if (optionSelected === 'support') {
                closeModal()
                openSupportModal()
              } else if (optionSelected === 'logout') {
                void handleLogout()
              }
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </Modal>
  )
}
