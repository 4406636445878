import { useEffect, type ReactElement, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { usePostCheckout } from '@/hooks/api/Checkout'
import { useGetPaymentById } from '@/hooks/api/Payment'
import { usePixelTrackAddPaymentInfo } from '@/hooks/pixel'

import { useTicketsStore } from '@/store/tickets'
// import { useUserStore } from '@/store/user'
import { useEventStore } from '@/store/event'
import { useSnackbarStore } from '@/store/snackbar'

import { isTruthy } from '@/helpers/validation'

import {
  CANT_CREATE_PAYMENT,
  PAYMENT_NOT_APPROVED_ERROR,
  PAYMENT_VALIDATION_ERROR,
  TICKETS_NOT_AVAILABLE_ANYMORE,
} from '@/errors'
import { EventCheckoutMobile } from './EventCheckoutMobile'
import { EventBase } from '@/compositions/EventBase'
import { useGlobalStore } from '@/store/global'
import { EventCheckoutDesktop } from './EventCheckoutDesktop'

interface EventCheckoutProps {
  eventId?: number
  isPastEvent: boolean
}

export function EventCheckout({
  eventId,
  isPastEvent,
}: EventCheckoutProps): ReactElement {
  const [total, setTotal] = useState(0)
  const [selectedPayment, setSelectedPayment] = useState<
    'PIX' | 'CREDITCARD' | 'BOLETO' | 'INTL_CREDITCARD' | ''
  >('')
  const [pixCopied, setPixCopied] = useState(false)
  const [payTicket, setPayTicket] = useState(false)
  const [pixCode, setPixCode] = useState('')
  const [paymentId, setPaymentId] = useState(0)
  const [isLoadingVerify, setIsLoadingVerify] = useState(false)
  const [allFreeTickets, setAllFreeTickets] = useState(false)
  const [hasTicketSelected, setHasTicketSelected] = useState(false)

  const [paymentSuccessfull, setPaymentSuccessfull] = useState(false)
  const [isModalClosing, setIsModalClosing] = useState(false)

  const navigate = useNavigate()
  const { eventAlias } = useParams()

  const { tickets, getTotal, getFees } = useTicketsStore()
  const { setTicketQuantity, integration } = useEventStore()
  const { showErrorSnack } = useSnackbarStore()
  const { isMobile } = useGlobalStore()

  const {
    createPaymentPix,
    createPaymentCard,
    createPaymentIntlCard,
    reserveFreeTicket,
    isLoading,
  } = usePostCheckout(eventId ?? 0, tickets)
  const { getPaymentById } = useGetPaymentById()

  const { trackAddPaymentInfo } = usePixelTrackAddPaymentInfo()

  useEffect(() => {
    setTotal(getTotal())
    const ticketsQuantity = tickets.reduce(
      (acc, ticket) => acc + ticket.quantitySelected,
      0,
    )
    setTicketQuantity(ticketsQuantity)
  }, [tickets])

  useEffect(() => {
    const fee = getFees().find((fee) => fee.type === selectedPayment)
    if (fee !== undefined) {
      setTotal(getTotal() + fee.absolute)
    }
  }, [selectedPayment])

  async function handlePayment(): Promise<boolean> {
    if (isTruthy(integration?.pixelId ?? ''))
      trackAddPaymentInfo(integration?.pixelId ?? '')
    if (selectedPayment === 'PIX') {
      const response = await createPaymentPix()
      if (response.status === 200) {
        setPayTicket(true)
        setPixCode(response.code ?? '')
        setPaymentId(response.paymentId ?? 0)
        return true
      } else {
        if (response.status === 406) {
          showErrorSnack(TICKETS_NOT_AVAILABLE_ANYMORE)
          setTimeout(navigate, 3400, `/events/${eventAlias ?? ''}/tickets`)
        } else {
          showErrorSnack(CANT_CREATE_PAYMENT)
        }
        return false
      }
    } else if (selectedPayment === 'CREDITCARD') {
      const response = await createPaymentCard()
      if (response.status === 200) {
        window.location.replace(response.redirectUrl ?? '')
        return true
      } else {
        if (response.status === 406) {
          showErrorSnack(TICKETS_NOT_AVAILABLE_ANYMORE)
          setTimeout(navigate, 3400, `/events/${eventAlias ?? ''}/tickets`)
        } else {
          showErrorSnack(CANT_CREATE_PAYMENT)
        }
        return false
      }
    } else if (selectedPayment === 'INTL_CREDITCARD') {
      const response = await createPaymentIntlCard()
      if (response.status === 200) {
        window.location.replace(response.redirectUrl ?? '')
        return true
      } else {
        if (response.status === 406) {
          showErrorSnack(TICKETS_NOT_AVAILABLE_ANYMORE)
          setTimeout(navigate, 3400, `/events/${eventAlias ?? ''}/tickets`)
        } else {
          showErrorSnack(CANT_CREATE_PAYMENT)
        }
        return false
      }
    } else if (allFreeTickets) {
      const response = await reserveFreeTicket()
      if (response.status === 200) {
        setPaymentId(response.paymentId ?? 0)
        setPaymentSuccessfull(true)
        return true
      } else {
        if (response.status === 406) {
          showErrorSnack(TICKETS_NOT_AVAILABLE_ANYMORE)
        } else {
          showErrorSnack(CANT_CREATE_PAYMENT)
        }
        setTimeout(navigate, 3000, `/events/${eventAlias ?? ''}/tickets`)
        return false
      }
    }
    return false
  }

  // Checking if all selected tickets have the same payment methods
  const acceptedPaymentMethods = {
    PIX: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some((payment) => payment.type === 'PIX'),
      ),
    CREDITCARD: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some(
          (payment) => payment.type === 'CREDITCARD',
        ),
      ),
    INTL_CREDITCARD: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some(
          (payment) => payment.type === 'INTL_CREDITCARD',
        ),
      ),
    BOLETO: !!tickets
      .filter((ticket) => ticket.quantitySelected > 0 && ticket.finalPrice > 0)
      .every((ticket) =>
        ticket?.paymentMethods?.some((payment) => payment.type === 'BOLETO'),
      ),
  }

  async function handleVerifyPayment(): Promise<boolean> {
    setIsLoadingVerify(true)
    const response = await getPaymentById(Number(paymentId))
    setIsLoadingVerify(false)
    if (response.status === 200 && response.paymentData !== undefined) {
      if (response.paymentData.approved) {
        setPaymentSuccessfull(true)
        return true
      } else {
        showErrorSnack(PAYMENT_NOT_APPROVED_ERROR)
        return false
      }
    } else {
      showErrorSnack(PAYMENT_VALIDATION_ERROR)
      return false
    }
  }

  useEffect(() => {
    const allFree = tickets
      .filter((ticket) => ticket.quantitySelected > 0)
      .every((t) => t.finalPrice === 0)
    setAllFreeTickets(allFree)

    const hasSelected = tickets.some((t) => t.quantitySelected > 0)
    setHasTicketSelected(hasSelected)
  }, [tickets])

  async function copyToClipboard(): Promise<void> {
    setPixCopied(true)
    setTimeout(setPixCopied, 3000, false)
    await navigator.clipboard.writeText(pixCode)
  }

  return (
    <EventBase.Root isMobile={isMobile}>
      {isMobile ? (
        <EventCheckoutMobile
          total={total}
          pixCopied={pixCopied}
          payTicket={payTicket}
          isLoadingVerify={isLoadingVerify}
          hasTicketSelected={hasTicketSelected}
          acceptedPaymentMethods={acceptedPaymentMethods}
          allFreeTickets={allFreeTickets}
          pixCode={pixCode}
          setPixCopied={setPixCopied}
          handlePayment={handlePayment}
          handleVerifyPayment={handleVerifyPayment}
          copyToClipboard={copyToClipboard}
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
          isLoading={isLoading}
          paymentSuccessfull={paymentSuccessfull}
          isModalClosing={isModalClosing}
          setIsModalClosing={setIsModalClosing}
          isPastEvent={isPastEvent}
        />
      ) : (
        <EventCheckoutDesktop
          payTicket={payTicket}
          selectedPayment={selectedPayment}
          setSelectedPayment={setSelectedPayment}
          acceptedPaymentMethods={acceptedPaymentMethods}
          allFreeTickets={allFreeTickets}
          total={total}
          handlePayment={handlePayment}
          copyToClipboard={copyToClipboard}
          pixCopied={pixCopied}
          isLoading={isLoading}
          hasTicketSelected={hasTicketSelected}
          pixCode={pixCode}
          isLoadingVerify={isLoadingVerify}
          handleVerifyPayment={handleVerifyPayment}
          paymentSuccessfull={paymentSuccessfull}
        />
      )}
    </EventBase.Root>
  )
}
