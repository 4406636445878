import { useRef, useState, type ReactElement, useEffect } from 'react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { MdOutlineAddReaction } from 'react-icons/md'

import { useToggleReaction } from '@/hooks/api/Event'

import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'
import { isTruthy } from '@/helpers/validation'
import { useGlobalStore } from '@/store/global'
import { twMerge } from 'tailwind-merge'

interface Reaction {
  emoji: string
  count: number
  activeUserReactionId?: number | null
}

interface ReactionObj extends Reaction {
  isHighlighted: boolean
}

interface EventReactionsProps {
  eventId: number
  eventReactions: Reaction[] | undefined
}

export function EventReactions({
  eventId,
  eventReactions,
}: EventReactionsProps): ReactElement {
  const [reactions, setReactions] = useState<ReactionObj[] | undefined>()
  const [reactionsModalIsOpen, setReactionsModalIsOpen] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLDivElement>(null)

  // Reactions
  const { postToggleReaction } = useToggleReaction()

  // Stores
  const { userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()
  const { isMobile } = useGlobalStore()

  const buttonStyle = `bg-opacity-/50 rounded-full bg-dark-black px-2 py-1 border-2 transition-color duration-200 ease-in-out hover:cursor-pointer text-white text-sm font-bold flex flex-col items-center justify-center min-w-[46px]`

  function handleEmojiButtonClick(emoji: string): void {
    if (reactionsModalIsOpen) setReactionsModalIsOpen(false)

    let emojiExistsOnReactions = false
    const newReactions = reactions
      ?.map((reaction) => {
        if (reaction.emoji === emoji) {
          emojiExistsOnReactions = true
          return {
            ...reaction,
            count: reaction.isHighlighted
              ? reaction.count - 1
              : reaction.count + 1,
            isHighlighted: !reaction.isHighlighted,
          }
        }
        return { ...reaction }
      })
      .filter((reaction) => reaction.count > 0)

    if (!emojiExistsOnReactions) {
      newReactions?.push({
        emoji,
        count: 1,
        isHighlighted: true,
      })
    }

    setReactions(newReactions)

    void postToggleReaction(eventId, emoji)
  }

  const setMaxHeight = (): void => {
    if (contentRef.current != null) {
      const contentElement = contentRef.current
      const contentHeight = contentElement.scrollHeight
      contentElement.style.maxHeight = `${contentHeight}px`
    }
  }

  useEffect(() => {
    if (isTruthy(eventReactions)) {
      const allReactions = eventReactions?.map((r) => {
        return {
          ...r,
          isHighlighted: r.activeUserReactionId !== null,
        }
      })

      const highlightedReactions = allReactions
        ?.filter((reaction) => reaction.isHighlighted)
        .sort((a, b) => (a.count > b.count ? -1 : 1))

      const notHighlightedReactions = allReactions
        ?.filter((reaction) => !reaction.isHighlighted)
        .sort((a, b) => (a.count > b.count ? -1 : 1))

      const concatenatedReactions = highlightedReactions?.concat(
        notHighlightedReactions!,
      )

      setReactions(concatenatedReactions?.slice(0, 20))
    }
  }, [eventReactions])

  useEffect(() => {
    setMaxHeight()
  }, [eventReactions, reactions])

  return (
    <div
      className={'relative z-30 flex w-full flex-wrap gap-2'}
      ref={contentRef}
    >
      {reactions?.map((reaction, index) => {
        return (
          <div
            key={index}
            className={
              buttonStyle +
              (reaction.isHighlighted
                ? ' border-primary-main'
                : ' border-dark-black')
            }
            onClick={() => {
              if (!userIsLogged) {
                setToPath(`none`)
                setShowLoginModal(true)
                return
              }
              handleEmojiButtonClick(reaction.emoji)
            }}
          >
            {reaction.emoji} {reaction.count}
          </div>
        )
      })}
      <div
        className={
          buttonStyle +
          (reactionsModalIsOpen ? ' border-primary-main' : ' border-dark-black')
        }
        onClick={() => {
          if (!userIsLogged) {
            setToPath(`none`)
            setShowLoginModal(true)
            return
          }
          setReactionsModalIsOpen(!reactionsModalIsOpen)
        }}
        ref={buttonRef}
      >
        <MdOutlineAddReaction
          size={20}
          color="white"
          style={{
            marginRight: '1px',
          }}
        />
      </div>
      {reactionsModalIsOpen && reactions != null && (
        <div
          className={twMerge(
            'absolute left-1/2 mt-2 -translate-x-1/2',
            isMobile ? 'top-full' : 'bottom-10 left-1/2',
          )}
        >
          <Picker
            data={data}
            onEmojiSelect={(emoji: { native: string }) => {
              const selectedReaction = reactions.find(
                (reaction) => reaction.emoji === emoji.native,
              )
              const selectedReactionIsHighlighted =
                selectedReaction?.isHighlighted

              if (selectedReactionIsHighlighted === true) {
                setReactionsModalIsOpen(false)
              } else {
                handleEmojiButtonClick(emoji.native)
              }
            }}
            maxFrequentRows={0}
            perLine={8}
            theme="dark"
            // searchPosition="none"
            locale="pt"
            previewPosition="none"
            navPosition="none"
            onClickOutside={(event: MouseEvent) => {
              if (buttonRef.current?.contains(event.target as Node) ?? false) {
                return
              }
              setReactionsModalIsOpen(false)
            }}
            exceptEmojis={[
              'broken_heart',
              'pouting_cat',
              'crying_cat_face',
              'woman-shrugging',
              'man-shrugging',
              'shrug',
              'woman-facepalming',
              'man-facepalming',
              'face_palm',
              'woman-bowing',
              'man-bowing',
              'bow',
              '-1',
              'point_down',
              'middle_finger',
              'pinched_fingers',
              'clown_face',
              'hankey',
              'imp',
              'face_with_symbols_on_mouth',
              'angry',
              'rage',
              'triumph',
              'yawning_face',
              'tired_face',
              'weary',
              'sweat',
              'disappointed',
              'persevere',
              'confounded',
              'sob',
              'cry',
              'disappointed_relieved',
              'cold_sweat',
              'fearful',
              'anguished',
              'frowning',
              'pleading_face',
              'flushed',
              'white_frowning_face',
              'slightly_frowning_face',
              'worried',
              'face_with_diagonal_mouth',
              'confused',
              'face_with_monocle',
              'face_with_spiral_eyes',
              'dizzy_face',
              'woozy_face',
              'hot_face',
              'sneezing_face',
              'face_vomiting',
              'nauseated_face',
              'face_with_head_bandage',
              'face_with_thermometer',
              'mask',
              'sleeping',
              'sleepy',
              'pensive',
              'lying_face',
              'face_with_rolling_eyes',
              'unamused',
              'no_mouth',
              'expressionless',
              'neutral_face',
              'face_with_raised_eyebrow',
            ]}
            categories={[
              'people',
              // 'nature',
              // 'food',
              // 'activity',
              'places',
              // 'objects',
              // 'symbols',
              // 'flags',
            ]}
          />
        </div>
      )}
    </div>
  )
}
