import { useState, type ReactElement, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'

interface TransferAnimationProps {
  afterTransferPage: 'success' | 'error' | undefined
  onEnd: (
    s:
      | 'preTransfer'
      | 'isTransfering'
      | 'posTransferSuccess'
      | 'posTransferError',
  ) => void
  username: string
}

export function TransferAnimation({
  afterTransferPage,
  onEnd,
  username,
}: TransferAnimationProps): ReactElement {
  const [animationState, setAnimationState] = useState('preparing')
  const [allowReady, setAllowReady] = useState(false)

  const variants = {
    preparing: {
      opacity: 1,
      y: '50%',
    },
    notifying: {
      opacity: 1,
      y: '25%',
    },
    ready: {
      opacity: 1,
      y: '0%',
    },
  }

  function animationControl(): void {
    setAnimationState('notifying')
    setTimeout(setAllowReady, 1000, true)
  }

  useEffect(() => {
    setTimeout(animationControl, 1500)
  }, [])

  useEffect(() => {
    if (afterTransferPage !== undefined && allowReady) {
      setAnimationState('ready')
      setTimeout(
        onEnd,
        1500,
        afterTransferPage === 'success'
          ? 'posTransferSuccess'
          : 'posTransferError',
      )
    }
  }, [afterTransferPage, allowReady])

  return (
    <motion.div
      className="my-8 flex h-[180px] flex-col gap-3"
      initial={{ opacity: 0, y: '50%' }}
      animate={animationState}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      variants={variants}
    >
      <h1
        className={twMerge(
          'transition-all duration-200 ease-in-out',
          animationState !== 'preparing'
            ? 'text-xl text-dark-light-gray'
            : 'text-2xl text-dark-black',
        )}
      >
        Preparando transferência
      </h1>
      <h1
        className={twMerge(
          'transition-all duration-200 ease-in-out',
          animationState !== 'notifying'
            ? 'text-xl text-dark-light-gray'
            : 'text-2xl text-dark-black',
        )}
      >
        Notificando @{username}
      </h1>
      <h1
        className={twMerge(
          'transition-all duration-200 ease-in-out',
          animationState !== 'ready'
            ? 'text-xl text-dark-light-gray'
            : 'text-2xl text-dark-black',
        )}
      >
        Pronto!
      </h1>
    </motion.div>
  )
}
