import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { formatNumber } from '@/helpers/formatNumber'

import { Button } from '@/components/Global/Button'

import {
  usePostFollowOrganization,
  useDeleteUnfollowOrganization,
} from '@/hooks/api/Organization'

import { isTruthy } from '@/helpers/validation'

import { useRoutingStore } from '@/store/routing'
import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'

interface OrganizationCardProps {
  organizationData?: {
    name: string
    description: string
    imageKey: string
    followers: number
    id: number
    followStatus: 'FOLLOWING' | 'NOT_FOLLOWING'
  }
  refresh: () => void
}

export function OrganizationCard({
  organizationData,
  refresh,
}: OrganizationCardProps): ReactElement {
  const { organizationAlias } = useParams()

  const { postFollowOrganization, isLoading: isLoadingFollow } =
    usePostFollowOrganization(organizationAlias ?? '')
  const { deleteUnfollowOrganization, isLoading: isLoadingUnfollow } =
    useDeleteUnfollowOrganization(organizationAlias ?? '')

  const { userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      setToPath(`none`)
      return
    }

    const response = await postFollowOrganization()
    if (response.status === 200) {
      refresh()
    }
  }

  async function handleUnfollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      setToPath(`none`)
      return
    }

    const response = await deleteUnfollowOrganization()
    if (response.status === 200) {
      refresh()
    }
  }

  if (organizationData === undefined) {
    return (
      <div className="h-52 w-full animate-pulse rounded-lg bg-background-main"></div>
    )
  }
  return (
    <div className="flex w-full flex-col gap-4 rounded-lg bg-background-main p-4 text-white">
      <div className="flex w-full gap-4">
        <div className="aspect-square h-24">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${
              organizationData.imageKey
            }`}
            alt="Logo da organização"
            className="size-full rounded-lg"
          />
        </div>
        <div className="flex w-full flex-col justify-between">
          <div className="flex flex-col gap-1 pt-1">
            <span className="line-clamp-1 text-2xl font-black leading-tight">
              {organizationData.name}
            </span>
            <span className="text-base font-bold leading-3">
              {formatNumber(organizationData.followers)}{' '}
              <span className="text-sm font-normal">
                {' '}
                {organizationData.followers === 1 ? 'seguidor' : 'seguidores'}
              </span>
            </span>
          </div>
          <div className="h-8 w-full">
            <Button
              enabled={!isLoadingUnfollow && !isLoadingFollow}
              text={
                organizationData.followStatus === 'FOLLOWING'
                  ? 'Deixar de seguir'
                  : 'Seguir'
              }
              className="text-sm font-semibold"
              onClick={(e) => {
                e.stopPropagation()
                if (organizationData.followStatus === 'FOLLOWING') {
                  void handleUnfollow()
                } else {
                  void handleFollow()
                }
              }}
              isLoading={isLoadingUnfollow || isLoadingFollow}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          </div>
        </div>
      </div>
      {isTruthy(organizationData.description) && (
        <span className="text-sm leading-4">
          {organizationData.description}
        </span>
      )}
    </div>
  )
}
