import { create } from 'zustand'

interface GlobalProps {
  // Header
  headerHeight: number
  setHeaderHeight: (h: number) => void

  // Header and footer
  scrollToEvents: () => void
  setScrollToEvents: (v: () => void) => void

  // Device
  isMobile: boolean
  setIsMobile: (s: boolean) => void

  // Modals
  showLoginModal: boolean
  setShowLoginModal: (n: boolean) => void
  showDownloadAppModal: boolean
  setShowDownloadAppModal: (n: boolean) => void
  showSearchBar: boolean
  setShowSearchBar: (n: boolean) => void
}

export const useGlobalStore = create<GlobalProps>()((set) => ({
  headerHeight: 0,
  setHeaderHeight: (v: number) => {
    set(() => ({ headerHeight: v }))
  },
  scrollToEvents: () => {
    // do nothing
  },
  setScrollToEvents: (v: () => void) => {
    set(() => ({ scrollToEvents: v }))
  },
  isMobile: true,
  setIsMobile: (s: boolean) => {
    set(() => ({ isMobile: s }))
  },
  showLoginModal: false as boolean,
  setShowLoginModal: (n: boolean) => {
    set(() => ({ showLoginModal: n }))
  },
  showDownloadAppModal: false as boolean,
  setShowDownloadAppModal: (n: boolean) => {
    set(() => ({ showDownloadAppModal: n }))
  },
  showSearchBar: false as boolean,
  setShowSearchBar: (n: boolean) => {
    set(() => ({ showSearchBar: n }))
  },
}))
