import { type ReactElement } from 'react'

interface SectionProps {
  title: string
  children: ReactElement
}

export function Section({ title, children }: SectionProps): ReactElement {
  return (
    <div>
      <h1 className="pl-4 text-2xl text-white desktop:text-[28px]">{title}</h1>
      <div className="mt-2 px-4 desktop:mt-5">{children}</div>
    </div>
  )
}
