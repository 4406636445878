import { type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { type IGenericEvent } from '@/types/hooks/api/useSearch'

import { datesToFormat } from '@/helpers/formatDate'

interface HeaderSearchEventCardProps {
  event: IGenericEvent
}

export function HeaderSearchEventCard({
  event,
}: HeaderSearchEventCardProps): ReactElement {
  return (
    <Link
      to={`/events/${event.alias}`}
      className="flex w-full items-center gap-4"
    >
      <div className="size-12">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${event.imageKey}`}
          alt="Event image"
          className="size-full rounded-lg"
        />
      </div>

      <div className="flex grow flex-col">
        <span className="text-xs font-semibold">{event.title}</span>
        <span className="text-xs text-dark-dark-gray">
          {datesToFormat(event.date, event.endDate)}
        </span>
        <span className="text-xs text-dark-dark-gray">{event.location}</span>
      </div>
    </Link>
  )
}
