import { useEffect, useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { DebounceInput } from 'react-debounce-input'

import { GoSearch } from 'react-icons/go'

import { DeleteIcon } from '../../Icons/DeleteIcon'

import { SEARCH_ERROR } from '@/errors'

import { useGetGenericSearch } from '@/hooks/api/Search'

import { type IGenericSearchDataResponse } from '@/types/hooks/api/useSearch'

import { useSnackbarStore } from '@/store/snackbar'

import { HeaderSearchUserCard } from './HeaderSearchUserCard'
import { HeaderSearchOrganizationCard } from './HeaderSearchOrganizationCard'
import { HeaderSearchEventCard } from './HeaderSearchEventCard'
import { Spinner } from '../../Spinner'

interface HeaderSearchProps {
  showSearch: boolean
  setShowSearch: (v: boolean) => void
}

export function HeaderSearch({
  setShowSearch,
  showSearch,
}: HeaderSearchProps): ReactElement {
  const [searchParam, setSearchParam] = useState<string>('')
  const [searchData, setSearchData] = useState<IGenericSearchDataResponse>()
  const [didSearch, setDidSearch] = useState(false)

  const { search, isLoading } = useGetGenericSearch()

  const { showErrorSnack } = useSnackbarStore()

  const emptySearch =
    searchData?.events.length === 0 &&
    searchData?.users.length === 0 &&
    searchData?.organizations.length === 0

  async function handleSearch(searchParamInput: string): Promise<void> {
    setSearchParam(searchParamInput)

    const response = await search(searchParamInput)
    if (response.status === 200 && response.data !== undefined) {
      setSearchData(response.data)
      setDidSearch(true)
    } else {
      showErrorSnack(SEARCH_ERROR)
      setDidSearch(false)
    }
  }

  useEffect(() => {
    setSearchParam('')
  }, [showSearch])

  useEffect(() => {
    if (searchParam === '') {
      setDidSearch(false)
    }
  }, [searchParam])

  return (
    <AnimatePresence>
      {showSearch && (
        <motion.div
          className="fixed left-0 top-0 z-50 size-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div
            className="size-full bg-dark-black/50"
            onClick={(e) => {
              e.preventDefault()
              setShowSearch(false)
            }}
          />

          <div className="fixed left-1/2 top-[20%] flex w-4/5 max-w-[600px] -translate-x-1/2 flex-col gap-6">
            <div className="flex w-full items-center gap-4 overflow-hidden rounded-full bg-white p-5">
              <div>
                <GoSearch
                  color="#181818"
                  size={24}
                  style={{ strokeWidth: '1px' }}
                />
              </div>
              <DebounceInput
                className="size-full text-lg outline-none ring-0"
                debounceTimeout={500}
                onChange={(event) => {
                  void handleSearch(event.target.value)
                }}
                autoFocus
                placeholder="Pesquisar"
              />
              <AnimatePresence mode="wait">
                {isLoading ? (
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.1 }}
                    key="search-loader"
                  >
                    <Spinner
                      borderWidth="border-[4px]"
                      borderColor="border-[#232323]/50"
                      bottomBorderColor="border-b-[#232323]"
                      width="w-6"
                      height="h-6"
                    />
                  </motion.div>
                ) : (
                  <>
                    {searchParam !== '' && (
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.1 }}
                        onClick={() => {
                          setSearchParam('')
                        }}
                        key="delete-search"
                      >
                        <DeleteIcon />
                      </motion.div>
                    )}
                  </>
                )}
              </AnimatePresence>
            </div>

            {didSearch && (
              <div className="flex size-full max-h-[500px] flex-col gap-4 overflow-y-scroll rounded-2xl bg-white px-4 py-3">
                {searchData !== undefined && !emptySearch ? (
                  <>
                    <span className="text-[#d8d8d8]">
                      Resultados da pesquisa
                    </span>
                    {searchData.users.length > 0 && (
                      <div className="flex flex-col gap-2">
                        <span className="text-sm font-semibold">Usuários</span>
                        {searchData.users.map((user) => {
                          return (
                            <HeaderSearchUserCard
                              key={user.id}
                              user={user}
                              closeSearch={() => {
                                setShowSearch(false)
                              }}
                            />
                          )
                        })}
                      </div>
                    )}
                    {searchData.organizations.length > 0 && (
                      <div className="flex flex-col gap-2">
                        <span className="text-sm font-semibold">
                          Organizações
                        </span>
                        {searchData.organizations.map((organization) => {
                          return (
                            <HeaderSearchOrganizationCard
                              key={organization.id}
                              organization={organization}
                              closeSearch={() => {
                                setShowSearch(false)
                              }}
                            />
                          )
                        })}
                      </div>
                    )}
                    {searchData.events.length > 0 && (
                      <div className="flex flex-col gap-2">
                        <span className="text-sm font-semibold">Eventos</span>
                        {searchData.events.map((event) => {
                          return (
                            <HeaderSearchEventCard
                              key={event.alias}
                              event={event}
                            />
                          )
                        })}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <span>Não encontramos resultados para a sua busca 😔</span>
                  </>
                )}
              </div>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
