import useSWR from 'swr'
import fetcher from '@/services/axios'
import { type IEventData } from '@/types/Social'

export const useGetEvents = (
  userId: number,
): {
  events: IEventData[] | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, mutate, isLoading } = useSWR<IEventData[], string>(
    `/social/${userId}/events`,
    fetcher,
  )

  const events = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    events,
    error,
    isLoading,
    refresh,
  }
}
