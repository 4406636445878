import useSWR from 'swr'
import fetcher from '@/services/axios'
import { type IFollowerData } from '@/types/Social'

export const useGetFollowers = (
  userId: number,
): {
  followers: IFollowerData[] | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, mutate, isLoading } = useSWR<IFollowerData[], string>(
    `/social/${userId}/followers`,
    fetcher,
  )

  const followers = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    followers,
    error,
    isLoading,
    refresh,
  }
}
