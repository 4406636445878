import { type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { QrCode } from '@/components/Global/QRCode'

interface MarketplaceWarningModalProps {
  isParentClosing: boolean
  closeModal: () => void
  linkUrl: string
}

export function MarketplaceWarningModal({
  isParentClosing,
  closeModal,
  linkUrl,
}: MarketplaceWarningModalProps): ReactElement {
  return (
    <Modal isParentClosing={isParentClosing} closeModal={closeModal}>
      <div className="flex size-full flex-col items-center gap-8 py-4">
        <h1 className="text-4xl">Revenda segura</h1>
        <span className="text-center leading-4">
          Para sua segurança, todas as revendas são <br /> realizada pelo nosso
          site ou aplicativo.
        </span>
        <div className="size-52 pb-4">
          <QrCode
            value={linkUrl}
            bgTransparent
            margin={false}
            variant="light"
          />
        </div>
      </div>
    </Modal>
  )
}
