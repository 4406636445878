import { type ReactElement } from 'react'

import { ModalMobile } from './ModalMobile'
import { ModalDesktop } from './ModalDesktop'

import { useGlobalStore } from '@/store/global'

interface ModalProps {
  closeModal?: () => void
  children: ReactElement
  disableTouchToClose?: boolean
  isParentClosing?: boolean
  hasRoundedBorder?: boolean
  variation?: 'dark' | 'light'
  maxWidth?: string
}

export function Modal(props: ModalProps): ReactElement {
  const { isMobile } = useGlobalStore()

  if (isMobile) return <ModalMobile {...props} />
  return <ModalDesktop {...props} />
}
