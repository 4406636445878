import { useState, useRef, type ReactElement, type ChangeEvent } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import 'react-image-crop/dist/ReactCrop.css'

import {
  useGetDefaultProfilePictures,
  usePostNewProfilePicture,
  usePostDefaultProfilePicture,
} from '@/hooks/api/Profile'

import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'
import { useSnackbarStore } from '@/store/snackbar'

import { CropImage } from '@/components/LoginSignup/ProfilePicture/CropImage'
import { SelectImage } from '@/components/LoginSignup/ProfilePicture/SelectImage'

import { PROFILE_PICTURE_ERROR } from '@/errors'
import { useGlobalStore } from '@/store/global'

interface ProfilePictureProps {
  closeModal?: () => void
}

export function ProfilePicture({
  closeModal,
}: ProfilePictureProps): ReactElement {
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null)
  const [uploadedImage, setUploadedImage] = useState<File>()
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string>()
  const [cropImage, setCropImage] = useState<boolean>(false)
  const [isUploadingImage, setIsUploadingImage] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  const navigate = useNavigate()

  const { setProfileImageKey, setUserIsLogged, username } = useUserStore()
  const { toPath, setToPath } = useRoutingStore()
  const { showErrorSnack } = useSnackbarStore()
  const { setShowLoginModal } = useGlobalStore()

  const { defaultProfilePictures, isLoading: isLoadingDefaultProfilePictures } =
    useGetDefaultProfilePictures()

  const { postNewProfilePicture } = usePostNewProfilePicture()

  const { postDefaultProfilePicture } = usePostDefaultProfilePicture()

  function handleImageChange(event: ChangeEvent): void {
    event.stopPropagation()
    event.preventDefault()

    setSelectedImageId(null)

    const target = event.target as HTMLInputElement
    const files = target.files as FileList
    const profileImage = files[0]

    setUploadedImage(profileImage)
    setUploadedImageUrl(URL.createObjectURL(profileImage))

    setCropImage(true)
  }

  function openFileExplorer(): void {
    inputRef?.current?.click()
  }

  async function handleUploadImage(): Promise<void> {
    setIsUploadingImage(true)
    let response
    if (selectedImageId === null) {
      response = await postNewProfilePicture(uploadedImage!)
    } else {
      response = await postDefaultProfilePicture(selectedImageId)
    }

    setIsUploadingImage(false)
    if (response.status === 200) {
      setProfileImageKey(response.imageKey!)
      setShowLoginModal(false)
      setUserIsLogged(true)
      if (closeModal !== undefined) closeModal()
      if (toPath === '') {
        setTimeout(navigate, 200, '/')
      } else if (toPath === 'none') {
        // pass
      } else if (toPath === '/memories/') {
        setTimeout(navigate, 200, `/memories/${username}`)
      } else {
        setTimeout(navigate, 200, toPath)
      }
      setToPath('')
    } else {
      showErrorSnack(PROFILE_PICTURE_ERROR)
    }
  }

  return (
    <div className="w-full overflow-hidden p-8">
      <input
        className="hidden"
        ref={inputRef}
        type="file"
        name="profilePicture"
        onChange={(event: ChangeEvent) => {
          handleImageChange(event)
        }}
      />
      <AnimatePresence mode="wait">
        {cropImage ? (
          <motion.div
            key="cropImage"
            initial={{ x: 500 }}
            animate={{ x: 0 }}
            exit={{ x: 500 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className="flex size-full flex-col items-center justify-between"
          >
            <CropImage
              resetImageData={() => {
                setCropImage(false)
                setUploadedImage(undefined)
                setUploadedImageUrl(undefined)
                setSelectedImageId(null)
                if (inputRef?.current != null) inputRef.current.value = ''
              }}
              openFileExplorer={openFileExplorer}
              setUploadedImageUrl={setUploadedImageUrl}
              setUploadedImage={setUploadedImage}
              setCropImage={setCropImage}
              uploadedImageUrl={uploadedImageUrl ?? ''}
            />
          </motion.div>
        ) : (
          <motion.div
            key="selectImage"
            initial={{ x: 0, opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ x: -500 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
          >
            <SelectImage
              defaultImages={defaultProfilePictures ?? []}
              isLoadingDefaultImages={isLoadingDefaultProfilePictures}
              selectedImageId={selectedImageId}
              uploadedImageUrl={uploadedImageUrl ?? ''}
              uploadedImage={uploadedImage}
              setSelectedImageId={setSelectedImageId}
              openFileExplorer={openFileExplorer}
              resetImageData={() => {
                setCropImage(false)
                setUploadedImage(undefined)
                setUploadedImageUrl(undefined)
                setSelectedImageId(null)
                if (inputRef?.current != null) inputRef.current.value = ''
              }}
              handleUploadImage={handleUploadImage}
              isUploadingImage={isUploadingImage}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
