import { useEffect, type ReactElement, useState } from 'react'
import { useParams } from 'react-router-dom'

import { EventBase } from '@/compositions/EventBase'

import { useGetTickets, usePostTicketsCheck } from '@/hooks/api/Tickets'
import { useAuthNavigate } from '@/hooks/useAuthNavigate'
import {
  usePixelTrackInitiateCheckout,
  usePixelTrackAddToCart,
} from '@/hooks/pixel'

import { useTicketsStore } from '@/store/tickets'
import { useEventStore } from '@/store/event'
import { useSnackbarStore } from '@/store/snackbar'
import { useGlobalStore } from '@/store/global'

import { TICKET_NOT_AVAILABLE, FETCH_EVENT_TICKETS_ERROR } from '@/errors'
import { isTruthy } from '@/helpers/validation'

import { EventTicketsMobile } from './EventTicketsMobile'
import { EventTicketsDesktop } from './EventTicketsDesktop'
import { ticketMapperAndSorter } from '@/helpers/ticketMapperAndSorter'
import { ticketsFilter } from '@/helpers/ticketsFilter'

interface EventTicketsProps {
  eventId?: number
  isPastEvent: boolean
}

export function EventTickets({
  eventId,
  isPastEvent,
}: EventTicketsProps): ReactElement {
  const [canSelectTickets, setCanSelectTickets] = useState(true)
  const [promo, setPromo] = useState('')

  const { eventAlias } = useParams()

  const { tickets, setTickets } = useTicketsStore()
  const { setTicketQuantity, integration } = useEventStore()
  const { showErrorSnack } = useSnackbarStore()
  const { isMobile } = useGlobalStore()
  const { showSuccessSnack } = useSnackbarStore()

  const { checkAvailability, isLoading: isLoadingCheck } = usePostTicketsCheck(
    eventId ?? 0,
    tickets,
  )
  const { ticketsData, error, isLoading } = useGetTickets(
    eventAlias ?? '',
    promo,
  )

  const { trackInitiateCheckout } = usePixelTrackInitiateCheckout()
  const { trackAddToCart } = usePixelTrackAddToCart()

  const { checkAuthNavigate } = useAuthNavigate()

  useEffect(() => {
    if (ticketsData !== undefined) {
      // Format input data so it has the same shape and format as the local storage tickets data
      const formattedTickesData = ticketMapperAndSorter(ticketsData)

      // Set tickets if there's nothing in the current store
      if (tickets.length === 0) {
        setTickets(ticketsData)
        return
      }

      // Update tickets store if fetched object is different from current store
      if (tickets.length !== formattedTickesData.length) {
        setTickets(ticketsData)
        return
      }

      // This is responsible for removing the quantity selected from the tickets object
      const filteredTickets = ticketsFilter(tickets)
      const filteredFormattedTickesData = ticketsFilter(formattedTickesData)

      // Update tickets store if fetched object is different from current store
      if (
        JSON.stringify(filteredTickets) !==
        JSON.stringify(filteredFormattedTickesData)
      ) {
        setTickets(ticketsData)
        return
      }
    }

    if (error !== undefined) {
      showErrorSnack(FETCH_EVENT_TICKETS_ERROR)
    }
  }, [ticketsData, error])

  async function handleGoToCheckout(): Promise<boolean> {
    setCanSelectTickets(false)
    const isAvailable = await checkAvailability()
    setCanSelectTickets(true)
    if (isAvailable) {
      if (isTruthy(integration?.pixelId)) {
        trackInitiateCheckout(integration?.pixelId ?? '')

        tickets.forEach((ticket) => {
          if (ticket.quantitySelected === 0) return
          const productTitle = isTruthy(ticket.batchDescription)
            ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
            : ticket.ticketSpecDescription

          trackAddToCart(
            integration?.pixelId ?? '',
            productTitle,
            ticket.price,
            ticket.quantitySelected,
            ticket.ticketSpecId,
          )
        })
      }
      setTimeout(checkAuthNavigate, 200, `/events/${eventAlias ?? ''}/checkout`)
      return true
    } else {
      showErrorSnack(TICKET_NOT_AVAILABLE)
      return false
    }
  }

  useEffect(() => {
    setTicketQuantity(0)
  }, [])

  useEffect(() => {
    if (isTruthy(promo)) {
      showSuccessSnack({
        title: 'Cupom aplicado!',
        description: `${promo}`,
      })
    }
  }, [promo])

  return (
    <EventBase.Root isMobile={isMobile}>
      {isMobile ? (
        <EventTicketsMobile
          canSelectTickets={canSelectTickets}
          handleGoToCheckout={handleGoToCheckout}
          isLoadingCheck={isLoadingCheck}
          isLoadingFetch={isLoading}
          promo={promo}
          setPromo={setPromo}
          isPastEvent={isPastEvent}
        />
      ) : (
        <EventTicketsDesktop
          canSelectTickets={canSelectTickets}
          handleGoToCheckout={handleGoToCheckout}
          isLoadingCheck={isLoadingCheck}
          isLoadingFetch={isLoading}
          promo={promo}
          setPromo={setPromo}
        />
      )}
    </EventBase.Root>
  )
}
