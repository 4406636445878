import { useEffect, useRef, useState, type ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'

import { SlArrowRight } from 'react-icons/sl'

import { EventBrief } from '@/components/Event/EventBrief'
import { OnFire } from '@/components/Global/OnFire'
import { EventHeart } from '@/components/Event/EventHeart'
import { TicketCounter } from '../TicketCounter'

import { usePutUserStatus } from '@/hooks/api/Event'

import { useGlobalStore } from '@/store/global'
import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'

import { isTruthy } from '@/helpers/validation'
import { isEventFinished } from '@/helpers/isEventFinished'
import { datesToFormat } from '@/helpers/formatDate'

interface EventCardProps {
  alias: string | undefined
  imageUrl: string
  startDate: string
  endDate: string
  location?: string
  title?: string
  lowestTicketPrice?: number
  variation: 'small' | 'medium' | 'big' | 'desktop'
  onFire?: boolean
  to?: string
  showInterest?: boolean
  isUserInsterested?: boolean
  ticketCount?: number
  isSticker?: boolean
}

export function EventCard({
  alias,
  imageUrl,
  startDate,
  endDate,
  location,
  title,
  variation,
  onFire = false,
  lowestTicketPrice,
  to = `/events/${alias ?? ''}`,
  showInterest = false,
  isUserInsterested = false,
  ticketCount = 0,
  isSticker = false,
}: EventCardProps): ReactElement {
  const variations = {
    parentDiv: {
      small:
        'p-1 border flex-row rounded-r-lg bg-transparent border-secondary-gray bg-dark-black gap-1',
      medium:
        'p-1 border flex-col rounded-b-lg border-secondary-gray bg-dark-black',
      big: 'p-4 border-2 flex-row rounded-b-lg items-center border-secondary-gray bg-dark-black',
      desktop: 'flex-col items-start size-full aspect-square flex',
    },
    imageDiv: {
      small: 'size-[68px] shrink-0',
      medium: 'relative w-40 h-40 mb-1',
      big: 'w-32 h-32 mr-2',
      desktop: 'size-full aspect-square relative flex justify-center',
    },
    image: {
      small: 'w-full rounded h-full object-cover',
      medium: 'w-full rounded-md h-full object-cover',
      big: 'w-full rounded-md h-full object-cover',
      desktop:
        'size-full rounded-md object-fit aspect-square max-h-full max-w-full',
    },
    briefDiv: {
      small:
        'bg-background-main w-full max-w-full flex items-center justify-between p-2 justify-center',
      medium: 'bg-background-main w-40 p-2',
      big: 'h-min p-2',
      desktop: 'h-min pt-3',
    },
    title: {
      small: 'max-w-full text-ellipsis line-clamp-1 text-sm text-white',
      medium: 'leading-4 text-white text-sm text-ellipsis line-clamp-1',
      big: 'text-2xl text-white font-bold leading-6',
      desktop: 'text-white text-md font-semibold leading-5',
    },
  }

  const [imageIsLoading, setImageIsLoading] = useState(true)

  const { userIsLogged } = useUserStore()
  const { setShowLoginModal } = useGlobalStore()
  const { setToPath } = useRoutingStore()

  const imageRef = useRef<HTMLImageElement>(null)

  const { putUserStatus } = usePutUserStatus(alias!)

  const [isUserInterestedLocal, setIsUserInterestedLocal] =
    useState(isUserInsterested)

  function handleInterest(): void {
    if (!userIsLogged) {
      setToPath('none')
      setShowLoginModal(true)
      return
    }

    setIsUserInterestedLocal(!isUserInterestedLocal)

    const newStatus = isUserInterestedLocal ? 'VIEWED' : 'INTERESTED'

    void putUserStatus(newStatus)
  }

  useEffect(() => {
    setIsUserInterestedLocal(isUserInsterested)
  }, [isUserInsterested])

  const isPastEvent = isEventFinished(endDate)
  const formattedEventDate = datesToFormat(startDate, endDate)

  return (
    <Link
      to={to}
      className={
        'relative flex size-full h-fit rounded-lg ' +
        variations.parentDiv[variation]
      }
    >
      <div className={variations.imageDiv[variation]}>
        <div className="flex items-center">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${imageUrl}`}
            alt="Event image"
            className={twMerge(
              variations.image[variation],
              isPastEvent && variation === 'desktop' && 'brightness-[40%]',
              imageIsLoading &&
                'size-full animate-pulse rounded-md bg-dark-black',
              isSticker && 'aspect-auto h-fit object-contain',
            )}
            ref={imageRef}
            onLoad={() => {
              setImageIsLoading(false)
            }}
          />
        </div>
        {isPastEvent && variation === 'desktop' && (
          <div className="absolute left-4 top-4 flex rounded-full border border-white">
            <span className="text-nowrap px-2 text-xs font-medium text-white">
              Encerrado
            </span>
          </div>
        )}
        {isTruthy(ticketCount) && (
          <div className="absolute bottom-2 right-2">
            <TicketCounter value={ticketCount} direction={0} variant="dark" />
          </div>
        )}
        {showInterest && (
          <div className="absolute bottom-4 right-4 z-10">
            <EventHeart
              isActive={isUserInterestedLocal}
              onClick={handleInterest}
              parentWidth={imageRef.current?.clientWidth ?? 0}
            />
          </div>
        )}
        {variation === 'medium' && onFire && (
          <div className="absolute right-2 top-2">
            <OnFire variant="simpleWithBorder" />
          </div>
        )}
      </div>
      <div
        className={twMerge(
          'min-w-0 flex-grow',
          variation === 'medium' && 'w-full max-w-40',
        )}
      >
        <div
          className={twMerge(
            'flex items-center justify-between gap-3 rounded bg-background-main p-2',
            variation === 'desktop' && 'desktop:px-0',
          )}
        >
          <div className="min-w-0">
            <EventBrief
              title={title}
              date={formattedEventDate}
              location={location}
              padding="0"
              variant="white"
              highlighted={variation !== 'big'}
              lowestTicketPrice={lowestTicketPrice}
              size="small"
            />
          </div>
          {isPastEvent && variation === 'small' && (
            <div className="flex w-[82px] min-w-[82px] items-center justify-center rounded-md pr-1">
              <div className="rounded-3xl border border-[#595959] px-2 py-0.5 text-xs font-medium leading-none text-[#595959]">
                Encerrado
              </div>
            </div>
          )}
          {variation === 'small' && !isPastEvent && (
            <div className="mr-1 flex h-full items-center gap-1">
              {onFire && <OnFire variant="simple" />}
              <SlArrowRight color="#7c7c7c" className="" size={12} />
            </div>
          )}
        </div>
      </div>
    </Link>
  )
}
