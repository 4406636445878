import { useState, type ReactElement, useRef, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'

import { SlArrowDown } from 'react-icons/sl'

import { useGlobalStore } from '@/store/global'

interface EventDescriptionProps {
  text: string | undefined
}

export function EventDescription({
  text,
}: EventDescriptionProps): ReactElement {
  const [isShort, setIsShort] = useState(true)

  const { isMobile } = useGlobalStore()

  const maxHeight = isMobile ? '120px' : '300px'

  const contentRef = useRef<HTMLInputElement>(null)

  const setMaxHeight = (): void => {
    if (contentRef.current != null) {
      const contentElement = contentRef.current
      const contentHeight = contentElement.scrollHeight
      contentElement.style.maxHeight = isShort
        ? maxHeight
        : `${contentHeight}px`
    }
  }

  useEffect(() => {
    setMaxHeight()
  }, [isShort])

  return (
    <div
      className={twMerge(
        'relative flex w-full flex-col transition-all duration-500 ease-in-out',
        isShort ? '' : 'pb-2',
      )}
    >
      <span
        className="font-sm overflow-hidden whitespace-pre-wrap break-words font-normal leading-5 text-text-main transition-[max-height] duration-500 ease-in-out"
        ref={contentRef}
      >
        {text}
      </span>
      {contentRef?.current?.clientHeight !== undefined &&
        contentRef?.current?.clientHeight >= 120 && (
          <>
            <button
              onClick={() => {
                setIsShort(!isShort)
              }}
              className="absolute -bottom-4 right-1/2 z-20 flex translate-x-1/2 items-center justify-center"
            >
              <SlArrowDown
                color="#fff"
                size={20}
                className={
                  'transition-transform duration-500 ease-in-out ' +
                  (isShort ? '' : '-rotate-180')
                }
              />
            </button>
            <div
              className={
                'absolute bottom-0 z-10 h-1/2 w-full bg-gradient-to-b from-transparent to-background-main transition-opacity duration-500 ease-linear ' +
                (isShort ? 'opacity-100' : ' opacity-0')
              }
            />
          </>
        )}
    </div>
  )
}
