import { useState, type ReactElement } from 'react'
import { Outlet } from 'react-router-dom'

import { LoginSignupModal } from '@/components/LoginSignup/LoginSignupModal'
import { MobileWrapper } from '@/components/Wrappers/MobileWrapper'
import { DesktopWrapper } from '@/components/Wrappers/DesktopWrapper'

import { NavBar } from '../components/NavBar'

import { useGlobalStore } from '@/store/global'
import { DownloadAppModal } from '@/components/Global/DownloadAppModal'

export function Layout(): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)

  const {
    isMobile,
    setShowLoginModal,
    showLoginModal,
    showDownloadAppModal,
    setShowDownloadAppModal,
  } = useGlobalStore()

  return (
    <>
      {isMobile ? (
        <MobileWrapper>
          <>
            <Outlet />
            <NavBar />
          </>
        </MobileWrapper>
      ) : (
        <DesktopWrapper>
          <Outlet />
        </DesktopWrapper>
      )}
      {showLoginModal && (
        <LoginSignupModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setShowLoginModal, 400, false)
            setTimeout(setIsParentClosing, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showDownloadAppModal && (
        <DownloadAppModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setShowDownloadAppModal, 400, false)
            setTimeout(setIsParentClosing, 400, false)
          }}
          isParentClosing={isParentClosing}
          text=""
        />
      )}
    </>
  )
}
