import { type ReactElement, useEffect } from 'react'

import { twMerge } from 'tailwind-merge'
import { AnimatePresence, motion } from 'framer-motion'

import { TicketSummary } from '@/components/Global/TicketSummary'
import { EventThumbnail } from '@/components/Event/EventThumbnail'
import { Button } from '@/components/Global/Button'

import { numberToReais } from '@/helpers/formatNumber'
import { datesToFormat } from '@/helpers/formatDate'

import { useSellStore } from '@/store/sell'
import { useNavbarStore } from '@/store/navbar'
import { useSnackbarStore } from '@/store/snackbar'

import { usePostCreateOrder } from '@/hooks/api/Order'

import { FiLock } from 'react-icons/fi'
import { AiOutlinePercentage } from 'react-icons/ai'
import { LuDollarSign } from 'react-icons/lu'

import { CREATE_MKT_ORDER_ERROR, MIN_SELL_PRICE } from '@/errors'

import { isTruthy } from '@/helpers/validation'

interface SelectSellTicketsProps {
  scrollToBottom: () => void
}

export function SelectSellTickets({
  scrollToBottom,
}: SelectSellTicketsProps): ReactElement {
  const {
    sellTickets,
    sellPrice,
    sellTicketsEvent,
    switchTicketSelectState,
    setSellPrice,
    setSellState,
    setSellLink,
  } = useSellStore()
  const { hideNavbar } = useNavbarStore()
  const { showErrorSnack } = useSnackbarStore()

  const { imageKey, title, startDate, location, endDate } = sellTicketsEvent
  const { postCreateOrder, isLoading } = usePostCreateOrder()

  async function handleGenerateLink(): Promise<void> {
    if (sellPrice < 1) {
      showErrorSnack(MIN_SELL_PRICE)
      return
    }
    const ticketIds = sellTickets
      .filter((ticket) => ticket.isSelected)
      .map((ticket) => ticket.id)
    const createResponse = await postCreateOrder(
      sellTicketsEvent.id,
      sellPrice,
      ticketIds,
    )
    if (createResponse.status === 200) {
      const link = createResponse.code
      setSellLink(link)
      setSellState('shareLink')
    } else {
      showErrorSnack(CREATE_MKT_ORDER_ERROR)
    }
  }

  useEffect(() => {
    hideNavbar()
  }, [])

  return (
    <div className="mb-20">
      <EventThumbnail
        imageKey={imageKey}
        title={title}
        date={datesToFormat(startDate, endDate)}
        location={location}
        briefVariant="white"
        imageVariant="medium"
        highlighted={false}
      />
      <h1 className="my-4 text-white">Qual ingresso quer vender?</h1>
      <div className="flex flex-col gap-2">
        {sellTickets?.map((ticket, index) => {
          const title = isTruthy(ticket.batchDescription)
            ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
            : ticket.ticketSpecDescription

          return (
            <div
              key={index}
              className="flex max-w-full items-center justify-between"
            >
              <div
                className={
                  !isTruthy(ticket.orderCode)
                    ? 'w-[calc(100%-40px)]'
                    : 'w-[calc(100%-105px)]'
                }
              >
                <TicketSummary
                  quantity={1}
                  title={title}
                  subtitle={'Valor pago: ' + numberToReais(ticket.price, 2)}
                  variant="mix"
                  counterVariant="dark"
                />
              </div>
              {ticket.validated ? (
                <div className="flex items-center justify-center gap-4 rounded-lg border border-background-main bg-white px-2 py-0.5">
                  <span className="line-clamp-none w-max text-2xs font-black">
                    VALIDADO
                  </span>
                </div>
              ) : !isTruthy(ticket.orderCode) ? (
                <div
                  className="transition-color flex size-6 items-center justify-center  rounded-lg border-2 border-dark-light-gray duration-100 ease-in-out hover:cursor-pointer"
                  onClick={() => {
                    if (!ticket.isSelected) setTimeout(scrollToBottom, 100)
                    setSellPrice(ticket.price)
                    switchTicketSelectState(ticket.id)
                  }}
                >
                  <div
                    className={twMerge(
                      'size-4 rounded-[4px]',
                      ticket.isSelected ? 'bg-primary-main' : 'bg-transparent',
                    )}
                  />
                </div>
              ) : (
                <div className="rounded-md bg-primary-main px-2 py-0.5">
                  <span className="line-clamp-none w-max text-2xs font-black">
                    ANUNCIADO
                  </span>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <AnimatePresence>
        {sellTickets.some((ticket) => ticket.isSelected) && (
          <motion.div
            key="sellPrice"
            className="mt-8"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <div className="mt-2 flex justify-between rounded-lg border-2 border-primary-main bg-transparent">
              <div className=" flex w-4/5 flex-col p-2">
                <span className="text-2xs font-bold text-white">
                  Quanto você quer receber pela venda?
                </span>
                <input
                  type="text"
                  inputMode="numeric"
                  className="w-full bg-transparent font-bold text-white focus:border-0 focus:outline-none"
                  onChange={(e) => {
                    const value = e.target.value
                    const cleanedValue = value
                      .replace(/[^0-9]/g, '')
                      .replace(/^0+/, '')
                    const number = Number(cleanedValue)
                    const finalNumber = number / 100
                    setSellPrice(finalNumber)
                  }}
                  value={numberToReais(sellPrice, 2)}
                />
              </div>
            </div>
            <div className="mt-4 flex flex-col gap-2">
              <div className="flex h-10 items-center justify-between gap-5">
                <div className="flex size-8 items-center justify-center rounded-full bg-dark-white">
                  <FiLock size={16} />
                </div>
                <span className="w-[90%] text-sm text-white">
                  Transação segura. Sem risco de golpe. Cuidamos do processo
                  para você.
                </span>
              </div>

              <div className="flex h-10 items-center justify-between gap-5">
                <div className="flex size-8 items-center justify-center rounded-full bg-dark-white">
                  <AiOutlinePercentage size={16} />
                </div>
                <span className="w-[90%] text-sm text-white">
                  Cobramos 10% a mais do comprador para oferecer opções de
                  pagamento e segurança.
                </span>
              </div>

              <div className="flex h-10 items-center justify-between gap-5">
                <div className="flex size-8 items-center justify-center rounded-full bg-dark-white">
                  <LuDollarSign size={16} />
                </div>
                <span className="w-[90%] text-sm text-white">
                  O valor da revenda ficará disponível na carteira no aplicativo
                  para saque em até 7 dias.
                </span>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <div className="fixed bottom-0 left-0 z-10 w-full bg-dark-black p-4">
        <div className="h-12 w-full">
          <Button
            text="Gerar link"
            enabled={
              sellTickets?.some((ticket) => ticket.isSelected) &&
              sellPrice > 0 &&
              !isLoading
            }
            onClick={() => {
              void handleGenerateLink()
            }}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  )
}
