import { useState, type ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { OrganizationCard } from '@/components/Organization/OrganizationCard'
import { EventCard } from '@/components/Global/EventCard'
import { Loading } from '@/components/Global/Loading'

import { useNavbarStore } from '@/store/navbar'

import {
  type IOrganization,
  type IEvent,
} from '@/types/hooks/api/useOrganization'

import { BsArrowLeftShort } from 'react-icons/bs'

import { isEventFinished } from '@/helpers/isEventFinished'

interface OrganizationMobileProps {
  organization: IOrganization | undefined
  refresh: () => void
  isLoading: boolean
}

export function OrganizationMobile({
  organization,
  refresh,
  isLoading,
}: OrganizationMobileProps): ReactElement {
  const [pastEvents, setPastEvents] = useState<IEvent[]>([])
  const [futureEvents, setFutureEvents] = useState<IEvent[]>([])

  const { showNavbar } = useNavbarStore()

  const navigate = useNavigate()

  useEffect(() => {
    const tempPastEvents: IEvent[] = []
    const tempFutureEvents: IEvent[] = []

    organization?.events
      .sort((eventA, eventB) =>
        new Date(eventA.startDate) < new Date(eventB.startDate) ? -1 : 1,
      )
      .forEach((event) => {
        if (isEventFinished(event.endDate)) {
          tempPastEvents.push(event)
        } else {
          tempFutureEvents.push(event)
        }
      })

    setPastEvents(tempPastEvents)
    setFutureEvents(tempFutureEvents)
  }, [organization])

  useEffect(() => {
    showNavbar()
  }, [])

  if (isLoading) return <Loading />
  return (
    <div className="flex min-h-full flex-col gap-4 bg-dark-black p-4 pb-16">
      <div className="flex gap-4">
        <button
          className="rounded-full bg-dark-white p-0.5"
          onClick={() => {
            navigate(-1)
          }}
        >
          <BsArrowLeftShort size={32} color="#232323" />
        </button>
        <h1 className="text-2xl text-white">Organização</h1>
      </div>
      <OrganizationCard organizationData={organization} refresh={refresh} />
      <div className="flex flex-col gap-4 pt-4">
        {futureEvents.length > 0 && (
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl text-white">Próximos eventos</h1>
            <div className="flex flex-col gap-1.5 pb-4">
              {futureEvents.map((event, index) => {
                return (
                  <EventCard
                    alias={event.alias}
                    key={index}
                    imageUrl={event.imageKey}
                    title={event.name}
                    startDate={event.startDate}
                    location={event.locationName ?? 'Local a definir'}
                    variation="small"
                    onFire={event.onFire}
                    endDate={event.endDate}
                  />
                )
              })}
            </div>
          </div>
        )}
        {pastEvents.length > 0 && (
          <div className="flex flex-col gap-2">
            <h1 className="text-2xl text-white">Eventos passados</h1>

            <div className="flex flex-col gap-1.5 pb-4">
              {pastEvents.map((event, index) => {
                return (
                  <EventCard
                    alias={event.alias}
                    key={index}
                    imageUrl={event.imageKey}
                    title={event.name}
                    startDate={event.startDate}
                    location={event.locationName ?? 'Local a definir'}
                    variation="small"
                    endDate={event.endDate}
                  />
                )
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
