import { zodResolver } from '@hookform/resolvers/zod'
import React, {
  useEffect,
  type FormHTMLAttributes,
  type ReactElement,
} from 'react'
import { useForm } from 'react-hook-form'
import { type z, type ZodType } from 'zod'

interface FormProps<T> extends FormHTMLAttributes<HTMLFormElement> {
  formSchema: ZodType
  stateChange?: boolean
  resetObject?: Record<string, string>
  defaultValues?: Record<string, string | number>
  children: Array<ReactElement<{ name: string }>>
  handleModalClose?: (data: T) => void
}

export function Form<T>({
  formSchema,
  children,
  handleModalClose,
  defaultValues,
  resetObject,
  stateChange,
  ...props
}: FormProps<T>): ReactElement {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues,
  })

  function handleFormSubmit(data: T): void {
    if (handleModalClose !== undefined) {
      handleModalClose(data)
    }
  }

  useEffect(() => {
    if (stateChange === true) reset(resetObject)
  }, [reset, stateChange])

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} {...props}>
      {register !== undefined ? (
        children.map((child, index) => {
          if (child.type === 'button' || child.type === 'div') {
            return React.Children.only(child)
          } else if (child.type === React.Fragment) {
            return null
          } else {
            return React.createElement(child.type, {
              ...{
                ...child.props,
                register,
                error: errors[child.props.name]?.message,
                key: index,
              },
            })
          }
        })
      ) : (
        <div></div>
      )}
    </form>
  )
}
