export function numberToReais(number: number, decimals = 0): string {
  const numberTyped = Number(number)
  const formattedNumber = numberTyped.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: decimals,
  })

  return formattedNumber
}

export function formatNumber(number: number): string {
  return new Intl.NumberFormat('pt-BR').format(number)
}
