import { useEffect, type ReactElement } from 'react'

// Styling
import './index.css'

// Routing
import { Route, useLocation, Routes, BrowserRouter } from 'react-router-dom'

// Pages
import { Layout } from './layouts'
import { Home } from './pages/Home'
import { Event } from './pages/Event'
import { LoginSignup } from './pages/LoginSignup'
import { Wallet } from './pages/Wallet'
import { Marketplace } from './pages/Marketplace'
import { Memories } from './pages/Memories'
import { Search } from './pages/Search'
import { PageNotFound } from './pages/PageNotFound'
import { Notifications } from './pages/Notifications'
import { Link } from './pages/Link'

// Components
import { ProtectedRoute } from './components/Global/ProtectedRoute'
import { Organization } from './pages/Organization'

// Hooks
import { useGetPing } from './hooks/api/Auth'
import { useGetFollowRequests } from './hooks/api/Notifications'

// Store
import { useUserStore } from './store/user'
import { useRoutingStore } from './store/routing'
import { useGlobalStore } from './store/global'

// Scroll to top on route change
function ScrollToTop(): ReactElement {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return <></>
}

function App(): ReactElement {
  const { loginUser, logoutUser, setUserIsLogged, setFollowRequests } =
    useUserStore()
  const { setIsPinging } = useRoutingStore()
  const { setIsMobile } = useGlobalStore()

  const { ping } = useGetPing()
  const { getFollowRequests } = useGetFollowRequests()

  async function handlePing(): Promise<void> {
    const pong = await ping()

    if (pong.status === 200 && pong.user !== undefined) {
      loginUser(pong.user)
      setUserIsLogged(true)

      const response = await getFollowRequests()
      if (response.status === 200 && response.followRequests !== undefined)
        setFollowRequests(response.followRequests.length)
    } else {
      logoutUser()
      setUserIsLogged(false)
    }
    setIsPinging(false)
  }

  useEffect(() => {
    void handlePing()
  }, [])

  useEffect(() => {
    setIsMobile(window.innerWidth <= 600)
    const handleResize = (): void => {
      setIsMobile(window.innerWidth <= 600)
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="/events/:eventAlias/*" element={<Event />} />
          <Route path="/close-friends/:eventAlias/*" element={<Event />} />
          <Route path="/login" element={<LoginSignup />} />
          <Route path="/search" element={<Search />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/memories/:username" element={<Memories />} />
          <Route path="/marketplace/*" element={<Marketplace />} />
          <Route
            path="/organizations/:organizationAlias"
            element={<Organization />}
          />
          <Route path="/links/:eventAlias/:hash" element={<Link />} />

          <Route element={<ProtectedRoute />}>
            <Route path="/wallet/*" element={<Wallet />} />
          </Route>

          <Route path="/*" element={<PageNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
