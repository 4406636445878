import { useState } from 'react'
import { api } from '@/services/axios'

import {
  type CreateOrderDetailsResponse,
  type CreateOrderDetailsReturn,
} from '@/types/hooks/api/useOrder'
import { type AxiosError, type AxiosResponse } from 'axios'

export const usePostCreateOrder = (): {
  isLoading: boolean
  postCreateOrder: (
    eventId: number,
    price: number,
    tickets: number[],
  ) => Promise<CreateOrderDetailsReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postCreateOrder(
    eventId: number,
    price: number,
    tickets: number[],
  ): Promise<CreateOrderDetailsReturn> {
    setIsLoading(true)

    const requestBody = {
      eventId,
      price,
      ticketIds: tickets,
    }

    try {
      const response: AxiosResponse<CreateOrderDetailsResponse> =
        await api.post(`/orders`, requestBody)
      const code = response.data.code
      setIsLoading(false)
      return {
        status: 200,
        code,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
        code: '',
      }
    }
  }

  return { postCreateOrder, isLoading }
}
