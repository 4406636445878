import { Modal } from '@/components/Global/Modal'
import { useGetEvents } from '@/hooks/api/Social'
import { type ReactElement } from 'react'
import { Spinner } from '@/components/Global/Spinner'
import { categorizeEventsByDate } from '@/helpers/categorizeEvent'
import { EventCard } from '@/components/Global/EventCard'

interface ShowEventsModalProps {
  closeModal: () => void
  isParentClosing?: boolean
  userId: number
  username: string
}

export function ShowEventsModal({
  closeModal,
  isParentClosing,
  userId,
  username,
}: ShowEventsModalProps): ReactElement {
  const { events, isLoading } = useGetEvents(userId)
  const { pastEvents, futureEvents } = categorizeEventsByDate(events ?? [])

  return (
    <Modal
      closeModal={closeModal}
      isParentClosing={isParentClosing}
      variation="dark"
    >
      <div className="relative mt-6 flex w-full flex-col gap-4 px-4 desktop:mt-0 desktop:p-4">
        <h1 className="font-matter text-2xl font-bold text-dark-light-gray">
          Eventos de @{username}
        </h1>

        {isLoading || events === undefined ? (
          <div className="flex w-full items-center justify-center">
            <Spinner
              borderWidth="border-4"
              borderColor="border-[#232323]/50"
              bottomBorderColor="border-b-[#232323]"
            />
          </div>
        ) : (
          <div className="relative">
            <div className="pointer-events-none absolute top-0 z-10 h-4 w-full bg-gradient-to-t from-transparent to-background-main" />
            <div className="flex max-h-[432px] w-full flex-col gap-4 overflow-y-scroll py-4">
              {futureEvents.length > 0 && (
                <div className="flex w-full flex-col gap-3">
                  <h2 className="font-matter font-medium text-white">
                    Próximos eventos
                  </h2>
                  {futureEvents.map((event) => (
                    <EventCard
                      key={event.alias}
                      alias={event.alias}
                      endDate={event.endDate}
                      imageUrl={event.imageKey}
                      startDate={event.startDate}
                      location={event.location?.name}
                      title={event.name}
                      variation="small"
                      to={`/events/${event.alias}`}
                    />
                  ))}
                </div>
              )}
              {pastEvents.length > 0 && (
                <div className="flex w-full flex-col gap-3">
                  <h2 className="font-matter font-medium text-white">
                    Eventos passados
                  </h2>
                  {pastEvents.map((event) => (
                    <EventCard
                      key={event.alias}
                      alias={event.alias}
                      endDate={event.endDate}
                      imageUrl={event.imageKey}
                      startDate={event.startDate}
                      location={event.location?.name}
                      title={event.name}
                      variation="small"
                      to={`/events/${event.alias}`}
                    />
                  ))}
                </div>
              )}
              {pastEvents.length <= 0 && futureEvents.length <= 0 && (
                <div className="flex w-full items-center justify-center">
                  <span className="text-center text-sm text-text-secondary">
                    Nenhum evento encontrado
                  </span>
                </div>
              )}
            </div>
            <div className="pointer-events-none absolute bottom-0 z-10 h-4 w-full bg-gradient-to-b from-transparent to-background-main" />
          </div>
        )}
      </div>
    </Modal>
  )
}
