import { type ReactElement } from 'react'
import { motion } from 'framer-motion'

import { Button } from '@/components/Global/Button'

import { useUserStore } from '@/store/user'
import { useInterestStore } from '@/store/interest'
import { useRoutingStore } from '@/store/routing'
import { useSnackbarStore } from '@/store/snackbar'
import { useGlobalStore } from '@/store/global'

import { type IEventInterestUserData } from '@/types/Event'

import {
  useDeleteUnfollow,
  usePostCreateFollowRequest,
  usePutCancelFollowRequest,
} from '@/hooks/api/Social'

import {
  ERROR_TO_CREATE_FOLLOW,
  ERROR_TO_CANCEL_FOLLOW,
  ERROR_TO_UNFOLLOW,
} from '@/errors'

interface InterestUserProps {
  user: IEventInterestUserData
}

export function InterestUser({ user }: InterestUserProps): ReactElement {
  const handList = ['👍', '🤟', '🤘', '🤙']
  const randomHand = handList[Math.floor(Math.random() * handList.length)]

  const { id, userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { changeFollowStatus, closeModal } = useInterestStore()
  const { showErrorSnack } = useSnackbarStore()
  const { setShowLoginModal } = useGlobalStore()

  const { postCreateFollowRequest, isLoading: isLoadingCreateFollowRequest } =
    usePostCreateFollowRequest()
  const { putCancelFollowRequest, isLoading: isLoadingCancelFollowRequest } =
    usePutCancelFollowRequest()
  const { deleteUnfollow, isLoading: isLoadingDeleteUnfollow } =
    useDeleteUnfollow()

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      if (closeModal !== undefined) closeModal()
      setToPath('none')
      setTimeout(setShowLoginModal, 500, true)
      return
    }
    const response = await postCreateFollowRequest(user.id)

    if (response.status === 200) {
      changeFollowStatus(user.id, {
        status: 'PENDING',
        id: response.followRequestId!,
      })
    } else {
      showErrorSnack(ERROR_TO_CREATE_FOLLOW)
    }
  }

  async function handleCancelRequest(): Promise<void> {
    const response = await putCancelFollowRequest(user.followRequest!.id!)

    if (response.status === 200) {
      changeFollowStatus(user.id, { status: 'NOT_FOLLOWING', id: undefined })
    } else {
      showErrorSnack(ERROR_TO_CANCEL_FOLLOW)
    }
  }

  async function handleUnfollow(): Promise<void> {
    const response = await deleteUnfollow(user.id)

    if (response.status === 200) {
      changeFollowStatus(user.id, { status: 'NOT_FOLLOWING', id: undefined })
    } else {
      showErrorSnack(ERROR_TO_UNFOLLOW)
    }
  }

  return (
    <motion.div
      className="flex w-full items-center justify-between gap-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.25, ease: 'easeInOut' }}
    >
      <div className="flex items-center gap-2">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${user.profileImageKey}`}
          alt="Usuário"
          className="size-12 rounded-full"
        />
        <div className="flex flex-col items-start">
          <span className="line-clamp-1 font-medium">
            {user.firstName} {user.lastName}
          </span>
          {user.hasTicket && (
            <span className="rounded-full bg-[#EFEFEF] px-2 text-2xs">{`${randomHand} Já comprei`}</span>
          )}
        </div>
      </div>
      {user.id === id ? (
        <></>
      ) : user.followRequest === undefined ||
        user.followRequest.status === 'NOT_FOLLOWING' ? (
        <div>
          <Button
            className="h-6 w-20 bg-primary-main py-1 text-center text-xs font-semibold disabled:bg-dark-light-gray"
            enabled={!isLoadingCreateFollowRequest}
            isLoading={isLoadingCreateFollowRequest}
            text="Seguir"
            onClick={() => {
              void handleFollow()
            }}
            customSpinnerHeight="h-4"
            customSpinnerWidth="w-4"
          />
        </div>
      ) : (
        <div>
          {user.followRequest.status === 'FOLLOWING' && (
            <Button
              className="h-6 w-20 bg-[#D9D9D9] py-1 text-center text-xs font-semibold"
              enabled={!isLoadingDeleteUnfollow}
              isLoading={isLoadingDeleteUnfollow}
              text="Seguindo"
              onClick={() => {
                void handleUnfollow()
              }}
              customSpinnerHeight="h-4"
              customSpinnerWidth="w-4"
            />
          )}
          {user.followRequest.status === 'PENDING' && (
            <Button
              className="h-6 w-20 bg-[#D9D9D9] py-1 text-center text-xs font-semibold"
              enabled={!isLoadingCancelFollowRequest}
              isLoading={isLoadingCancelFollowRequest}
              text="Solicitado"
              onClick={() => {
                void handleCancelRequest()
              }}
              customSpinnerHeight="h-4"
              customSpinnerWidth="w-4"
            />
          )}
        </div>
      )}
    </motion.div>
  )
}
