import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Spinner } from '@/components/Global/Spinner'

import { SEND_OTP_ERROR } from '@/errors'

import { usePostRequestPhoneOTP } from '@/hooks/api/Auth'

import { AiOutlineWhatsApp } from 'react-icons/ai'
import { IoPhonePortraitOutline } from 'react-icons/io5'
import { useSnackbarStore } from '@/store/snackbar'

export interface CommunicationMethodProps {
  phoneNumber?: string
  setState: (
    state:
      | 'login'
      | 'twoFactorPhone'
      | 'signup'
      | 'profilePicture'
      | 'communicationMethod',
  ) => void
  setCommunicationMethod: (m: 'SMS' | 'WHATSAPP') => void
}

export function CommunicationMethod({
  phoneNumber,
  setState,
  setCommunicationMethod,
}: CommunicationMethodProps): ReactElement {
  const { postRequestPhoneOtp, isLoading } = usePostRequestPhoneOTP()

  const { showErrorSnack } = useSnackbarStore()

  async function handleRequestOTP(
    communicationMethod: 'SMS' | 'WHATSAPP',
  ): Promise<void> {
    setCommunicationMethod(communicationMethod)

    const loginResponse = await postRequestPhoneOtp(
      phoneNumber!,
      communicationMethod,
    )

    if (!loginResponse) {
      showErrorSnack(SEND_OTP_ERROR)
      return
    }

    setState('twoFactorPhone')
  }

  return (
    <div className="mt-5 flex w-full flex-col items-center justify-between gap-8 overflow-hidden p-8 pt-4">
      <h1 className="w-full text-4xl text-text-dark">código de validação</h1>

      <span className="text-center text-sm font-normal">
        Para validar seu celular iremos te enviar um código. Selecione abaixo
        como deseja receber:
      </span>

      <AnimatePresence mode="wait">
        {isLoading ? (
          <motion.div
            className="flex h-[100px] w-full items-center justify-center"
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'anticipate' }}
          >
            <Spinner
              borderWidth="border-[6px]"
              borderColor="border-[#232323]/50"
              bottomBorderColor="border-b-[#232323]"
              width="w-12"
              height="h-12"
            />
          </motion.div>
        ) : (
          <motion.div
            className="flex gap-8"
            key="buttons"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.1, ease: 'anticipate' }}
          >
            <button
              className="flex size-[100px] flex-col items-center justify-center gap-0 rounded-md bg-[#E8E8E8]"
              onClick={() => {
                void handleRequestOTP('WHATSAPP')
              }}
            >
              <AiOutlineWhatsApp size={64} />
              <span className="text-sm font-medium">WhatsApp</span>
            </button>
            <button
              className="flex size-[100px] flex-col items-center justify-center gap-1 rounded-md bg-[#E8E8E8] pt-1"
              onClick={() => {
                void handleRequestOTP('SMS')
              }}
            >
              <IoPhonePortraitOutline size={56} />
              <span className="text-sm font-medium">SMS</span>
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
