import { type ReactElement, useState, useEffect, useRef } from 'react'

import { SlArrowDown } from 'react-icons/sl'

interface EventTermsProps {
  terms: string | undefined
}

export function EventTerms({ terms }: EventTermsProps): ReactElement {
  const [isShort, setIsShort] = useState(true)

  const contentRef = useRef<HTMLInputElement>(null)

  const setMaxHeight = (): void => {
    if (contentRef.current != null) {
      const contentElement = contentRef.current
      const contentHeight = contentElement.scrollHeight
      contentElement.style.maxHeight = isShort ? '50px' : `${contentHeight}px`
    }
  }

  useEffect(() => {
    setMaxHeight()
  }, [isShort])

  return (
    <div
      className={`relative flex w-full flex-col items-start rounded-lg bg-dark-black p-4`}
    >
      <span className="mb-1 w-full text-sm font-bold text-text-terciary">
        Termos do evento
      </span>
      <div
        className="relative overflow-hidden leading-3 transition-[max-height] duration-500 ease-in-out"
        ref={contentRef}
      >
        <span
          ref={contentRef}
          className="overflow-hidden whitespace-pre-wrap break-words text-xs text-text-secondary"
        >
          {terms}
        </span>
        {contentRef?.current?.clientHeight !== undefined &&
          contentRef?.current?.clientHeight >= 50 && (
            <div
              className={
                'absolute bottom-0 z-10 h-10 w-full bg-gradient-to-b from-transparent to-dark-black transition-opacity duration-500 ease-linear ' +
                (isShort ? 'opacity-100' : ' opacity-0')
              }
            />
          )}
      </div>
      {contentRef?.current?.clientHeight !== undefined &&
        contentRef?.current?.clientHeight >= 50 && (
          <div
            className="mt-2 flex w-[87px] items-center justify-between"
            onClick={() => {
              setIsShort(!isShort)
            }}
          >
            <span className="text-sm font-bold text-white transition-opacity duration-300 ease-out">
              {isShort ? 'Ver mais' : 'Ver menos'}
            </span>
            <SlArrowDown
              color="white"
              size={12}
              className={
                'transition-transform duration-500 ease-in-out ' +
                (isShort ? '' : '-rotate-180')
              }
            />
          </div>
        )}
    </div>
  )
}
