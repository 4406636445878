import { api } from '@/services/axios'
import { useState } from 'react'

export const usePostRequestPhoneOTP = (): {
  postRequestPhoneOtp: (
    phoneNumber: string,
    communicationMethod: 'SMS' | 'WHATSAPP',
  ) => Promise<boolean>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postRequestPhoneOtp(
    phoneNumber: string,
    communicationMethod: 'SMS' | 'WHATSAPP',
  ): Promise<boolean> {
    setIsLoading(true)

    const requestBody = {
      phoneNumber,
      communicationMethod,
    }

    try {
      await api.post(`/auth/request-otp`, requestBody)
      setIsLoading(false)
      return true
    } catch (err) {
      setIsLoading(false)
      return false
    }
  }

  return { postRequestPhoneOtp, isLoading }
}
