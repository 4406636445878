import { type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

interface AvatarProps {
  avatarImageKey: string
  firstName: string
  lastName: string
  isSelected?: boolean
  onClick: () => void
}

export function Avatar({
  avatarImageKey,
  firstName,
  lastName,
  isSelected = false,
  onClick,
}: AvatarProps): ReactElement {
  return (
    <div
      className="flex w-fit flex-col items-center hover:cursor-pointer"
      onClick={() => {
        onClick()
      }}
    >
      <img
        className={twMerge(
          'mb-1.5 aspect-square h-16 w-16 rounded-full border-2 transition-colors duration-100 ease-in-out',
          isSelected ? 'border-primary-main' : 'border-background-main',
        )}
        src={`${import.meta.env.VITE_S3 as string}/${avatarImageKey}`}
        alt={'User'}
      />
      <span className="max-w-[64px] truncate text-xs leading-3 text-white">
        {firstName}
      </span>
      <span className="max-w-[64px] truncate text-xs text-white">
        {lastName}
      </span>
    </div>
  )
}
