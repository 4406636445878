import { useEffect, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { Button } from '@/components/Global/Button'
import { InterestUser } from '../../InterestUser'
import { Spinner } from '@/components/Global/Spinner'

import { useUserStore } from '@/store/user'
import { useInterestStore } from '@/store/interest'

import { usePutUserStatus } from '@/hooks/api/Event'

interface InterestViewsGoingProps {
  setPageHeight: (h: number) => void
  newPage: () => void
  noNewUsers: boolean
}

export function InterestViewsGoing({
  setPageHeight,
  newPage,
  noNewUsers,
}: InterestViewsGoingProps): ReactElement {
  const { id } = useUserStore()
  const { going, refreshCurrentUserData, refreshData } = useInterestStore()

  const { eventAlias } = useParams()

  const { putUserStatus, isLoading } = usePutUserStatus(eventAlias!)

  async function handleUserInterest(): Promise<void> {
    await putUserStatus('GOING')

    if (refreshCurrentUserData !== undefined) refreshCurrentUserData()
    if (refreshData !== undefined) refreshData()
  }

  // Set page height
  useEffect(() => {
    if (going.users.length === 0) {
      setPageHeight(360)
    } else {
      const height = Math.min(506, 160 + going.total * 53)
      setPageHeight(height)
    }
  }, [going])

  // Infinite scroll handler
  useEffect(() => {
    const intersectionObserver = new IntersectionObserver((entries) => {
      if (entries.some((entry) => entry.isIntersecting)) {
        newPage()
      }
    })

    const loadSentry = document.querySelector('#loadSentryGoingView')
    if (loadSentry !== null) intersectionObserver.observe(loadSentry)

    return () => {
      intersectionObserver.disconnect()
    }
  }, [isLoading])

  if (going.users.length === 0)
    return (
      <div className="flex size-full flex-col items-center justify-evenly gap-4 ">
        <div className="flex flex-col gap-6">
          <span className="text-center font-medium leading-4">
            Ninguém confirmou presença <br /> (ainda) neste evento 👀
          </span>
          <span className="text-center font-medium leading-4">
            Tá esperando o quê para <br /> ser o primeiro?
          </span>
        </div>

        <div className="h-11 w-2/3">
          <Button
            enabled={!isLoading}
            isLoading={isLoading}
            text="Confirmar presença"
            className="text-sm"
            onClick={handleUserInterest}
            customSpinnerHeight="h-6"
            customSpinnerWidth="w-6"
          />
        </div>
      </div>
    )
  return (
    <div className="relative size-full max-h-[400px]">
      <div className="flex size-full flex-col items-center gap-2.5 overflow-scroll py-4">
        {going.users
          .sort((user) => (user.id === id ? -1 : 1))
          .map((user) => {
            return <InterestUser key={user.id} user={user} />
          })}
        {!noNewUsers && (
          <div
            id="loadSentryGoingView"
            className="my-4 flex w-full items-center justify-center gap-4 "
          >
            <Spinner
              borderWidth="border-4"
              borderColor="border-dark-black/50"
              bottomBorderColor="border-b-dark-black"
            />
          </div>
        )}
      </div>

      <div className="absolute left-0 top-0 h-6 w-full bg-gradient-to-b from-white to-transparent" />
      <div className="absolute bottom-0 left-0 h-6 w-full bg-gradient-to-t from-white to-transparent" />
    </div>
  )
}
