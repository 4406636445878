import { useEffect, useState, type ReactElement } from 'react'
import { motion } from 'framer-motion'

import { TicketSelector } from '@/components/Global/TicketSelector'

import { EventBase } from '@/compositions/EventBase'

import { isTruthy } from '@/helpers/validation'

import { useTicketsStore } from '@/store/tickets'

interface EventTicketsMobileProps {
  isLoadingFetch: boolean
  isLoadingCheck: boolean
  canSelectTickets: boolean
  handleGoToCheckout: () => Promise<boolean>
  promo: string
  setPromo: (promo: string) => void
  isPastEvent: boolean
}

export function EventTicketsMobile({
  isLoadingFetch,
  isLoadingCheck,
  canSelectTickets,
  handleGoToCheckout,
  promo,
  setPromo,
  isPastEvent,
}: EventTicketsMobileProps): ReactElement {
  const [total, setTotal] = useState(0)

  const {
    tickets,
    totalHasFee,
    addTicket,
    removeTicket,
    canAdd,
    canRemove,
    getTotal,
  } = useTicketsStore()

  const hasTicketSelected = tickets.some((t) => t.quantitySelected > 0)

  useEffect(() => {
    setTotal(getTotal())
  }, [tickets])

  const footerTitle = hasTicketSelected
    ? 'Valor total'
    : 'Selecione seus ingressos'
  const footerValue = hasTicketSelected ? total : 0
  const footerSubtitle = totalHasFee() ? '+ taxa' : ''
  const footerButtonText =
    hasTicketSelected && total === 0 ? 'Reservar agora' : 'Comprar agora'
  const footerButtonEnabled =
    hasTicketSelected && !isLoadingCheck && !isLoadingFetch

  return (
    <>
      <motion.div
        className="flex size-full flex-col items-center justify-center"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeOut' }}
      >
        {isLoadingFetch ? (
          <div className="flex w-full flex-col items-center justify-center gap-2 p-4">
            <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
            <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
            <div className="h-[100px] w-full animate-pulse rounded-md bg-dark-black" />
          </div>
        ) : (
          <div className="flex w-full max-w-[500px] flex-col gap-4">
            <div className="flex w-full flex-col gap-6 p-4 pb-6 pt-0 desktop:rounded-md desktop:bg-background-main desktop:pb-4">
              <div className="relative">
                <div className="absolute top-0 z-10 h-4 w-full bg-gradient-to-b from-background-main to-transparent" />
                <div className="absolute bottom-0 z-10 h-4 w-full bg-gradient-to-t from-background-main to-transparent" />
                <div className="flex flex-col gap-2 py-4 desktop:max-h-[400px] desktop:overflow-scroll">
                  {tickets?.map((ticket) => {
                    const title = isTruthy(ticket.batchDescription)
                      ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                      : ticket.ticketSpecDescription
                    return (
                      <TicketSelector
                        key={String(ticket.ticketSpecId) + ticket.promo}
                        title={title}
                        price={ticket.price}
                        promo={ticket.promo}
                        hasFee={ticket.hasFee}
                        quantitySelected={ticket.quantitySelected}
                        finalPrice={ticket.finalPrice}
                        variant="dark"
                        addTicket={() => {
                          if (!canSelectTickets) return
                          addTicket(ticket)
                        }}
                        removeTicket={() => {
                          if (!canSelectTickets) return
                          removeTicket(ticket)
                        }}
                        canAddTicket={() => {
                          return canAdd(ticket)
                        }}
                        canRemoveTicket={() => {
                          return canRemove(ticket)
                        }}
                        canFastSelect={false}
                        extraDescription={ticket.extraDescription}
                      />
                    )
                  })}
                </div>
              </div>
              {tickets.length === 0 && (
                <span className="mt-4 w-full text-center text-sm text-white">
                  Nenhum ingresso disponível no momento.
                </span>
              )}
            </div>
          </div>
        )}
      </motion.div>
      <div className="fixed bottom-[88px] right-0 z-10 w-full">
        <EventBase.AddPromo
          promo={promo}
          setPromo={setPromo}
          variant="hideable"
        />
      </div>
      <div>
        <EventBase.Footer
          title={footerTitle}
          value={footerValue}
          subtitle={footerSubtitle}
          buttonText={footerButtonText}
          buttonEnabled={footerButtonEnabled}
          buttonOnClick={() => {
            void handleGoToCheckout()
          }}
          isLoading={isLoadingCheck}
          eventFinished={isPastEvent}
        />
      </div>
    </>
  )
}
