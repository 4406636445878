import { type ReactElement, type ElementType, useState, useEffect } from 'react'
import { isSameDay, isFuture } from 'date-fns'
import { useSearchParams, useNavigate } from 'react-router-dom'

import { Loading } from '@/components/Global/Loading'
import { TicketSummary } from '@/components/Global/TicketSummary'
import { QRCodeView } from '@/components/UserWallet/QRCodeView'
import { AccountBalance } from '@/components/UserWallet/AccountBalance'
import { QRCodeModal } from '@/components/UserWallet/QRCodeModal'
import { EventTicketsModal } from '@/components/UserWallet/EventTicketsModal'
import { OrderDetailsModal } from '@/components/UserWallet/OrderDetailsModal'

import { BsThreeDots } from 'react-icons/bs'
import { FaHistory } from 'react-icons/fa'

import { useTransferStore } from '@/store/transfer'
import { useSellStore } from '@/store/sell'
import { useNavbarStore } from '@/store/navbar'
import { useWalletStore } from '@/store/wallet'

import { type IWalletTicketGroup } from '@/types/hooks/api/useWallet'

import { datesToFormat, dateToHour } from '@/helpers/formatDate'
import { isTruthy } from '@/helpers/validation'

interface IViewableTicketsQRCode {
  title: string
  code: string
  validated: boolean
}

export function UserWalletMobile(): ReactElement {
  const [viewableTicketsQRCode, setViewableTicketsQRCode] = useState<
    IViewableTicketsQRCode[]
  >([])
  const [selectedTicketGroup, setSelectedTicketGroup] =
    useState<IWalletTicketGroup>()

  const [showTickets, setShowTickets] = useState<boolean>(false)
  const [showOrderDetails, setShowOrderDetails] = useState<boolean>(false)
  const [orderCode, setOrderCode] = useState<string>('')
  const [showQRCodeModal, setShowQRCodeModal] = useState<boolean>(false)
  const [modalQRCode, setModalQRCode] = useState<string>('')

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  const { resetTransferStore } = useTransferStore()
  const { resetSellStore } = useSellStore()
  const { showNavbar } = useNavbarStore()
  const { walletEvents, isLoadingWalletFetch, refresh } = useWalletStore()

  const todayWalletTicketGroups: IWalletTicketGroup[] =
    walletEvents?.filter((ticketGroup) => {
      const eventStartDate = new Date(ticketGroup.startDate)
      const eventEndDate = new Date(ticketGroup.endDate)
      const today = new Date()

      return (
        (isSameDay(eventStartDate, today) || eventStartDate < today) &&
        today < eventEndDate
      )
    }) ?? []

  const otherWalletTicketGroups: IWalletTicketGroup[] =
    walletEvents?.filter((ticketGroup) => {
      const eventDate = new Date(ticketGroup.startDate)
      const today = new Date()
      return !isSameDay(eventDate, today) && isFuture(eventDate)
    }) ?? []

  function handleEventSelect(walletTicketGroup: IWalletTicketGroup): void {
    setSelectedTicketGroup(walletTicketGroup)
  }

  function handleOpenOrder(orderCode: string): void {
    setShowTickets(false)
    setOrderCode(orderCode)
    setShowOrderDetails(true)
  }

  function handleOpenQRCodeModal(code: string): void {
    setModalQRCode(code)
    setShowQRCodeModal(true)
  }

  useEffect(() => {
    resetTransferStore()
    useTransferStore.persist.clearStorage()
    resetSellStore()
    useSellStore.persist.clearStorage()
    showNavbar()
  }, [])

  useEffect(() => {
    if (searchParams.has('order')) {
      const orderCodeParam = searchParams.get('order')
      if (orderCodeParam !== undefined) {
        handleOpenOrder(orderCodeParam!)
      }
    }
  }, [searchParams])

  useEffect(() => {
    if (todayWalletTicketGroups.length > 0) {
      const selectedEvent = todayWalletTicketGroups[0]
      const eventSecrets = selectedEvent.tickets.map((ticket) => {
        const title = isTruthy(ticket.batchDescription)
          ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
          : ticket.ticketSpecDescription
        return {
          title,
          code: ticket.secret,
          validated: ticket.validated,
        }
      })
      setViewableTicketsQRCode(eventSecrets)
    }
  }, [walletEvents])

  if (isLoadingWalletFetch) {
    return <Loading hidden={!isLoadingWalletFetch} />
  }

  return (
    <>
      <div className="flex min-h-full flex-col overflow-x-hidden bg-background-main py-4 pb-16">
        <div className="flex w-full items-center justify-between">
          <h1 className="px-4 text-2xl text-white">Carteira</h1>
          <button
            className="mr-4 flex items-center gap-3 rounded-md bg-dark-black px-3 py-1.5"
            onClick={() => {
              navigate('history')
            }}
          >
            <FaHistory className="text-white" />
            <span className="line-clamp-1 text-sm font-black text-white">
              Meu histórico
            </span>
          </button>
        </div>
        <div className="mt-8 flex items-center justify-center">
          <QRCodeView
            viewableTicketsQRCode={viewableTicketsQRCode}
            handleOpenQRCodeModal={handleOpenQRCodeModal}
          />
        </div>

        <div className="relative mb-4 flex w-full flex-col px-4">
          <AccountBalance />
        </div>

        <div className="flex flex-col gap-2 px-4">
          <div className="flex size-full flex-col gap-2">
            <span className="text-sm font-semibold text-dark-dark-gray">
              Próximos acessos liberados
            </span>
            <div className="flex w-full flex-col gap-2">
              {todayWalletTicketGroups?.map((ticketGroup) => {
                // Defining BatchAndTicketSpecDescription based on ticketSpecId
                let batchAndTicketSpecDescription
                const ticketSpecIds = new Set()

                ticketGroup.tickets.forEach((ticket) => {
                  ticketSpecIds.add(ticket.ticketSpecId)
                })

                const uniqueBatchDescriptions = Array.from(ticketSpecIds)

                if (uniqueBatchDescriptions.length === 1) {
                  batchAndTicketSpecDescription = isTruthy(
                    ticketGroup.tickets[0].batchDescription,
                  )
                    ? `${ticketGroup.tickets[0].batchDescription} - ${ticketGroup.tickets[0].ticketSpecDescription}`
                    : ticketGroup.tickets[0].ticketSpecDescription
                } else {
                  batchAndTicketSpecDescription = 'Lotes variados'
                }

                // Getting event secrets
                const eventSecrets = ticketGroup.tickets.map((ticket) => {
                  const title = isTruthy(ticket.batchDescription)
                    ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                    : ticket.ticketSpecDescription
                  return {
                    title,
                    code: ticket.secret,
                    validated: ticket.validated,
                  }
                })

                return (
                  <TicketSummary
                    key={ticketGroup.id}
                    title={ticketGroup.title}
                    subtitle={'Hoje'}
                    quantity={ticketGroup.tickets.length}
                    thirdInfo={batchAndTicketSpecDescription}
                    fourthInfo={dateToHour(ticketGroup.startDate)}
                    orderQuantity={
                      ticketGroup.tickets.filter(
                        (ticket) => ticket.orderCode !== '',
                      ).length
                    }
                    icon={BsThreeDots as ElementType}
                    onClick={() => {
                      setViewableTicketsQRCode(eventSecrets)
                    }}
                    onClickIcon={() => {
                      handleEventSelect(ticketGroup)
                      setShowTickets(true)
                    }}
                    variant="highlighted"
                    counterVariant="highlighted"
                  />
                )
              })}
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {otherWalletTicketGroups?.map((ticketGroup) => {
              // Defining BatchAndTicketSpecDescription based on ticketSpecId
              let batchAndTicketSpecDescription
              const ticketSpecIds = new Set()

              ticketGroup.tickets.forEach((ticket) => {
                ticketSpecIds.add(ticket.ticketSpecId)
              })

              const uniqueBatchDescriptions = Array.from(ticketSpecIds)

              if (uniqueBatchDescriptions.length === 1) {
                batchAndTicketSpecDescription = isTruthy(
                  ticketGroup.tickets[0].batchDescription,
                )
                  ? `${ticketGroup.tickets[0].batchDescription} - ${ticketGroup.tickets[0].ticketSpecDescription}`
                  : ticketGroup.tickets[0].ticketSpecDescription
              } else {
                batchAndTicketSpecDescription = 'Lotes variados'
              }

              // Getting event secrets
              const eventSecrets = ticketGroup.tickets.map((ticket) => {
                const title = isTruthy(ticket.batchDescription)
                  ? `${ticket.batchDescription} - ${ticket.ticketSpecDescription}`
                  : ticket.ticketSpecDescription
                return {
                  title,
                  code: ticket.secret,
                  validated: ticket.validated,
                }
              })

              return (
                <TicketSummary
                  key={ticketGroup.id}
                  title={ticketGroup.title}
                  subtitle={datesToFormat(
                    ticketGroup.startDate,
                    ticketGroup.endDate,
                  )}
                  quantity={ticketGroup.tickets.length}
                  thirdInfo={batchAndTicketSpecDescription}
                  fourthInfo={dateToHour(ticketGroup.startDate)}
                  orderQuantity={
                    ticketGroup.tickets.filter(
                      (ticket) => ticket.orderCode !== '',
                    ).length
                  }
                  icon={BsThreeDots as ElementType}
                  onClick={() => {
                    setViewableTicketsQRCode(eventSecrets)
                  }}
                  onClickIcon={() => {
                    handleEventSelect(ticketGroup)
                    setShowTickets(true)
                  }}
                  variant="light"
                  counterVariant="dark"
                />
              )
            })}
          </div>
        </div>
      </div>
      {showTickets && selectedTicketGroup != null && (
        <EventTicketsModal
          ticketGroup={selectedTicketGroup}
          closeModal={() => {
            setShowTickets(false)
          }}
          handleOpenOrder={handleOpenOrder}
        />
      )}
      {showOrderDetails && (
        <OrderDetailsModal
          orderCode={orderCode}
          closeModal={() => {
            setShowOrderDetails(false)
          }}
          refreshWallet={() => {
            if (refresh !== undefined) void refresh()
          }}
        />
      )}
      {showQRCodeModal && (
        <QRCodeModal
          closeModal={() => {
            setShowQRCodeModal(false)
          }}
          isParentClosing={false}
          code={modalQRCode}
        />
      )}
    </>
  )
}
