import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'

import { BsTicket } from 'react-icons/bs'
import { FaRegCircleUser } from 'react-icons/fa6'
import { FiLogOut } from 'react-icons/fi'
import { TbHeadset } from 'react-icons/tb'

import { useUserStore } from '@/store/user'

import { useLogout } from '@/hooks/api/Profile'

interface HeaderProfileOptionsProps {
  showProfileOptions: boolean
  setShowProfileOptions: (value: boolean) => void
}

export function HeaderProfileOptions({
  setShowProfileOptions,
  showProfileOptions,
}: HeaderProfileOptionsProps): ReactElement {
  const { username, logoutUser } = useUserStore()

  const navigate = useNavigate()

  const { logout } = useLogout()

  async function handleLogout(): Promise<void> {
    setShowProfileOptions(false)

    const response = await logout()
    if (response.status === 200) logoutUser()
  }

  return (
    <AnimatePresence>
      {showProfileOptions && (
        <motion.div
          className="absolute bottom-[-170px] right-8 z-40 flex origin-top flex-col gap-1 rounded-lg bg-dark-black px-4 py-3"
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          transition={{ duration: 0.2 }}
          key="profile-options"
        >
          <button
            className="flex items-center gap-3 hover:cursor-pointer"
            onClick={() => {
              setShowProfileOptions(false)
              navigate(`/memories/${username}`)
            }}
          >
            <FaRegCircleUser size={20} color="#fff" />
            <span className="text-lg text-white">Meu perfil</span>
          </button>

          <button
            className="flex items-center gap-3 hover:cursor-pointer"
            onClick={() => {
              setShowProfileOptions(false)
              navigate(`/wallet`)
            }}
          >
            <BsTicket size={20} color="#fff" style={{ strokeWidth: '0.8px' }} />
            <span className="text-lg text-white">Meus ingressos</span>
          </button>

          <button
            className="flex items-center gap-3"
            onClick={() => {
              setShowProfileOptions(false)
              const msg = 'Olá, gostaria de tirar uma dúvida.'
              const url = `https://wa.me/${
                import.meta.env.VITE_SUPPORT_PHONE as string
              }?text=${msg}`
              window.open(url, '_blank')
            }}
          >
            <TbHeadset size={20} color="#fff" />
            <span className="text-lg text-white">Suporte</span>
          </button>

          <button className="flex items-center gap-3" onClick={handleLogout}>
            <FiLogOut size={20} color="#fff" />
            <span className="text-lg text-white">Logout</span>
          </button>

          <div className="absolute -top-4 right-2 size-0 border-x-[18px] border-b-[28px] border-x-transparent border-b-dark-black" />
        </motion.div>
      )}
    </AnimatePresence>
  )
}
