import { useState, type ReactElement } from 'react'

import { FiCamera } from 'react-icons/fi'
// import { FaInstagram, FaTiktok } from 'react-icons/fa'

import { type IUserData } from '@/types/hooks/api/useProfile'

import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'
import { useSnackbarStore } from '@/store/snackbar'

import { Button } from '@/components/Global/Button'

import {
  usePostCreateFollowRequest,
  useDeleteUnfollow,
  usePutCancelFollowRequest,
} from '@/hooks/api/Social'

import { ERROR_TO_CANCEL_FOLLOW, ERROR_TO_CREATE_FOLLOW } from '@/errors'

interface ProfileHeaderProps {
  userData?: IUserData
  isAuthUser: boolean
  isLoading: boolean
  openEditProfileModal?: () => void
  openEditProfilePictureModal?: () => void
  openShowFollowersModal?: () => void
  openShowFollowingModal?: () => void
  openShowEventsModal?: () => void
}

export function ProfileHeader({
  userData,
  isAuthUser,
  isLoading,
  openEditProfileModal,
  openEditProfilePictureModal,
  openShowFollowersModal,
  openShowFollowingModal,
  openShowEventsModal,
}: ProfileHeaderProps): ReactElement {
  const [followRequestStatus, setFollowRequestStatus] = useState<
    'FOLLOWING' | 'NOT_FOLLOWING' | 'PENDING' | undefined
  >(userData?.followRequest.status)

  const { imageKey, id, userIsLogged } = useUserStore()
  const { setShowLoginModal } = useGlobalStore()
  const { showErrorSnack } = useSnackbarStore()

  const { postCreateFollowRequest, isLoading: followRequestIsLoading } =
    usePostCreateFollowRequest()
  const { deleteUnfollow, isLoading: unfollowRequestIsLoading } =
    useDeleteUnfollow()
  const { putCancelFollowRequest, isLoading: cancelFollowRequestIsLoading } =
    usePutCancelFollowRequest()

  const events = userData?.numberOfEvents === 1 ? 'evento' : 'eventos'
  const followers = userData?.followers === 1 ? 'seguidor' : 'seguidores'

  const userImageKey = id === userData?.id ? imageKey : userData?.imageKey

  async function handleFollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }
    if (userData == null) return
    const response = await postCreateFollowRequest(userData?.id)
    if (response.status === 200) {
      setFollowRequestStatus('PENDING')
    } else {
      showErrorSnack(ERROR_TO_CREATE_FOLLOW)
    }
  }

  async function handleUnfollow(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }
    if (userData == null) return
    const response = await deleteUnfollow(userData?.id)
    if (response.status === 200) {
      setFollowRequestStatus('NOT_FOLLOWING')
    } else {
      showErrorSnack(ERROR_TO_CREATE_FOLLOW)
    }
  }

  async function handleCancelFollowRequest(): Promise<void> {
    if (!userIsLogged) {
      setShowLoginModal(true)
      return
    }
    if (userData == null) return
    const response = await putCancelFollowRequest(userData?.followRequest.id)
    if (response.status === 200) {
      setFollowRequestStatus('NOT_FOLLOWING')
    } else {
      showErrorSnack(ERROR_TO_CANCEL_FOLLOW)
    }
  }

  if (isLoading || userData === undefined)
    return (
      <div className="flex w-full max-w-full items-center justify-between gap-4 text-white">
        <div className="flex min-w-0 gap-6">
          <div className="relative size-44 shrink-0 animate-pulse rounded-full bg-dark-black" />

          <div className="flex min-w-0 flex-col justify-evenly">
            <div className="flex h-6 w-24 animate-pulse items-center justify-center rounded-full bg-dark-black" />

            <div className="flex min-w-0 flex-col">
              <div className="h-10 w-64 animate-pulse bg-dark-black" />

              <div className="flex gap-6 pt-0.5">
                <div className="h-6 w-24 animate-pulse bg-dark-black" />
                <div className="h-6 w-24 animate-pulse bg-dark-black" />
                <div className="h-6 w-24 animate-pulse bg-dark-black" />
              </div>
            </div>

            <div className="flex gap-4">
              <div className="flex gap-2">
                <div className="flex size-8 animate-pulse rounded-full bg-dark-black" />
                <div className="flex size-8 animate-pulse rounded-full bg-dark-black" />
              </div>
            </div>
          </div>
        </div>
      </div>
    )

  return (
    <div className="flex w-full max-w-full items-center justify-between gap-4 text-white">
      <div className="flex min-w-0 gap-6">
        <div className="relative size-44 shrink-0">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${userImageKey ?? ''}`}
            alt="Profile image"
            className="size-full rounded-full"
          />

          {isAuthUser && (
            <button
              className="absolute bottom-0 right-2 flex size-9 items-center justify-center rounded-full bg-white"
              onClick={openEditProfilePictureModal}
            >
              <FiCamera size={22} color="#181818" />
            </button>
          )}
        </div>

        <div className="flex min-w-0 flex-col justify-evenly">
          <div className="flex w-min items-center justify-center rounded-full bg-dark-black px-3 py-0.5">
            <span className="text-sm">@{userData.username}</span>
          </div>

          <div className="flex min-w-0 flex-col">
            <span className="truncate text-4xl font-bold">
              {`${userData.firstName} ${userData.lastName}`}
            </span>

            <div className="flex gap-6 pt-0.5">
              <span
                className="cursor-pointer text-nowrap text-base transition-colors duration-200 hover:text-primary-main"
                onClick={openShowEventsModal}
              >
                <strong>{userData.numberOfEvents}</strong> {events}
              </span>
              <span
                className="cursor-pointer text-nowrap text-base transition-colors duration-200 hover:text-primary-main"
                onClick={openShowFollowersModal}
              >
                <strong>{userData.followers}</strong> {followers}
              </span>
              <span
                className="cursor-pointer text-nowrap text-base transition-colors duration-200 hover:text-primary-main"
                onClick={openShowFollowingModal}
              >
                <strong>{userData.following}</strong> seguindo
              </span>
            </div>
          </div>

          <div className="flex gap-4">
            {/* <PeopleCounter /> */}

            {/* <div className="flex gap-2">
              <a className="flex size-8 items-center justify-center rounded-full bg-dark-black">
                <FaInstagram size={14} color="white" />
              </a>
              <a className="flex size-8 items-center justify-center rounded-full bg-dark-black">
                <FaTiktok size={13} color="white" />
              </a>
            </div> */}
          </div>
        </div>
      </div>

      {isAuthUser ? (
        <button
          className="text-nowrap rounded-full bg-white px-4 py-1 text-sm font-medium text-dark-black"
          onClick={openEditProfileModal}
        >
          Editar perfil
        </button>
      ) : (
        <div className="h-10 w-48">
          {followRequestStatus === 'NOT_FOLLOWING' && (
            <Button
              className="text-base"
              text="Seguir"
              enabled={!followRequestIsLoading}
              onClick={handleFollow}
              isLoading={followRequestIsLoading}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          )}

          {followRequestStatus === 'PENDING' && (
            <Button
              className="text-base brightness-50"
              text="Solicitação enviada"
              enabled={!cancelFollowRequestIsLoading}
              onClick={handleCancelFollowRequest}
              isLoading={cancelFollowRequestIsLoading}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          )}

          {followRequestStatus === 'FOLLOWING' && (
            <Button
              className="text-base"
              text="Deixar de seguir"
              enabled={!unfollowRequestIsLoading}
              onClick={handleUnfollow}
              isLoading={unfollowRequestIsLoading}
              customSpinnerHeight="h-6"
              customSpinnerWidth="w-6"
            />
          )}
        </div>
      )}
    </div>
  )
}
