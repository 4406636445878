import { useState, type ReactElement, useRef, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { BsFillExclamationTriangleFill } from 'react-icons/bs'

import { usePostResendEmailOTP } from '@/hooks/api/Auth'

interface VerifyEmailSnackbarProps {
  showSnackBar?: boolean
}

export function VerifyEmailSnackbar({
  showSnackBar,
}: VerifyEmailSnackbarProps): ReactElement {
  const [offset, setOffset] = useState(0)
  const [emailSent, setEmailSent] = useState(false)

  const { postResendEmailOtp } = usePostResendEmailOTP()

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef !== null) {
      setOffset((divRef.current?.clientHeight ?? 0) + 54)
    }
  }, [divRef, showSnackBar])

  return (
    <AnimatePresence>
      {showSnackBar !== undefined && showSnackBar && (
        <motion.div
          key="snackbar"
          className={
            'absolute left-[5%] top-0 -z-20 flex w-full items-center gap-2 rounded-lg border border-white p-4 text-white drop-shadow-md backdrop-blur-sm backdrop-brightness-75'
          }
          initial={{ y: 0 }}
          animate={{ y: -offset }}
          exit={{ y: 0 }}
          transition={{ duration: 0.5, ease: 'backInOut' }}
          ref={divRef}
        >
          <BsFillExclamationTriangleFill color="white" size={24} />
          <p className="ml-2 w-[90%] text-xs">
            Você ainda não confirmou seu email. Verifique sua caixa de entrada e
            spam.{' '}
            <span
              className={twMerge(
                'font-bold hover:cursor-pointer',
                !emailSent && 'italic underline decoration-1',
              )}
              onClick={() => {
                void postResendEmailOtp()
                setEmailSent(true)
              }}
            >
              {emailSent ? 'Novo email enviado!' : 'Clique aqui para reenviar.'}
            </span>
          </p>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
