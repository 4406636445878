import { type ReactElement } from 'react'

import { useGlobalStore } from '@/store/global'
import { UserWalletDesktop } from './UserWalletDesktop'
import { UserWalletMobile } from './UserWalletMobile'

export function UserWallet(): ReactElement {
  const { isMobile } = useGlobalStore()

  if (isMobile) return <UserWalletMobile />
  return <UserWalletDesktop />
}
