import { type ReactElement } from 'react'

import { EventCard } from '@/components/Global/EventCard'

import { isTruthy } from '@/helpers/validation'

import { useGetHomeEvents } from '@/hooks/api/Home'
import { AnimatePresence, motion } from 'framer-motion'

export function EventList(): ReactElement {
  const { homeEventsData, isLoading } = useGetHomeEvents()

  const events = [
    homeEventsData?.main,
    homeEventsData?.sections.flatMap((section) => section.events),
    homeEventsData?.otherEvents,
  ].flat()

  return (
    <AnimatePresence mode="wait">
      {isLoading ? (
        <motion.div
          className="grid size-full grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          key="loading-events"
        >
          {Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="flex flex-col items-start gap-2">
              <div className="aspect-square w-full animate-pulse rounded-md bg-dark-black" />
              <div className="h-16 w-full animate-pulse rounded-md bg-dark-black" />
            </div>
          ))}
        </motion.div>
      ) : (
        <motion.div
          className="grid size-full grid-cols-[repeat(auto-fill,minmax(200px,1fr))] gap-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          key="events"
        >
          {events
            .filter((event) => event !== undefined)
            .map((event, index) => {
              return (
                <EventCard
                  alias={event.alias}
                  key={index}
                  imageUrl={event.splashImageKey}
                  title={event.title}
                  startDate={event.date}
                  endDate={event.endDate}
                  location={
                    isTruthy(event.location)
                      ? event.location
                      : 'Local a definir'
                  }
                  variation="desktop"
                  onFire={event.onFire}
                  lowestTicketPrice={event.lowestTicketPrice}
                  showInterest
                  isUserInsterested={
                    event.userEventStatus === 'INTERESTED' ||
                    event.userEventStatus === 'GOING'
                  }
                />
              )
            })}
        </motion.div>
      )}
    </AnimatePresence>
  )
}
