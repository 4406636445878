import { api } from '@/services/axios'
import { useState } from 'react'
import { type AxiosError } from 'axios'

import { type IResendEmailOtpResponse } from '@/types/hooks/api/useOTP'

export const usePostResendEmailOTP = (): {
  postResendEmailOtp: () => Promise<IResendEmailOtpResponse>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postResendEmailOtp(): Promise<IResendEmailOtpResponse> {
    setIsLoading(true)

    try {
      await api.post(`/auth/resend-email-otp`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError<IResendEmailOtpResponse>
      setIsLoading(false)

      if (error.response?.status === 401) {
        return {
          status: 401,
        }
      }
      if (error.response?.status === 406) {
        return {
          status: 406,
        }
      }
      return {
        status: error.response?.status as number,
      }
    }
  }

  return { postResendEmailOtp, isLoading }
}
