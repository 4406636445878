import { useState } from 'react'
import { api } from '@/services/axios'

import { type AxiosError } from 'axios'

interface IReturn {
  status: number
}

export const usePutUserStatus = (
  alias: string,
): {
  isLoading: boolean
  putUserStatus: (status: 'GOING' | 'INTERESTED' | 'VIEWED') => Promise<IReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function putUserStatus(
    status: 'GOING' | 'INTERESTED' | 'VIEWED',
  ): Promise<IReturn> {
    setIsLoading(true)

    try {
      await api.put(`/events/${alias}/status`, {
        status,
      })
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { putUserStatus, isLoading }
}
