export function usePixelTrackPageView(): {
  trackPageView: (pixelId: string) => void
} {
  function trackPageView(pixelId: string): void {
    if (window.fbq != null && typeof window.fbq === 'function') {
      window.fbq('trackSingle', pixelId, 'PageView')
    }
  }
  return { trackPageView }
}
