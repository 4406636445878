import { type ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { LoginSignup as LoginSignupComponent } from '@/components/LoginSignup'
import { Loading } from '@/components/Global/Loading'

import { useNavbarStore } from '@/store/navbar'
import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'

import { GoHomeFill } from 'react-icons/go'

export function LoginSignup(): ReactElement {
  const bgImageId = Math.floor(Math.random() * 10) + 1

  const { hideNavbar } = useNavbarStore()
  const { userIsLogged } = useUserStore()
  const { isPinging } = useRoutingStore()

  const navigate = useNavigate()

  useEffect(() => {
    hideNavbar()
  }, [])

  useEffect(() => {
    if (userIsLogged) navigate('/')
  }, [userIsLogged])

  if (isPinging) return <Loading hidden={false} />
  return (
    <div className="flex h-screen grow flex-col items-center justify-center desktop:h-auto">
      <video
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          display: 'block',
          width: '100vw',
          height: '100%',
          objectFit: 'cover',
        }}
        src={`https://gandaya.b-cdn.net/bg-videos/v${bgImageId}.mp4`}
        autoPlay
        loop
        muted
        playsInline
        className="-z-10 bg-black blur-md brightness-75"
      />
      <div className="flex h-full w-[90%] max-w-[500px] flex-col items-start justify-center gap-4 py-8">
        <div
          className="flex items-center justify-center gap-4 rounded-xl bg-dark-black px-4 py-2 hover:cursor-pointer"
          onClick={() => {
            navigate('/')
          }}
        >
          <GoHomeFill color="white" />
          <span className="text-white">Home</span>
        </div>
        <div className="mb-16 flex  w-full flex-col justify-center rounded-xl bg-dark-white">
          <LoginSignupComponent />
        </div>
      </div>
      <div className="absolute bottom-0 z-30 w-[90%]"></div>
    </div>
  )
}
