import { type ReactElement, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useNavbarStore } from '@/store/navbar'

export function UserNotFound(): ReactElement {
  const navigate = useNavigate()

  const { hideNavbar, showNavbar } = useNavbarStore()

  useEffect(() => {
    hideNavbar()
  }, [])

  return (
    <div className="absolute left-0 top-0 z-50 flex size-full flex-col items-center justify-center bg-dark-black p-8">
      <h1 className="mb-8 text-center text-3xl text-white">
        Não encontramos esse usuário
      </h1>
      <button
        className="fixed bottom-8 w-4/5 rounded-full bg-primary-main px-8 py-4 font-bold"
        onClick={() => {
          showNavbar()
          navigate('/')
        }}
      >
        Ir para a página inicial
      </button>
    </div>
  )
}
