import QRCode from 'qrcode'
import { type ReactElement, useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

interface QRCodeProps {
  value: string
  variant?: 'light' | 'dark'
  bgTransparent?: boolean
  margin?: boolean
}

export function QrCode({
  value,
  variant = 'dark',
  bgTransparent = false,
  margin = true,
}: QRCodeProps): ReactElement {
  const [qrcode, setQrcode] = useState('')

  const GenerateQRCode = (): void => {
    QRCode.toDataURL(
      value,
      {
        errorCorrectionLevel: 'H',
        width: 800,
        margin: margin ? 4 : 0,
        color: {
          dark: variant === 'dark' ? '#ffffff' : '#232323',
          light: bgTransparent
            ? '#0000'
            : variant === 'dark'
              ? '#232323'
              : '#ffffff',
        },
      },
      function (err, url) {
        if (err !== null) {
          return
        }
        setQrcode(url)
      },
    )
  }

  useEffect(() => {
    if (value !== undefined) GenerateQRCode()
  }, [value])

  return (
    <div
      className={twMerge(
        'flex aspect-square size-full justify-center overflow-hidden',
        margin && 'rounded-xl',
      )}
    >
      <img src={qrcode} alt="Event QRCode" className="h-full w-auto" />
    </div>
  )
}
