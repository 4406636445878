import { type ReactElement } from 'react'

import { Button } from '@/components/Global/Button'

import { type IOrganization } from '@/types/hooks/api/useOrganization'
import { isTruthy } from '@/helpers/validation'

interface OrganizationHeaderProps {
  organization: IOrganization | undefined
  openOrganizationDetailsModal: () => void
  handleFollow: () => Promise<void>
  handleUnfollow: () => Promise<void>
  isLoading: boolean
}

export function OrganizationHeader({
  organization,
  openOrganizationDetailsModal,
  handleFollow,
  handleUnfollow,
  isLoading,
}: OrganizationHeaderProps): ReactElement {
  const followersText =
    organization?.followers === 1 ? 'seguidor' : 'seguidores'

  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-4 text-white">
        <img
          src={`${import.meta.env.VITE_S3 as string}/${organization?.imageKey ?? ''}`}
          alt="Organization image"
          className="size-[165px] shrink-0 rounded-full"
        />
        <div className="flex w-full flex-col gap-2">
          <h1 className="text-4xl">{organization?.name}</h1>

          <span>
            <strong>{organization?.followers}</strong> {followersText}
          </span>

          {isTruthy(organization?.description) && (
            <div className="flex w-full gap-2">
              <div className="max-w-[200px] overflow-hidden">
                <span className="block text-nowrap">
                  {organization?.description}
                </span>
              </div>
              <button
                className="text-text-secondary"
                onClick={openOrganizationDetailsModal}
              >
                ...mais
              </button>
            </div>
          )}
        </div>
      </div>
      <div className="h-8 w-32">
        <Button
          enabled={!isLoading}
          text={
            organization?.followStatus === 'FOLLOWING'
              ? 'Deixar de seguir'
              : 'Seguir'
          }
          className="text-sm font-semibold"
          onClick={(e) => {
            e.stopPropagation()
            if (organization?.followStatus === 'FOLLOWING') {
              void handleUnfollow()
            } else {
              void handleFollow()
            }
          }}
          isLoading={isLoading}
          customSpinnerHeight="h-6"
          customSpinnerWidth="w-6"
        />
      </div>
    </div>
  )
}
