import { useEffect, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'

import { PeopleCounter } from '@/components/Event/PeopleCounter'

import { useInterestStore } from '@/store/interest'

import { useUpdateUserInterest } from '@/helpers/useUserInterest'

interface EventInterestProps {
  openEventInterestModal: () => void
  initialUserStatus?: 'GOING' | 'INTERESTED' | 'VIEWED'
}

export function EventInterest({
  openEventInterestModal,
  initialUserStatus = 'VIEWED',
}: EventInterestProps): ReactElement {
  const { eventAlias } = useParams()

  const {
    viewed,
    alias: interestAlias,
    userStatus,
    setUserStatus,
  } = useInterestStore()

  const { handleUpdateUserInterest } = useUpdateUserInterest(eventAlias!)

  useEffect(() => {
    setUserStatus(initialUserStatus)
  }, [initialUserStatus])

  return (
    <div className="relative flex w-full items-center justify-between overflow-visible">
      <div className="flex h-min gap-2">
        <button
          className={twMerge(
            'rounded-full border border-primary-main px-4 py-2 text-xs font-bold text-primary-main transition-colors duration-100 ease-in-out',
            userStatus === 'GOING' && 'text-dar-black bg-primary-main',
          )}
          onClick={() => {
            void handleUpdateUserInterest('GOING')
          }}
        >
          Vou
        </button>
        <button
          className={twMerge(
            'transition-color rounded-full border border-white px-4 py-2 text-xs font-bold text-white transition-colors duration-100 ease-in-out',
            userStatus === 'INTERESTED' && 'bg-white text-dark-black',
          )}
          onClick={() => {
            void handleUpdateUserInterest('INTERESTED')
          }}
        >
          Tenho interesse
        </button>
      </div>

      {viewed.total > 0 && eventAlias === interestAlias && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <PeopleCounter
            data={viewed}
            openEventInterestModal={openEventInterestModal}
            size="sm"
          />
        </motion.div>
      )}
    </div>
  )
}
