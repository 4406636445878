import { useState } from 'react'
import { type AxiosResponse, type AxiosError } from 'axios'

import { api } from '@/services/axios'

import {
  type IGetSearchUsersReturn,
  type IUserDataResponse,
} from '@/types/hooks/api/useSearch'

export const useGetSearchUsers = (): {
  searchUsers: (searchParam: string) => Promise<IGetSearchUsersReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function searchUsers(
    searchParam: string,
  ): Promise<IGetSearchUsersReturn> {
    setIsLoading(true)

    try {
      const response: AxiosResponse<IUserDataResponse[]> = await api.get(
        `/search/users?term=${searchParam}&skip=0&take=10`,
      )
      setIsLoading(false)
      return {
        status: 200,
        users: response.data.map((user) => {
          return {
            ...user,
          }
        }),
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { searchUsers, isLoading }
}
