export const SEND_EMAIL_ERROR = {
  title: 'Ocorreu um erro ao enviar o email',
  description: 'Tente novamente em alguns instantes',
}

export const SEND_OTP_ERROR = {
  title: 'Ocorreu um erro ao enviar o código',
  description: 'Tente novamente em alguns instantes',
}

export const PROFILE_PICTURE_ERROR = {
  title: 'Ocorreu um erro ao definir a imagem de perfil',
  description: 'Tente novamente em alguns instantes',
}

export const SIGNUP_ERROR = {
  title: 'Ocorreu um erro ao realizar o cadastro',
  description: 'Tente novamente em alguns instantes',
}

export const EDIT_PROFILE_ERROR = {
  title: 'Ocorreu um erro ao atualizar seus dados',
  description: 'Tente novamente em alguns instantes',
}

export const VALIDATE_TOKEN_ERROR = {
  title: 'Ocorreu um erro ao validar o token',
  description: 'Tente novamente em alguns instantes',
}

export const GLUE_STICKER_ERROR = {
  title: 'Ocorreu um erro ao colar o sticker',
  description: 'Tente novamente em alguns instantes',
}

export const CLAIM_ERROR = {
  title: 'Ocorreu um erro ao receber os ingressos',
  description: 'Tente novamente em alguns instantes',
}

export const SEARCH_ERROR = {
  title: 'Ocorreu um erro ao realizar a pesquisa',
  description: 'Tente novamente em alguns instantes',
}

export const PAYMENT_NOT_APPROVED_ERROR = {
  title: 'O pagamento ainda não foi aprovado',
  description: 'Tente novamente em alguns instantes',
}

export const PAYMENT_VALIDATION_ERROR = {
  title: 'Ocorreu um erro ao validar o pagamento',
  description: 'Tente novamente em alguns instantes',
}

export const USER_SEARCH_ERROR = {
  title: 'Ocorreu um erro ao realizar a pesquisa',
  description: 'Tente novamente',
}

export const NO_USER_FOUND = {
  title: 'Nenhum usuário encontrado',
}

export const CANCEL_ORDER_ERROR = {
  title: 'Ocorreu um erro ao cancelar a venda',
  description: 'Tente novamente em alguns instantes',
}

export const ORDER_NOT_AVAILABLE = {
  title: 'Esse link de revenda não está mais ativo.',
}

export const ORDER_ALREADY_SOLD = {
  title: 'Esse ingresso já foi vendido.',
}

export const TICKET_NOT_AVAILABLE = {
  title: 'Os ingressos selecionados não estão mais disponíveis!',
}

export const TICKETS_NOT_AVAILABLE_ANYMORE = {
  title: 'Os ingressos selecionados não estão mais disponíveis!',
  description: 'Tente novamente.',
}

export const ORDER_RESERVED = {
  title: 'Esse ingresso já está reservado por outro comprador.',
  description: 'Tente novamente em alguns instantes.',
}

export const CANT_CREATE_PAYMENT = {
  title: 'Ocorreu um erro ao gerar o pagamento!',
  description: 'Tente novamente em alguns instantes.',
}

export const CREATE_MKT_ORDER_ERROR = {
  title: 'Ocorreu um erro ao criar o link de venda',
  description: 'Tente novamente em alguns instantes',
}

export const LOAD_PAYMENT_ERROR = {
  title: 'Erro ao carregar dados do pagamento',
  description: 'Tente novamente em alguns instantes',
}

export const FETCH_EVENT_TICKETS_ERROR = {
  title: 'Erro ao carregar ingressos',
  description: 'Tente novamente em alguns instantes',
}

export const NO_TICKETS_TO_TRANSFER = {
  title: 'Você não possui ingressos para transferir',
}

export const NO_TICKETS_TO_SELL = {
  title: 'Você não possui ingressos para vender',
}

export const MIN_SELL_PRICE = {
  title: 'Erro ao criar venda',
  description: 'O valor mínimo para venda é de R$ 1,00',
}

export const INVALID_BRAZILLIAN_PHONE = {
  title: 'Número de telefone inválido',
  description: 'Certifique-se de inserir DDD + 9 e então seu celular.',
}

export const ERROR_TO_CREATE_FOLLOW = {
  title: 'Erro ao seguir usuário',
  description: 'Tente novamente',
}

export const ERROR_TO_UNFOLLOW = {
  title: 'Erro ao deixar de seguir usuário',
  description: 'Tente novamente',
}

export const ERROR_TO_CANCEL_FOLLOW = {
  title: 'Erro ao cancelar pedido para seguir usuário',
  description: 'Tente novamente',
}

export const ERROR_TO_ACCEPT_FOLLOW_REQUEST = {
  title: 'Erro ao aceitar solicitação',
  description: 'Tente novamente',
}

export const ERROR_TO_DENY_FOLLOW_REQUEST = {
  title: 'Erro ao rejeitar solicitação',
  description: 'Tente novamente',
}

export const ERROR_TO_LOAD_MORE_USERS = {
  title: 'Erro ao carregar mais usuários',
}
