import { api } from '@/services/axios'

export const useToggleReaction = (): {
  postToggleReaction: (eventId: number, emoji: string) => Promise<void>
} => {
  async function postToggleReaction(
    eventId: number,
    emoji: string,
  ): Promise<void> {
    const requestBody = {
      eventId,
      emoji,
    }

    try {
      await api.post('/reactions/toggle', requestBody)
    } catch (err) {}
  }

  return { postToggleReaction }
}
