export function capitalizeString(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export function formatPhoneNumber(v: string): string {
  let r = v.replace(/\D/g, '')
  r = r.replace(/^0/, '')
  if (r.length > 10) {
    r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3')
  } else if (r.length > 6) {
    r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3')
  } else if (r.length > 2) {
    r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2')
  } else {
    r = r.replace(/^(\d*)/, '$1')
  }
  return r
}

export function formatCPF(value: string): string {
  const cpf = value.replace(/\D/g, '')
  if (cpf.length <= 3) {
    return cpf
  } else if (cpf.length <= 6) {
    return `${cpf.slice(0, 3)}.${cpf.slice(3)}`
  } else if (cpf.length <= 9) {
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(6)}`
  } else {
    return `${cpf.slice(0, 3)}.${cpf.slice(3, 6)}.${cpf.slice(
      6,
      9,
    )}-${cpf.slice(9, 11)}`
  }
}

export function validateCpf(strCPF: string): boolean {
  let Soma
  let Resto
  Soma = 0
  if (strCPF === '00000000000') return false

  const cpf = strCPF.replace(/\D/g, '')

  for (let i = 1; i <= 9; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if (Resto === 10 || Resto === 11) Resto = 0
  if (Resto !== parseInt(cpf.substring(9, 10))) return false

  Soma = 0
  for (let i = 1; i <= 10; i++)
    Soma = Soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11

  if (Resto === 10 || Resto === 11) Resto = 0
  if (Resto !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export function formatDate(dateString: string): string {
  const date = dateString.replace(/\D/g, '')

  if (date.length <= 2) {
    return date
  } else if (date.length <= 4) {
    return `${date.slice(0, 2)}/${date.slice(2)}`
  } else {
    return `${date.slice(0, 2)}/${date.slice(2, 4)}/${date.slice(4, 8)}`
  }
}

export function formatEncodeGoogleMapsString(str: string): string {
  const removeAccents = str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  const encodeUrl = removeAccents.replace(/\s/g, '+').replace(/[-,.]/g, '%2C')
  return encodeUrl
}

export const validateEmail = (email: string): boolean => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
