import { useState, type ReactElement } from 'react'
import { useNavigate } from 'react-router-dom'

import { useGetEventHistorySocial } from '@/hooks/api/Wallet'

import { DownloadAppModal } from '@/components/Global/DownloadAppModal'

interface EventHistoryUsersCardProps {
  alias: string
}

export function EventHistoryUsersCard({
  alias,
}: EventHistoryUsersCardProps): ReactElement {
  const [showDownloadModal, setShowDownloadModal] = useState(false)

  const { users } = useGetEventHistorySocial(alias)

  const navigate = useNavigate()

  const hasSocialData =
    users !== undefined &&
    (users?.friends?.length > 0 ||
      users?.following?.length > 0 ||
      users?.followers?.length > 0)

  if (!hasSocialData) return <></>
  return (
    <div className="flex flex-col gap-6 rounded-md bg-dark-black p-4">
      {(users?.friends.length > 0 || users?.following.length > 0) && (
        <div className="flex flex-col gap-2">
          <span className="text-xs font-bold text-white">
            Amigos que foram ao evento
          </span>
          <div className="flex flex-wrap gap-4">
            {users?.friends.map((user) => {
              return (
                <div key={user.username} className="w-[64px] max-w-[64px]">
                  <button
                    className="flex flex-col items-center gap-0.5"
                    onClick={() => {
                      navigate(`/memories/${user.username}`)
                    }}
                  >
                    <img
                      src={`${import.meta.env.VITE_S3 as string}/${
                        user.imageKey
                      }`}
                      alt="User image"
                      className="size-12 rounded-full bg-white"
                    />
                    <span className="max-w-[64px] truncate text-xs text-white">
                      @{user.username}
                    </span>
                  </button>
                </div>
              )
            })}
            {users?.following.map((user) => {
              return (
                <button
                  key={user.username}
                  className="flex flex-col items-center gap-0.5"
                  onClick={() => {
                    navigate(`/memories/${user.username}`)
                  }}
                >
                  <img
                    src={`${import.meta.env.VITE_S3 as string}/${
                      user.imageKey
                    }`}
                    alt="User image"
                    className="size-12 rounded-full bg-white"
                  />
                  <span className="text-xs text-white">@{user.username}</span>
                </button>
              )
            })}
          </div>
        </div>
      )}
      {users?.followers.length > 0 && (
        <div className="flex flex-col gap-2">
          <span className="text-xs font-bold text-white">
            Seguidores que foram ao evento
          </span>
          <div className="flex flex-wrap">
            {users?.followers.map((user) => {
              return (
                <div
                  className="flex w-full items-center justify-between"
                  key={user.username}
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={`${import.meta.env.VITE_S3 as string}/${
                        user.imageKey
                      }`}
                      alt="Foto de perfil"
                      className="aspect-square size-12 rounded-full"
                    />
                    <div className="flex flex-col gap-1.5">
                      <span className="text-sm font-medium leading-3 text-white">
                        @{user.username}
                      </span>
                      <span className="text-xs leading-3 text-text-secondary">
                        {`${user.firstName} ${user.lastName}`}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <button
                      className="h-min rounded-md bg-primary-main px-4 py-1.5 text-xs font-bold leading-3 text-dark-black"
                      onClick={() => {
                        setShowDownloadModal(true)
                      }}
                    >
                      Seguir
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      )}
      {showDownloadModal && (
        <DownloadAppModal
          closeModal={() => {
            setTimeout(setShowDownloadModal, 400, false)
          }}
          text="Para aceitar essa solicitação e acompanhar seus amigos, baixe nosso
          aplicativo!"
        />
      )}
    </div>
  )
}
