import { api } from '@/services/axios'
import { useState } from 'react'

interface PostReturnProps {
  status: boolean
  message?: string
}

export const usePostTransfer = (): {
  postTransfer: (
    receiverId: number,
    tickets: number[],
  ) => Promise<PostReturnProps>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postTransfer(
    receiverId: number,
    tickets: number[],
  ): Promise<PostReturnProps> {
    setIsLoading(true)

    const requestBody = {
      receiverId,
      products: tickets,
    }

    try {
      await api.post(`/transactions`, requestBody)
      setIsLoading(false)
      return {
        status: true,
      }
    } catch (err) {
      setIsLoading(false)
      return {
        status: false,
        message:
          tickets.length === 1
            ? 'Erro ao transferir ingresso'
            : 'Erro ao transferir ingressos',
      }
    }
  }

  return { postTransfer, isLoading }
}
