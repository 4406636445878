import fetcher from '@/services/axios'
import useSWR from 'swr'

import { type IWalletTicketGroup } from '@/types/hooks/api/useWallet'

export const useGetWalletByUserId = (): {
  walletTicketGroups: IWalletTicketGroup[] | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const { data, error, mutate } = useSWR<IWalletTicketGroup[], string>(
    `/users/tickets`,
    fetcher,
  )

  const isLoading = !(data !== undefined || error !== undefined)

  const walletTicketGroups: IWalletTicketGroup[] | undefined = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    walletTicketGroups,
    error,
    isLoading,
    refresh,
  }
}
