import useSWR from 'swr'

import fetcher from '@/services/axios'

import { type IBalance } from '@/types/hooks/api/useWallet'

export const useGetBalance = (): {
  balance: number | undefined
  error: string | undefined
  isLoading: boolean
} => {
  const { data, error } = useSWR<IBalance, string>(`/users/balance`, fetcher)

  const isLoading = !(data !== undefined || error !== undefined)

  const balance: number | undefined = data?.balance ?? undefined

  return {
    balance,
    error,
    isLoading,
  }
}
