export function usePixelTrackAddPaymentInfo(): {
  trackAddPaymentInfo: (pixelId: string) => void
} {
  function trackAddPaymentInfo(pixelId: string): void {
    if (window.fbq != null && typeof window.fbq === 'function') {
      window.fbq('trackSingle', pixelId, 'AddPaymentInfo')
    }
  }
  return { trackAddPaymentInfo }
}
