import { useEffect, type ReactElement } from 'react'
import { useLocation, useNavigate, Outlet } from 'react-router-dom'

import { useRoutingStore } from '@/store/routing'
import { useUserStore } from '@/store/user'

// import { Loading } from '@/components/Global/Loading'

export function ProtectedRoute(): ReactElement {
  // const [isLoading, setIsLoading] = useState(true)

  const { setToPath, isPinging } = useRoutingStore()
  const { userIsLogged } = useUserStore()

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    if (!isPinging) {
      // setIsLoading(false)

      if (!userIsLogged) {
        navigate('/login')
        setToPath(location.pathname)
      }
    }
  }, [isPinging])

  // if (isLoading) return <Loading hidden={!isLoading} />
  return <Outlet />
}
