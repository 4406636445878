import { api } from '@/services/axios'
import { useState } from 'react'
import { type AxiosError } from 'axios'

import {
  type IVerifyEmailOtpProps,
  type IVerifyEmailOtpReturn,
  type IVerifyEmailOtpResponse,
} from '@/types/hooks/api/useOTP'

export const usePostVerifyEmailOTP = (): {
  postVerifyEmailOtp: (
    verificationObj: IVerifyEmailOtpProps,
  ) => Promise<IVerifyEmailOtpReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function postVerifyEmailOtp(
    verificationObj: IVerifyEmailOtpProps,
  ): Promise<IVerifyEmailOtpReturn> {
    setIsLoading(true)

    const requestBody = verificationObj

    try {
      await api.post(`/auth/validate-email-otp`, requestBody)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError<IVerifyEmailOtpResponse>
      setIsLoading(false)

      if (error.response?.status === 401) {
        return {
          status: 401,
        }
      }
      if (error.response?.status === 406) {
        return {
          status: 406,
        }
      }
      return {
        status: error.response?.status as number,
      }
    }
  }

  return { postVerifyEmailOtp, isLoading }
}
