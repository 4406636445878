import { useState } from 'react'
import { api } from '@/services/axios'

import { type AxiosError } from 'axios'

import { type IEventInterest } from '@/types/Event'

interface IReturn {
  status: number
  usersStatus?: IEventInterest
}

export const useGetUsersStatusPaged = (
  eventAlias: string,
): {
  isLoading: boolean
  getUsersStatusPaged: (page: number) => Promise<IReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function getUsersStatusPaged(page: number): Promise<IReturn> {
    const take = 50
    const skip = 50 * page
    setIsLoading(true)

    try {
      const response = await api.get<IEventInterest>(
        `/events/${eventAlias}/status?skip=${skip}&take=${take}`,
      )
      setIsLoading(false)
      return {
        status: 200,
        usersStatus: response.data,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { getUsersStatusPaged, isLoading }
}
