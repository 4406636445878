import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError, type AxiosResponse } from 'axios'

import { type ITicketStore } from '@/types/hooks/api/useTickets'
import {
  type IPixResponseData,
  type ICardResponseData,
  type IPaymentReturnData,
  type ICreateCardPaymentReturnData,
} from '@/types/hooks/api/useCheckout'

export const usePostCheckout = (
  eventId: number,
  tickets?: ITicketStore[],
  orderId?: number,
): {
  createPaymentPix: () => Promise<IPaymentReturnData>
  createPaymentCard: () => Promise<ICreateCardPaymentReturnData>
  createPaymentIntlCard: () => Promise<ICreateCardPaymentReturnData>
  reserveFreeTicket: () => Promise<IPaymentReturnData>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function createPaymentPix(): Promise<IPaymentReturnData> {
    setIsLoading(true)
    let requestBody
    if (tickets !== undefined) {
      requestBody = {
        eventId,
        products: tickets
          .filter((ticket) => ticket.quantitySelected !== 0)
          .map((ticket) => {
            return {
              ticketSpecId: ticket.ticketSpecId,
              quantity: ticket.quantitySelected,
            }
          }),
      }
    } else {
      requestBody = {
        eventId,
        products: [{ orderId, quantity: 1 }],
      }
    }

    try {
      const response: AxiosResponse<IPixResponseData> = await api.post(
        `/payments/checkout/pix`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,
        code: response.data.pix.code,
        paymentId: response.data.payment.id,
      }
    } catch (err) {
      setIsLoading(false)
      const error = err as AxiosError

      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  async function createPaymentCard(): Promise<ICreateCardPaymentReturnData> {
    setIsLoading(true)

    let requestBody
    if (tickets !== undefined) {
      requestBody = {
        eventId,
        products: tickets
          .filter((ticket) => ticket.quantitySelected !== 0)
          .map((ticket) => {
            return {
              ticketSpecId: ticket.ticketSpecId,
              quantity: ticket.quantitySelected,
            }
          }),
      }
    }

    try {
      const response: AxiosResponse<ICardResponseData> = await api.post(
        `/payments/checkout/credit-card`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,
        redirectUrl: response.data.preference?.redirectUrl,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  async function createPaymentIntlCard(): Promise<ICreateCardPaymentReturnData> {
    setIsLoading(true)

    let requestBody
    if (tickets !== undefined) {
      requestBody = {
        eventId,
        products: tickets
          .filter((ticket) => ticket.quantitySelected !== 0)
          .map((ticket) => {
            return {
              ticketSpecId: ticket.ticketSpecId,
              quantity: ticket.quantitySelected,
            }
          }),
      }
    }

    try {
      const response: AxiosResponse<ICardResponseData> = await api.post(
        `/payments/checkout/intl-credit-card`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,
        redirectUrl: response.data.session?.redirectUrl,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  async function reserveFreeTicket(): Promise<IPaymentReturnData> {
    setIsLoading(true)
    let requestBody
    if (tickets !== undefined) {
      requestBody = {
        eventId,
        products: tickets
          .filter((ticket) => ticket.quantitySelected !== 0)
          .map((ticket) => {
            return {
              ticketSpecId: ticket.ticketSpecId,
              quantity: ticket.quantitySelected,
            }
          }),
      }
    } else {
      requestBody = {
        eventId,
        products: [{ orderId, quantity: 1 }],
      }
    }

    try {
      await api.post(`/tickets/free`, requestBody)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      setIsLoading(false)

      const error = err as AxiosError
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    createPaymentPix,
    createPaymentCard,
    createPaymentIntlCard,
    reserveFreeTicket,
    isLoading,
  }
}
