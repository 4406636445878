import { useEffect, type ReactElement, useState } from 'react'
import { motion } from 'framer-motion'
import { useNavigate, useParams } from 'react-router-dom'

import { useGetPaymentById } from '@/hooks/api/Payment'

import { TicketSummary } from '@/components/Global/TicketSummary'
import { CheckoutSuccessModal } from '@/components/CheckoutSuccessModal'
import { Loading } from '@/components/Global/Loading'

import { EventBase } from '@/compositions/EventBase'

import { numberToReais } from '@/helpers/formatNumber'
import { isTruthy } from '@/helpers/validation'

import { useUserStore } from '@/store/user'
import { useSnackbarStore } from '@/store/snackbar'
import { useGlobalStore } from '@/store/global'

import { type PaymentData } from '@/types/hooks/api/usePayment'

import { LOAD_PAYMENT_ERROR } from '@/errors'

export function EventCheckoutSuccessMobile(): ReactElement {
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false)
  const [paymentData, setPaymentData] = useState<PaymentData>()
  const [isLoadingPayment, setIsLoadingPayment] = useState<boolean>(true)
  const [isModalClosing, setIsModalClosing] = useState(false)

  const { paymentId } = useParams()

  const { username } = useUserStore()
  const { showErrorSnack } = useSnackbarStore()
  const { isMobile } = useGlobalStore()

  const { getPaymentById } = useGetPaymentById()

  const navigate = useNavigate()

  async function getPayment(): Promise<void> {
    const response = await getPaymentById(Number(paymentId))
    if (response.status === 200) {
      setPaymentData(response.paymentData)
      setIsLoadingPayment(false)
      setTimeout(setShowPaymentSuccessModal, 1500, true)
    } else {
      setIsLoadingPayment(false)
      showErrorSnack(LOAD_PAYMENT_ERROR)
    }
  }

  useEffect(() => {
    void getPayment()
  }, [])

  if (isLoadingPayment) return <Loading hidden={isLoadingPayment} />
  return (
    <>
      <EventBase.Root isMobile={isMobile}>
        <motion.div
          className="size-full"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <div className="flex w-full flex-col gap-2 p-4">
            {paymentData?.productPayments.map((ticket) => {
              const title = isTruthy(ticket.batchDescription)
                ? `${ticket.batchDescription} - ${ticket.ticketDescription}`
                : ticket.ticketDescription
              return (
                <TicketSummary
                  key={ticket.id}
                  title={title}
                  quantity={ticket.quantity}
                  thirdInfo={`Total: ${numberToReais(ticket.subtotal, 2)}`}
                  counterVariant="dark"
                />
              )
            })}
          </div>
        </motion.div>
      </EventBase.Root>
      {showPaymentSuccessModal && (
        <CheckoutSuccessModal
          title="Compra realizada"
          onClick={() => {
            setIsModalClosing(true)
            setTimeout(navigate, 300, `/memories/${username}`)
          }}
          isParentClosing={isModalClosing}
        />
      )}
    </>
  )
}
