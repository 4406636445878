import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

import { Spinner } from '@/components/Global/Spinner'

import { FaCircleCheck, FaCircleXmark } from 'react-icons/fa6'

interface ItemStatusProps {
  status: boolean
  isLoading: boolean
}

export function ItemStatus({
  status,
  isLoading,
}: ItemStatusProps): ReactElement {
  return (
    <AnimatePresence mode="wait">
      {isLoading ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <Spinner
            borderWidth="border-[3px]"
            borderColor="border-[#232323]/50"
            bottomBorderColor="border-b-[#232323]"
            width="w-5"
            height="h-5"
          />
        </motion.div>
      ) : (
        <>
          {status ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <FaCircleCheck size={20} color="#4D5E1F" />
            </motion.div>
          ) : (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2, ease: 'easeInOut' }}
            >
              <FaCircleXmark size={20} color="#FF5C5C" />
            </motion.div>
          )}
        </>
      )}
    </AnimatePresence>
  )
}
