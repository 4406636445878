import { useState, type ReactElement } from 'react'

import { Modal } from '@/components/Global/Modal'
import { Button } from '@/components/Global/Button'

interface IntransferableTicketModalProps {
  closeModal: () => void
}

export function IntransferableTicketModal({
  closeModal,
}: IntransferableTicketModalProps): ReactElement {
  const [isParentClosing, setIsParentClosing] = useState(false)

  function handleCloseModal(): void {
    setIsParentClosing(true)
    setTimeout(setIsParentClosing, 400, false)
    setTimeout(closeModal, 400)
  }

  return (
    <Modal isParentClosing={isParentClosing} closeModal={handleCloseModal}>
      <div className="flex flex-col gap-6 p-6">
        <h1 className="text-2xl">Por que não posso transferir meu ingresso?</h1>

        <span className="text-base leading-5">
          Para proteger alguns tipos de ingressos específicos, como ingressos
          sociais ou cortesias, sua transferência é desabilitada.
        </span>

        <div className="mt-2 h-12 w-full">
          <Button text="Entendi" enabled onClick={handleCloseModal} />
        </div>
      </div>
    </Modal>
  )
}
