import { useRef, type ReactElement } from 'react'
import { twMerge } from 'tailwind-merge'

import { OnFire } from '@/components/Global/OnFire'
import { EventShare } from '../EventShare'
import { EventHeart } from '../EventHeart'

import { useGlobalStore } from '@/store/global'

import { BsArrowLeftShort } from 'react-icons/bs'

interface EventImageProps {
  imageKey: string | undefined
  eventName?: string
  expanded?: boolean
  rounded?: boolean
  onClick?: () => void
  imgClassName?: string
  onFire?: boolean
  closeFriends?: boolean
  share?: boolean
  back?: boolean
  desktopBorderRadius?: string
  showInterest?: boolean
  isUserInsterested?: boolean
}

export function EventImage({
  imageKey,
  eventName,
  rounded = false,
  onClick,
  imgClassName,
  onFire = false,
  closeFriends = false,
  share = false,
  back = false,
  desktopBorderRadius = '',
  showInterest = false,
  isUserInsterested = false,
}: EventImageProps): ReactElement {
  const { isMobile } = useGlobalStore()

  const imageRef = useRef<HTMLImageElement>(null)

  return (
    <>
      {imageKey !== undefined && (
        <div
          className={twMerge(
            `relative z-20 flex h-full w-full items-center overflow-hidden `,
            rounded ? 'rounded-md' : '',
            imgClassName,
          )}
        >
          {back && (
            <div
              className={twMerge(
                'absolute z-10 flex h-8 w-8 items-center justify-center rounded-full bg-white shadow-md hover:cursor-pointer',
                isMobile ? 'left-4 top-4' : 'left-6 top-6',
              )}
              onClick={onClick}
            >
              <BsArrowLeftShort size={26} />
            </div>
          )}

          {showInterest && (
            <div className="absolute bottom-4 right-4 z-10">
              <EventHeart
                isActive={isUserInsterested}
                onClick={onClick}
                parentWidth={imageRef.current?.clientWidth ?? 0}
              />
            </div>
          )}
          <img
            src={`${import.meta.env.VITE_S3 as string}/${imageKey}`}
            alt="Event image"
            className="aspect-square size-full object-cover drop-shadow-2xl"
            style={{
              borderTopLeftRadius: desktopBorderRadius,
              borderTopRightRadius: desktopBorderRadius,
            }}
            ref={imageRef}
          />
          {closeFriends ? (
            <div className="absolute right-4 top-4 z-20 rounded-full bg-primary-main px-2 py-1 text-xs font-black text-dark-black shadow-md">
              CLOSE FRIENDS
            </div>
          ) : onFire ? (
            <div className="absolute right-4 top-4 z-20">
              <OnFire variant="withText" />
            </div>
          ) : null}

          {share && <EventShare eventName={eventName ?? ''} />}
        </div>
      )}
    </>
  )
}
