import { useState, type ReactElement } from 'react'
import { Link } from 'react-router-dom'

import { type IGenericUser } from '@/types/hooks/api/useSearch'

import { Button } from '@/components/Global/Button'

import {
  useDeleteUnfollow,
  usePostCreateFollowRequest,
  usePutCancelFollowRequest,
} from '@/hooks/api/Social'

import { useSnackbarStore } from '@/store/snackbar'
import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'

interface HeaderSearchUserCardProps {
  user: IGenericUser
  closeSearch: () => void
}

export function HeaderSearchUserCard({
  user,
  closeSearch,
}: HeaderSearchUserCardProps): ReactElement {
  const [localFollowRequest, setLocalFollowRequest] = useState<
    | {
        id?: number | null
        status: 'FOLLOWING' | 'NOT_FOLLOWING' | 'PENDING'
      }
    | undefined
  >(user.followRequest ?? undefined)

  const { showErrorSnack } = useSnackbarStore()
  const { userIsLogged } = useUserStore()
  const { setShowLoginModal } = useGlobalStore()

  const { postCreateFollowRequest, isLoading: isLoadingFollow } =
    usePostCreateFollowRequest()
  const { deleteUnfollow, isLoading: isLoadingUnfollow } = useDeleteUnfollow()
  const { putCancelFollowRequest, isLoading: isLoadingCancelFollow } =
    usePutCancelFollowRequest()

  async function handleFollowUser(): Promise<void> {
    if (!userIsLogged) {
      closeSearch()
      setTimeout(setShowLoginModal, 400, true)
      return
    }
    const response = await postCreateFollowRequest(user.id)

    if (response.status === 200) {
      setLocalFollowRequest({
        status: 'PENDING',
        id: response.followRequestId,
      })
    } else {
      showErrorSnack({
        title: 'Erro ao seguir usuário',
        description: 'Tente novamente',
        displayMode: 'ROOT',
      })
    }
  }

  async function handleUnfollowUser(): Promise<void> {
    const response = await deleteUnfollow(user.id)

    if (response.status === 200) {
      setLocalFollowRequest({
        status: 'NOT_FOLLOWING',
        id: null,
      })
    } else {
      showErrorSnack({
        title: 'Erro ao deixar de seguir usuário',
        description: 'Tente novamente',
        displayMode: 'ROOT',
      })
    }
  }

  async function handleCancelFollowUser(): Promise<void> {
    const response = await putCancelFollowRequest(localFollowRequest!.id!)

    if (response.status === 200) {
      setLocalFollowRequest({
        status: 'NOT_FOLLOWING',
        id: null,
      })
    } else {
      showErrorSnack({
        title: 'Erro ao cancelar pedido',
        description: 'Tente novamente',
        displayMode: 'ROOT',
      })
    }
  }

  return (
    <div className="flex w-full items-center gap-4">
      <Link
        to={`/memories/${user.username}`}
        className="flex w-full items-center gap-4"
      >
        <div className="size-12">
          <img
            src={`${import.meta.env.VITE_S3 as string}/${user.imageKey}`}
            alt="User profile image"
            className="size-full rounded-full"
          />
        </div>

        <div className="flex grow flex-col">
          <span className="text-xs font-semibold">
            {user.firstName} {user.lastName}
          </span>
          <span className="text-xs text-dark-dark-gray">@{user.username}</span>
        </div>
      </Link>
      <div className="h-6 w-[140px]">
        {localFollowRequest?.status === 'FOLLOWING' ? (
          <Button
            className="h-6 w-[140px] text-nowrap rounded-full bg-dark-light-gray px-6 py-1 text-xs font-semibold"
            text="Deixar de seguir"
            enabled={!isLoadingUnfollow}
            isLoading={isLoadingUnfollow}
            onClick={handleUnfollowUser}
            customSpinnerHeight="w-4"
            customSpinnerWidth="h-4"
          />
        ) : localFollowRequest?.status === 'PENDING' ? (
          <Button
            className="h-6 w-[120px] rounded-full bg-dark-light-gray px-6 py-1 text-xs font-semibold"
            text="Pendente"
            enabled={!isLoadingCancelFollow}
            isLoading={isLoadingCancelFollow}
            onClick={handleCancelFollowUser}
            customSpinnerHeight="w-4"
            customSpinnerWidth="h-4"
          />
        ) : (
          <Button
            className="h-6 w-[120px] rounded-full bg-dark-light-gray px-6 py-1 text-xs font-semibold"
            text="Seguir"
            enabled={!isLoadingFollow}
            isLoading={isLoadingFollow}
            onClick={handleFollowUser}
            customSpinnerHeight="w-4"
            customSpinnerWidth="h-4"
          />
        )}
      </div>
    </div>
  )
}
