import { useGlobalStore } from '@/store/global'
import { type ReactElement } from 'react'
import { MemoriesMobile } from './MemoriesMobile'
import { MemoriesDesktop } from './MemoriesDesktop'

export function Memories(): ReactElement {
  const { isMobile } = useGlobalStore()

  if (isMobile) return <MemoriesMobile />
  return <MemoriesDesktop />
}
