import { type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'

import { EventTicket } from '../EventTicket'

import 'swiper/css'
import 'swiper/css/pagination'

interface IViewableTicketsQRCode {
  title: string
  code: string
  validated: boolean
}

interface QRCodeViewProps {
  viewableTicketsQRCode: IViewableTicketsQRCode[]
  handleOpenQRCodeModal: (code: string) => void
}

export function QRCodeView({
  viewableTicketsQRCode,
  handleOpenQRCodeModal,
}: QRCodeViewProps): ReactElement {
  return (
    <AnimatePresence mode="wait">
      {viewableTicketsQRCode.length === 0 && (
        <motion.div
          key="no-tickets"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1 }}
          className="flex h-[400px] w-full items-start justify-center "
        >
          <EventTicket code={'https://gandaya.io'} />
        </motion.div>
      )}
      {viewableTicketsQRCode.length > 0 && (
        <motion.div
          key={'tickets' + viewableTicketsQRCode[0].code}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.1, ease: 'easeInOut' }}
          className="flex size-full items-center justify-center"
        >
          <Swiper
            slidesPerView={1}
            className="swiper-container h-[400px] overflow-visible"
            modules={[Pagination]}
            pagination={true}
            centeredSlides={true}
          >
            {viewableTicketsQRCode.map((ticket) => {
              return (
                <SwiperSlide key={ticket.code}>
                  <EventTicket
                    title={ticket.title}
                    code={ticket.code}
                    validated={ticket.validated}
                    handleOpenQRCodeModal={handleOpenQRCodeModal}
                  />
                </SwiperSlide>
              )
            })}
          </Swiper>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
