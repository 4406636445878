import { useState, useEffect } from 'react'
import fetcher from '@/services/axios'
import useSWR from 'swr'

import { type IOrganization } from '@/types/hooks/api/useOrganization'

export const useGetOrganization = (
  organizationAlias: string,
): {
  organization: IOrganization | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const [isLoading, setIsLoading] = useState(false)
  const { data, error, mutate } = useSWR<IOrganization, string>(
    `/organizations/${organizationAlias}/events`,
    fetcher,
  )

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data, error])

  const organization: IOrganization | undefined = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    organization,
    error,
    isLoading,
    refresh,
  }
}
