import { useState } from 'react'
import { api } from '@/services/axios'
import { type AxiosError } from 'axios'

import {
  type IProfilePictureResponse,
  type IProfilePictureReturn,
} from '@/types/hooks/api/useProfilePicture'

export const usePostDefaultProfilePicture = (): {
  postDefaultProfilePicture: (imageId: number) => Promise<IProfilePictureReturn>
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(true)

  async function postDefaultProfilePicture(
    imageId: number,
  ): Promise<IProfilePictureReturn> {
    setIsLoading(true)

    const requestBody = {
      imageId,
    }

    try {
      const response = await api.post<IProfilePictureResponse>(
        `/users/profile-picture/default`,
        requestBody,
      )
      setIsLoading(false)
      return {
        status: 200,
        imageKey: response.data.imageKey,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return {
    postDefaultProfilePicture,
    isLoading,
  }
}
