import { type ReactElement } from 'react'
import { useParams } from 'react-router-dom'

import { OrganizationMobile } from './OrganizationMobile'
import { OrganizationDesktop } from './OrganizationDesktop'

import { useGlobalStore } from '@/store/global'

import { useGetOrganization } from '@/hooks/api/Organization'

export function Organization(): ReactElement {
  const { isMobile } = useGlobalStore()

  const { organizationAlias } = useParams()

  const { organization, refresh, isLoading } = useGetOrganization(
    organizationAlias ?? '',
  )

  if (isMobile)
    return (
      <OrganizationMobile
        organization={organization}
        refresh={refresh}
        isLoading={isLoading}
      />
    )
  return (
    <OrganizationDesktop
      organization={organization}
      refresh={refresh}
      isLoading={isLoading}
    />
  )
}
