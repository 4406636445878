import { api } from '@/services/axios'
import { type AxiosError } from 'axios'
import { useState } from 'react'

interface IReturn {
  status: number
}

export const usePutCancelFollowRequest = (): {
  isLoading: boolean
  putCancelFollowRequest: (requestId: number) => Promise<IReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function putCancelFollowRequest(requestId: number): Promise<IReturn> {
    setIsLoading(true)

    try {
      await api.put(`/social/follow/requests/${requestId}/cancel`)
      setIsLoading(false)
      return {
        status: 200,
      }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return {
        status: error.response?.status ?? 0,
      }
    }
  }

  return { putCancelFollowRequest, isLoading }
}
