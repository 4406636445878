import { useNavigate } from 'react-router-dom'

import { useUserStore } from '@/store/user'
import { useRoutingStore } from '@/store/routing'
import { useGlobalStore } from '@/store/global'

interface UseAuthNavigateReturn {
  checkAuthNavigate: (to: string) => void
}

export function useAuthNavigate(): UseAuthNavigateReturn {
  const { userIsLogged } = useUserStore()
  const { setToPath } = useRoutingStore()
  const { setShowLoginModal } = useGlobalStore()

  const navigate = useNavigate()

  function checkAuthNavigate(to: string): void {
    if (userIsLogged) {
      navigate(to)
    } else {
      setToPath(to)
      setShowLoginModal(true)
    }
  }

  return { checkAuthNavigate }
}
