import { useEffect, useState, useRef, type ReactElement } from 'react'
import { useNavigate } from 'react-router'

import { FiClock } from 'react-icons/fi'
import { MdPix } from 'react-icons/md'
import { LuCopy } from 'react-icons/lu'
import { BsCheckLg } from 'react-icons/bs'

import { Button } from '../Global/Button'
import { QrCode } from '../Global/QRCode'
import { PixPaymentInstructions } from './PixPaymentInstructions'

interface PixPaymentProps {
  code: string
  codeCopied: boolean
  isLoadingVerify: boolean
  handleVerifyPayment: () => Promise<boolean>
  copyToClipboard: () => Promise<void>
  showQRCode?: boolean
}

export function PixPayment({
  code,
  codeCopied,
  isLoadingVerify,
  copyToClipboard,
  handleVerifyPayment,
  showQRCode = false,
}: PixPaymentProps): ReactElement {
  const [elapsedTime, setElapsedTime] = useState(0)
  const [finalPaymentCheck, setFinalPaymentCheck] = useState(false)
  const linkRef = useRef<HTMLSpanElement>(null)

  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  const totalDuration = 5 * 60 // 5 minutes
  let remainingTime = totalDuration - elapsedTime
  if (remainingTime <= 0) {
    if (!finalPaymentCheck) {
      setFinalPaymentCheck(true)
    }
    remainingTime = 0
  }
  const remainingMinutes = Math.floor(remainingTime / 60)
  const remainingSeconds = remainingTime % 60
  const formattedRemainingTime = `${remainingMinutes}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`

  const loadingBarWidth = (elapsedTime / 300) * 100

  async function handleFinalVerifyPayment(): Promise<void> {
    const paymentSucceeded = await handleVerifyPayment()
    if (!paymentSucceeded) setTimeout(navigate, 3000, -1)
  }

  useEffect(() => {
    if (finalPaymentCheck) {
      void handleFinalVerifyPayment()
    }
  }, [finalPaymentCheck])

  return (
    <div className="flex size-full flex-col gap-4 pb-8">
      <div className="flex w-full flex-col gap-4 pb-2">
        <div>
          <span className="text-xl font-black text-primary-main">
            {formattedRemainingTime}
          </span>
          <div className="h-2 w-full overflow-hidden rounded-full bg-background-main desktop:bg-dark-black">
            <div
              style={{ width: `${loadingBarWidth}%` }}
              className="h-2 bg-primary-main transition-[width] duration-1000 ease-linear"
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex size-8 items-center justify-center rounded-full bg-dark-dark-gray">
            <FiClock size={20} color="white" />
          </div>

          <span className="w-[90%] text-sm text-white">
            Reservamos seu ingresso por 5 minutos. Pague agora para garantir
            seus ingressos.
          </span>
        </div>
      </div>

      {showQRCode && (
        <div className="flex w-full items-center gap-4">
          <div>
            <div className="size-52">
              <QrCode value={code} variant="light" />
            </div>
          </div>
          <div className="w-full">
            <PixPaymentInstructions scanAvailable />
          </div>
        </div>
      )}

      <div className="flex flex-col gap-4">
        <div className="flex items-center justify-between gap-2 rounded-lg bg-background-main px-4 py-3 desktop:bg-dark-black">
          <div className="flex w-4/5 flex-col">
            <div className="flex items-center gap-2">
              <MdPix size={16} color="white" />
              <span className="font-bold text-white ">Pix copa e cola</span>
            </div>
            <span
              className="clamp-1 truncate text-dark-dark-gray"
              ref={linkRef}
            >
              {code}
            </span>
          </div>
          <div
            className={
              'transition-color flex h-8 w-8 items-center justify-center rounded-full duration-200 ease-in-out hover:cursor-pointer' +
              (codeCopied ? ' bg-primary-dark' : ' bg-primary-main')
            }
            onClick={() => {
              void copyToClipboard()
            }}
          >
            {codeCopied ? (
              <BsCheckLg size={18} color="white" />
            ) : (
              <LuCopy size={18} color="bg-background-main" />
            )}
          </div>
        </div>
        {!showQRCode && <PixPaymentInstructions />}
        <div className="h-9 w-28">
          <Button
            className="bg-white text-dark-black"
            onClick={() => {
              void handleVerifyPayment()
            }}
            enabled={!isLoadingVerify}
            text="Já paguei"
            isLoading={isLoadingVerify}
          />
        </div>
      </div>
    </div>
  )
}
