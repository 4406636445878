import { type ReactElement } from 'react'
import { motion } from 'framer-motion'

import { EventCard } from '@/components/Global/EventCard'
import { EventList } from '@/components/UserWallet/EventList'

import { useWalletStore } from '@/store/wallet'

import { isTruthy } from '@/helpers/validation'

export function UserWalletDesktop(): ReactElement {
  const { walletEvents, isLoadingWalletFetch } = useWalletStore()

  if (isLoadingWalletFetch)
    return (
      <motion.div
        className="flex size-full flex-col gap-8 p-8"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        key="loading-user-event-tickets"
      >
        <h1 className="text-[36px] font-semibold text-white">Meus ingressos</h1>
        <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8">
          {Array.from({ length: 8 }).map((_, index) => (
            <div key={index} className="flex flex-col items-start gap-2">
              <div className="aspect-square w-full animate-pulse rounded-md bg-dark-black" />
              <div className="h-16 w-full animate-pulse rounded-md bg-dark-black" />
            </div>
          ))}
        </div>
      </motion.div>
    )

  if (walletEvents?.length === 0)
    return (
      <div className="flex size-full flex-col items-center gap-16 p-8 pt-16">
        <div className="flex flex-col items-center gap-4">
          <h1 className="text-3xl font-bold text-white">
            Você ainda não possui ingressos
          </h1>
          <span className="text-white">
            Brasilidades? Música eletrônica? Pop? Funk?
            <br />
            Aqui você encontra a festa perfeita para você!
          </span>
        </div>
        <EventList />
      </div>
    )

  return (
    <motion.div
      className="flex size-full flex-col gap-8 p-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      key="user-event-tickets"
    >
      <h1 className="text-[36px] font-semibold text-white">Meus ingressos</h1>
      <div className="grid grid-cols-[repeat(auto-fill,minmax(300px,1fr))] gap-8">
        {walletEvents
          ?.sort((eventA, eventB) => {
            if (new Date(eventA.startDate) < new Date(eventB.startDate))
              return 1
            return -1
          })
          .map((event) => {
            const eventIsOver = new Date(event.endDate) < new Date()

            return (
              <div className="size-full" key={event.alias}>
                <EventCard
                  alias={event.alias}
                  key={event.alias}
                  imageUrl={event.imageKey}
                  title={event.title}
                  startDate={event.startDate}
                  endDate={event.endDate}
                  location={
                    isTruthy(event.location)
                      ? event.location
                      : 'Local a definir'
                  }
                  variation="desktop"
                  ticketCount={eventIsOver ? 0 : (event.tickets.length ?? 0)}
                  to={event.alias}
                />
              </div>
            )
          })}
      </div>
    </motion.div>
  )
}
