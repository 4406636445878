export function usePixelTrackInitiateCheckout(): {
  trackInitiateCheckout: (pixelId: string) => void
} {
  function trackInitiateCheckout(pixelId: string): void {
    if (window.fbq != null && typeof window.fbq === 'function') {
      window.fbq('trackSingle', pixelId, 'InitiateCheckout')
    }
  }
  return { trackInitiateCheckout }
}
