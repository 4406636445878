import { type ReactElement, useRef } from 'react'
import { motion } from 'framer-motion'
import { tv } from 'tailwind-variants'

import { AiFillExclamationCircle, AiFillInfoCircle } from 'react-icons/ai'
import { IoCheckmarkCircle } from 'react-icons/io5'

import { type Snack } from '@/types/snackbar'
import { isTruthy } from '@/helpers/validation'

interface SnackBarProps {
  snack: Snack
}

export function MobileSnackbar({ snack }: SnackBarProps): ReactElement {
  const divRef = useRef<HTMLDivElement>(null)

  const divVariant = tv({
    base: 'w-[90%] z-50 absolute left-[5%] bottom-0 flex items-center rounded p-4 text-white drop-shadow-md gap-4',
    variants: {
      background: {
        ERROR: 'bg-tonal-red',
        INFO: 'bg-[#6f6f6f]',
        SUCCESS: 'bg-[#53D860]',
      },
    },
  })

  return (
    <motion.div
      key="snackbar-mobile"
      className={divVariant({ background: snack.type })}
      initial={{ y: 100 }}
      animate={{ y: -20 }}
      exit={{ y: 100 }}
      transition={{ duration: 0.5, ease: 'backInOut' }}
      ref={divRef}
    >
      <div>
        {snack.type === 'ERROR' && (
          <AiFillExclamationCircle color="white" size={32} />
        )}
        {snack.type === 'INFO' && <AiFillInfoCircle color="white" size={32} />}
        {snack.type === 'SUCCESS' && (
          <IoCheckmarkCircle color="white" size={32} />
        )}
      </div>
      <div className="flex w-full flex-col gap-1">
        <span className="font-bold leading-4">{snack.title}</span>
        {isTruthy(snack.description) && (
          <span className="text-sm leading-4">{snack.description}</span>
        )}
      </div>
    </motion.div>
  )
}
