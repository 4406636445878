import { useEffect, useRef, useState, type ReactElement } from 'react'
import { AnimatePresence, motion } from 'framer-motion'
import { twMerge } from 'tailwind-merge'

import { QrCode } from '@/components/Global/QRCode'

import { IoIosClose } from 'react-icons/io'

import logo from '@/assets/images/logo-no-border.png'

interface WalletEventDownloadAppModalProps {
  closeModal?: () => void
  isParentClosing?: boolean
}

export function WalletEventDownloadAppModal({
  closeModal,
  isParentClosing = false,
}: WalletEventDownloadAppModalProps): ReactElement {
  const [renderOverlay, setRenderOverlay] = useState(true)
  const [renderModal, setRenderModal] = useState(false)

  const modalRef = useRef<HTMLDivElement>(null)
  const modalDivRef = useRef<HTMLDivElement>(null)

  function handleModalClose(): void {
    document.body.style.overflow = ''
    setRenderModal(false)
    setTimeout(setRenderOverlay, 200, false)
    if (closeModal !== undefined) setTimeout(closeModal, 200)
  }

  // Render overlay first, then render modal
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setTimeout(setRenderModal, 200, true)
  }, [])

  useEffect(() => {
    document.body.style.overflow = ''
    if (isParentClosing) handleModalClose()
  }, [isParentClosing])

  return (
    <AnimatePresence>
      {renderOverlay && (
        <motion.div
          key="overlay"
          className="fixed inset-0 z-50 h-max min-h-full w-full backdrop-blur-sm backdrop-brightness-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          onClick={closeModal}
        />
      )}

      {renderModal && (
        <motion.div
          key="modal"
          className="fixed left-1/2 top-1/2 z-[51] flex w-full max-w-[400px] -translate-x-1/2 -translate-y-1/2 flex-col items-end"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          ref={modalRef}
        >
          <>
            <div
              className={twMerge(
                'relative z-30 flex max-h-screen w-full flex-col items-center justify-center gap-8 overflow-scroll rounded-xl bg-dark-black p-8',
              )}
              ref={modalDivRef}
            >
              <div className="flex justify-between">
                <img src={logo} alt="Logo Gandaya" className="w-2/5" />
                <button
                  className="flex size-8 items-center justify-center rounded-full bg-white"
                  onClick={closeModal}
                >
                  <IoIosClose size={24} color="#181818" />
                </button>
              </div>

              <div className="flex w-full items-end gap-4">
                <div className="h-32">
                  <QrCode
                    value="https://gdya.dance/app"
                    variant="dark"
                    bgTransparent
                    margin={false}
                  />
                </div>
                <div className="flex w-full flex-col items-center gap-6">
                  <span className="text-xs leading-4 text-white">
                    Para sua segurança, transferências, revendas e cancelamentos
                    de ingressos deverão ser realizados pelo celular ou em nosso
                    app.
                  </span>
                  <a
                    className="w-full rounded-full bg-white py-1 text-center text-lg font-medium text-dark-black"
                    href="https://gdya.dance/app"
                  >
                    Baixar o app
                  </a>
                </div>
              </div>
            </div>
          </>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
