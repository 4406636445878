import { type ReactElement, useState, useEffect } from 'react'
import { motion } from 'framer-motion'
import { useParams } from 'react-router-dom'

import { ProfileCard } from '@/components/Memories/ProfileCard'
import { Stickers } from '@/components/Memories/Stickers'
import { AddSticker } from '@/components/Memories/AddSticker'
import { Loading } from '@/components/Global/Loading'
import { NewStickersModal } from '@/components/Memories/NewStickersModal'
import { UserNotFound } from '@/components/Memories/UserNotFound'
import { ProfilePictureModal } from '@/components/LoginSignup/ProfilePictureModal'
import { SupportModal } from '@/components/Memories/SupportModal'
import { OptionsModal } from '@/components/Memories/OptionsModal'
import { EditProfileModal } from '@/components/Memories/EditProfileModal'
import { ShowFollowersModal } from '@/components/Memories/ShowFollowersModal'
import { ShowFollowingModal } from '@/components/Memories/ShowFollowingModal'
import { ShowEventsModal } from '@/components/Memories/ShowEventsModal'

import { useNavbarStore } from '@/store/navbar'
import { useUserStore } from '@/store/user'
import { useGlobalStore } from '@/store/global'
import { useSnackbarStore } from '@/store/snackbar'
import { useRoutingStore } from '@/store/routing'

import { useGetProfileData } from '@/hooks/api/Profile'

import { type IUserSticker } from '@/types/global/sticker'

import { BsGearFill } from 'react-icons/bs'
import { PiStickerDuotone } from 'react-icons/pi'

import { isTruthy } from '@/helpers/validation'

export function MemoriesMobile(): ReactElement {
  const [editStickers, setEditStickers] = useState<boolean>(false)
  const [maxCoordY, setMaxCoordY] = useState<number>(0)
  const [showUngluedStickers, setShowUngluedStickers] = useState<boolean>(true)
  const [newStickerToGlue, setNewStickerToGlue] = useState<IUserSticker>()
  const [userNotFound, setUserNotFound] = useState(false)
  const [showProfilePictureModal, setShowProfilePictureModal] = useState(false)
  const [showSupportModal, setShowSupportModal] = useState(false)
  const [showOptionsModal, setShowOptionsModal] = useState(false)
  const [isParentClosing, setIsParentClosing] = useState(false)
  const [allImagesLoaded, setAllImagesLoaded] = useState<boolean>(false)
  const [gluedStickers, setGluedStickers] = useState<IUserSticker[]>([])
  const [notGluedStickers, setNotGluedStickers] = useState<IUserSticker[]>([])
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)
  const [showFollowersModal, setShowFollowersModal] = useState(false)
  const [showFollowingModal, setShowFollowingModal] = useState(false)
  const [showEventsModal, setShowEventsModal] = useState(false)

  const { setShowLoginModal } = useGlobalStore()
  const { showInfoSnack } = useSnackbarStore()
  const { setToPath } = useRoutingStore()

  const { username } = useParams()

  const {
    userProfileData,
    isLoading: isLoadingProfileData,
    refresh,
  } = useGetProfileData(username ?? '')

  const { showNavbar } = useNavbarStore()
  const { username: usernameStore, imageKey, userIsLogged } = useUserStore()

  const variantsProfileCard = {
    shown: {
      y: 0,
    },
    hidden: {
      y: -250,
    },
  }

  const variantsOverlay = {
    shown: {
      opacity: 0,
    },
    hidden: {
      opacity: 1,
    },
  }

  const isAuthUser = usernameStore === userProfileData?.username
  const isAllowedToSeeSocials =
    isAuthUser || userProfileData?.followRequest.status === 'FOLLOWING'

  useEffect(() => {
    if (userProfileData === undefined) {
      setUserNotFound(true)
      return
    }
    setUserNotFound(false)
    if (userProfileData?.stickers !== undefined) {
      const tempGluedStickers = userProfileData?.stickers.filter(
        (sticker) => sticker.glued,
      )

      // Set max value for memories div height
      if (tempGluedStickers === undefined) return

      const tempMaxHeight =
        Math.max(...tempGluedStickers.map((sticker) => sticker.yPos), 500) + 250
      setMaxCoordY(tempMaxHeight)

      setGluedStickers(tempGluedStickers ?? [])
      setNotGluedStickers(
        userProfileData?.stickers?.filter((sticker) => !sticker.glued!) ?? [],
      )
    }
  }, [userProfileData])

  useEffect(() => {
    showNavbar()
  }, [])

  useEffect(() => {
    setShowProfilePictureModal(false)

    if (isTruthy(imageKey) && userProfileData?.imageKey !== imageKey)
      void refresh()
  }, [imageKey])

  if (isLoadingProfileData) return <Loading />
  if (userNotFound) return <UserNotFound />
  return (
    <div
      className={
        'relative size-full min-h-full overflow-x-hidden overflow-y-scroll bg-background-main'
      }
    >
      {!allImagesLoaded && (
        <div>
          <Loading hidden={false} />
        </div>
      )}
      <div className="absolute top-0 z-30 flex w-full items-center justify-between p-4">
        <motion.div
          initial={{ y: -250 }}
          animate={editStickers ? 'hidden' : 'shown'}
          variants={variantsProfileCard}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="flex w-full items-center justify-between"
        >
          <div className="flex w-full flex-col gap-2">
            <div className="flex justify-between">
              <div className="w-min rounded-md bg-dark-black px-2 py-0.5">
                <span className="text-xl font-bold text-white">
                  @{userProfileData?.username}
                </span>
              </div>
              {isAuthUser && (
                <div
                  className={
                    'relative z-30 ml-2 text-2xl hover:cursor-pointer' +
                    (notGluedStickers.length > 0
                      ? ' text-text-main'
                      : ' text-text-secondary')
                  }
                  onClick={() => {
                    if (notGluedStickers.length > 0)
                      setShowUngluedStickers(true)
                  }}
                >
                  {notGluedStickers.length > 0 && (
                    <span className=" absolute -left-2 -top-1 flex size-5 items-center justify-center rounded-full bg-tonal-red text-sm font-bold">
                      {notGluedStickers.length}
                    </span>
                  )}
                  <PiStickerDuotone size={32} />
                </div>
              )}
            </div>
            <ProfileCard
              userData={userProfileData}
              isAuthUser={isAuthUser}
              setShowProfilePictureModal={setShowProfilePictureModal}
              openEditProfileModal={() => {
                setShowEditProfileModal(true)
              }}
              openFollowersModal={() => {
                if (!userIsLogged) {
                  setShowLoginModal(true)
                  setToPath(`/memories/${userProfileData?.username ?? ''}`)
                  return
                }

                if (!isAllowedToSeeSocials) {
                  showInfoSnack({
                    title: 'Você não segue esse usuário',
                    description:
                      'Siga este usuário para ver os seguidores dele',
                  })
                  return
                }

                setShowFollowersModal(true)
              }}
              openFollowingModal={() => {
                if (!userIsLogged) {
                  setShowLoginModal(true)
                  setToPath(`/memories/${userProfileData?.username ?? ''}`)
                  return
                }

                if (!isAllowedToSeeSocials) {
                  showInfoSnack({
                    title: 'Você não segue esse usuário',
                    description: 'Siga este usuário para ver quem ele segue',
                  })
                  return
                }

                setShowFollowingModal(true)
              }}
              openEventsModal={() => {
                if (!userIsLogged) {
                  setShowLoginModal(true)
                  setToPath(`/memories/${userProfileData?.username ?? ''}`)
                  return
                }

                if (!isAllowedToSeeSocials) {
                  showInfoSnack({
                    title: 'Você não segue esse usuário',
                    description: 'Siga este usuário para ver os eventos dele',
                  })
                  return
                }

                setShowEventsModal(true)
              }}
            />
          </div>
        </motion.div>
      </div>
      <Stickers
        maxCoordY={maxCoordY}
        editStickers={editStickers}
        gluedStickers={gluedStickers}
        setAllImagesLoaded={setAllImagesLoaded}
      />
      {isAuthUser && !editStickers && (
        <BsGearFill
          className="fixed bottom-16 right-4 size-8 text-dark-dark-gray hover:cursor-pointer"
          onClick={() => {
            setShowOptionsModal(true)
          }}
        />
      )}
      {isAuthUser && editStickers && newStickerToGlue !== undefined && (
        <motion.div
          key="add-sticker"
          initial={{ opacity: 0 }}
          animate={editStickers ? 'hidden' : 'shown'}
          variants={variantsOverlay}
          transition={{ duration: 0.2, ease: 'easeInOut' }}
          className="absolute left-0 top-0 z-40 w-full"
        >
          <AddSticker
            maxCoordY={maxCoordY}
            setMaxCoordY={setMaxCoordY}
            newStickerToGlue={newStickerToGlue}
            addGluedSticker={(newGluedSticker: IUserSticker) => {
              setGluedStickers((prev) => [...prev, newGluedSticker])
            }}
            removeNotGluedSticker={(newGluedStickerId: number) => {
              setNotGluedStickers((prev) =>
                prev.filter((sticker) => sticker.id !== newGluedStickerId),
              )
            }}
            exitEditMode={() => {
              setEditStickers(false)
              setNewStickerToGlue(undefined)
            }}
            refreshProfileData={() => {
              void refresh()
            }}
          />
        </motion.div>
      )}
      {isAuthUser && showUngluedStickers && notGluedStickers.length > 0 && (
        <NewStickersModal
          notGluedStickers={notGluedStickers}
          closeModal={() => {
            setShowUngluedStickers(false)
          }}
          setNewStickerToGlue={setNewStickerToGlue}
          onSelectSticker={() => {
            setEditStickers(true)
          }}
        />
      )}
      {showProfilePictureModal && (
        <ProfilePictureModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowProfilePictureModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showSupportModal && (
        <SupportModal
          closeModal={() => {
            setShowSupportModal(false)
          }}
        />
      )}
      {showOptionsModal && (
        <OptionsModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowOptionsModal, 400, false)
          }}
          openSupportModal={() => {
            setTimeout(setShowSupportModal, 400, true)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {showEditProfileModal && (
        <EditProfileModal
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowEditProfileModal, 400, false)
          }}
          isParentClosing={isParentClosing}
          refreshUserData={refresh}
        />
      )}
      {userProfileData !== undefined && showFollowersModal && (
        <ShowFollowersModal
          userId={userProfileData.id}
          isAuthUser={isAuthUser}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowFollowersModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {userProfileData !== undefined && showFollowingModal && (
        <ShowFollowingModal
          userId={userProfileData.id}
          isAuthUser={isAuthUser}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowFollowingModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
      {userProfileData !== undefined && showEventsModal && (
        <ShowEventsModal
          userId={userProfileData.id}
          username={userProfileData.username}
          closeModal={() => {
            setIsParentClosing(true)
            setTimeout(setIsParentClosing, 400, false)
            setTimeout(setShowEventsModal, 400, false)
          }}
          isParentClosing={isParentClosing}
        />
      )}
    </div>
  )
}
