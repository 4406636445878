export function usePixelTrackAddToCart(): {
  trackAddToCart: (
    pixelId: string,
    productName: string,
    price: number,
    quantity: number,
    productId: number,
  ) => void
} {
  function trackAddToCart(
    pixelId: string,
    productName: string,
    price: number,
    quantity: number,
    productId: number,
  ): void {
    if (window.fbq != null && typeof window.fbq === 'function') {
      window.fbq('trackSingle', pixelId, 'AddToCart', {
        content_name: productName,
        content_category: 'Products',
        content_type: 'product',
        value: price * quantity,
        currency: 'BRL',
        contents: [{ id: productId, quantity }],
      })
    }
  }
  return { trackAddToCart }
}
