import { useState } from 'react'
import { type AxiosError } from 'axios'

import { api } from '@/services/axios'

interface IReturn {
  status: number
}

export const usePutDenyFollowRequest = (): {
  isLoading: boolean
  putDenyFollowRequest: (requestId: number) => Promise<IReturn>
} => {
  const [isLoading, setIsLoading] = useState(false)

  async function putDenyFollowRequest(requestId: number): Promise<IReturn> {
    setIsLoading(true)

    try {
      await api.put<string>(`/social/follow/requests/${requestId}/deny`)
      setIsLoading(false)
      return { status: 200 }
    } catch (err) {
      const error = err as AxiosError
      setIsLoading(false)
      return { status: error.response?.status ?? 0 }
    }
  }

  return { putDenyFollowRequest, isLoading }
}
