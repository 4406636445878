import { type ReactNode, type ReactElement } from 'react'

interface EventRootProps {
  children: ReactNode
  isMobile: boolean
}

export function EventRoot({
  children,
  isMobile,
}: EventRootProps): ReactElement {
  if (isMobile)
    return (
      <div className="relative flex w-full max-w-lg flex-col items-start justify-center overflow-hidden pb-24">
        {children}
      </div>
    )
  return <>{children}</>
}
