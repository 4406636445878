import { useState, useEffect } from 'react'
import useSWR from 'swr'
import fetcher from '@/services/axios'

import { type IUserData } from '@/types/hooks/api/useProfile'

export const useGetProfileData = (
  username: string,
): {
  userProfileData: IUserData | undefined
  error: string | undefined
  isLoading: boolean
  refresh: () => Promise<void>
} => {
  const [isLoading, setIsLoading] = useState(false)
  const { data, error, mutate } = useSWR<IUserData, string>(
    `/users/${username}`,
    fetcher,
  )

  useEffect(() => {
    if (data !== undefined || error !== undefined) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
    }
  }, [data, error])

  const userProfileData: IUserData | undefined = data ?? undefined

  async function refresh(): Promise<void> {
    await mutate()
  }

  return {
    userProfileData,
    error,
    isLoading,
    refresh,
  }
}
