import { type ReactElement } from 'react'

import { EventBase } from '@/compositions/EventBase'

import { useGlobalStore } from '@/store/global'

import { EventCheckoutSuccessMobile } from './EventCheckoutSuccessMobile'
import { EventCheckoutSuccessDesktop } from './EventCheckoutSuccessDesktop'

export function EventCheckoutSuccess(): ReactElement {
  const { isMobile } = useGlobalStore()
  return (
    <EventBase.Root isMobile={isMobile}>
      {isMobile ? (
        <EventCheckoutSuccessMobile />
      ) : (
        <EventCheckoutSuccessDesktop />
      )}
    </EventBase.Root>
  )
}
