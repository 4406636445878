import { type ReactElement } from 'react'
import {
  Area,
  AreaChart as AreaChartRecharts,
  ResponsiveContainer,
} from 'recharts'

interface EventBarChartProps {
  data: Array<{
    pv: number
  }>
  variant?: 'light' | 'dark'
}

export function AreaChart({
  data,
  variant = 'dark',
}: EventBarChartProps): ReactElement {
  const chartColor = variant === 'dark' ? '#D5FF5C' : '#4D5E1F'

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChartRecharts data={data}>
        <defs>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={chartColor} stopOpacity={0.5} />
            <stop
              offset="95%"
              stopColor={variant === 'dark' ? '#232323' : '#fff'}
              stopOpacity={0}
            />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="pv"
          stroke={chartColor}
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChartRecharts>
    </ResponsiveContainer>
  )
}
