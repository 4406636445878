import { type IEventData } from '@/types/hooks/api/useEvent'
import { create } from 'zustand'

interface eventProps {
  ticketQuantity: number
  setTicketQuantity: (quantity: number) => void
  integration?: {
    pixelId: string
  }
  setPixelId: (pixelId: string) => void
  currentEvent: IEventData | undefined
  setCurrentEvent: (event: IEventData) => void
  showLinkCoppiedSnackBar: boolean
  setShowLinkCoppiedSnackBar: (show: boolean) => void
}

export const useEventStore = create<eventProps>()((set) => ({
  ticketQuantity: 0,
  setTicketQuantity: (quantity) => {
    set(() => ({
      ticketQuantity: quantity,
    }))
  },
  integration: undefined,
  setPixelId: (pixelId) => {
    set(() => ({
      integration: {
        pixelId,
      },
    }))
  },
  currentEvent: undefined,
  setCurrentEvent: (event) => {
    set(() => ({
      currentEvent: event,
    }))
  },
  showLinkCoppiedSnackBar: false,
  setShowLinkCoppiedSnackBar: (show) => {
    set(() => ({
      showLinkCoppiedSnackBar: show,
    }))
  },
}))
