import { useState, type ReactElement } from 'react'
import { useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import { motion } from 'framer-motion'

import { EventThumbnail } from '@/components/Event/EventThumbnail'
import { Loading } from '@/components/Global/Loading'
import { LinkAnalytics } from '@/components/Link/LinkAnalytics'
import { LinkUsers } from '@/components/Link/LinkUsers'
import { LinkToggle } from '@/components/Link/LinkToggle'

import { useGetLinkAnalytics } from '@/hooks/api/Links'
import { useGetEventByAlias } from '@/hooks/api/Event'

import { datesToFormat } from '@/helpers/formatDate'

import { useGlobalStore } from '@/store/global'

export function Link(): ReactElement {
  const [selectedTab, setSelectedTab] = useState<'analytics' | 'users'>(
    'analytics',
  )

  const { eventAlias, hash } = useParams()

  const { isMobile } = useGlobalStore()

  const {
    linkAnalytics,
    isLoading: isLoadingAnalyticsData,
    error: errorAnalyticsData,
  } = useGetLinkAnalytics(hash)
  const {
    eventData,
    isLoading: isLoadingEventData,
    error: errorEventData,
  } = useGetEventByAlias(eventAlias!)

  if (isLoadingEventData || isLoadingAnalyticsData) return <Loading />
  if (!isMobile)
    return (
      <div className="absolute left-1/2 top-1/2 flex w-[400px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center pb-32">
        <span className="text-center text-2xl font-bold text-white">
          Para a sua segurança este link só pode ser aberto pelo celular 😉
        </span>
      </div>
    )
  if (errorAnalyticsData !== undefined || errorEventData !== undefined)
    return (
      <div className="flex size-full flex-col items-center justify-center bg-background-main p-4">
        <span className="text-center text-3xl font-black text-white">
          Esse link não é válido <br /> ou não existe 🤔
        </span>
      </div>
    )
  return (
    <div className="flex size-full max-h-full max-w-full flex-col gap-10 overflow-x-hidden overflow-y-scroll bg-background-main pb-14 pt-4">
      <div className="flex w-full flex-col gap-6 px-4">
        <div className="flex w-fit flex-col pt-2">
          <span className="font-black leading-3 text-[#A3A3A3]">Link</span>
          <span className="text-4xl font-bold text-white">
            {linkAnalytics?.code}
          </span>
        </div>
        <EventThumbnail
          imageKey={eventData?.imageKey ?? ''}
          title={eventData?.title ?? ''}
          date={datesToFormat(
            eventData?.date.startDate ?? '',
            eventData?.date.endDate ?? '',
          )}
          location={eventData?.location?.name ?? 'Local a definir'}
          highlighted={false}
          briefVariant="white"
          imageVariant="large"
          eventAlias={eventAlias}
        />
      </div>

      <div className="self-center">
        <LinkToggle selected={selectedTab} setSelected={setSelectedTab} />
      </div>

      <div
        className={twMerge(
          'flex h-full w-[200%]',
          selectedTab === 'analytics'
            ? 'h-[calc(100%-5px)] max-h-full overflow-hidden'
            : 'h-full',
        )}
      >
        <motion.div
          key="analytics"
          animate={{ x: selectedTab === 'analytics' ? 0 : -500 }}
          className="size-full overflow-hidden"
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <div className="size-full px-4 ">
            <LinkAnalytics linkAnalytics={linkAnalytics} />
          </div>
        </motion.div>

        <motion.div
          key="users"
          animate={{
            x: selectedTab === 'users' ? '-100%' : 0,
          }}
          className="size-full"
          transition={{ duration: 0.2, ease: 'easeInOut' }}
        >
          <div
            className={twMerge(
              'w-full px-4',
              selectedTab === 'users' ? 'h-full' : 'h-0',
            )}
          >
            <LinkUsers linkHash={hash!} />
          </div>
        </motion.div>
      </div>
    </div>
  )
}
