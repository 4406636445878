import { type ReactElement } from 'react'
import { type IFollowingData, type IFollowerData } from '@/types/Social'
import { Button } from '@/components/Global/Button'
import { useRemoveFollower } from '@/hooks/api/Social'
import { useNavigate } from 'react-router-dom'

interface UserSocialCardProps {
  closeModal: () => void
  user: IFollowerData | IFollowingData
  isAuthUser: boolean
  mode: 'FOLLOWER' | 'FOLLOWING'
  refreshData?: () => void
}

export function UserSocialCard({
  user,
  mode,
  isAuthUser,
  closeModal,
  refreshData,
}: UserSocialCardProps): ReactElement {
  const navigate = useNavigate()
  const fullName = `${user.firstName} ${user.lastName}`

  const { deleteFollower, isMutating: isDeletingFollower } = useRemoveFollower(
    user.userId,
  )

  const handleDeleteFollower = async (): Promise<void> => {
    await deleteFollower()
    if (refreshData != null) refreshData()
  }

  const handleGoToProfile = (): void => {
    closeModal()
    setTimeout(() => {
      navigate(`/memories/${user.username}`)
    }, 400)
  }

  return (
    <div
      className="flex w-full cursor-pointer items-center justify-between rounded-lg p-2 transition-colors hover:bg-dark-white/10"
      onClick={handleGoToProfile}
    >
      <div className="flex items-center gap-4">
        <img
          className="aspect-square size-12 rounded-full"
          src={`${import.meta.env.VITE_S3 as string}/${user.imageKey}`}
          alt={fullName}
        />
        <span className="font-matter text-dark-white">{fullName}</span>
      </div>

      {isAuthUser && mode === 'FOLLOWER' && (
        <Button
          enabled={!isDeletingFollower}
          text="Remover"
          onClick={(e) => {
            e.stopPropagation()
            void handleDeleteFollower()
          }}
          className="max-w-fit px-3 py-1 text-sm"
        />
      )}
      {isAuthUser && mode === 'FOLLOWING' && (
        <Button
          enabled={false}
          text="Seguindo"
          className="max-w-fit bg-[#A7A6A6] px-3 py-1 text-sm text-black"
        />
      )}
    </div>
  )
}
